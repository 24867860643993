import { useQuery, useQueries, useMutation } from 'react-query'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { api } from '../services/api'
import { signalToCancellationToken } from './utils'

/**
 * Export interface for useLocationMetrics options.
 */
export interface UseLocationConnectionsOptions {
  locationId: string
}

export const useAccountLocationConnections = (
  options: UseLocationConnectionsOptions
) => {
  const { locationId } = options
  const token = useAccessToken()
  const [accountId] = useAuthStore((t) => [t.accountId])

  return useQuery(
    ['accounts', accountId, 'locations', locationId, 'connections'],
    ({ signal }) =>
      api
        .get(`/v1/accounts/${accountId}/locations/${locationId}/connections`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data.data),
    {
      enabled: !!token?.access_token && !!accountId && !!locationId,
    }
  )
}

export interface UseLocationConnectionPullOptions {
  locationId: string
  connectionId: string
}

/**
 * Use pull catalog
 */
export const useAccountLocationConnectionPull = () => {
  const token = useAccessToken()
  return useMutation(
    (props: { accountId: string; locationId: string; id: string }) => {
      return api.post(
        `/v1/accounts/${props.accountId}/locations/${props.locationId}/connections/${props.id}/pull`,
        {},
        {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        }
      )
    },
    {}
  )
}
