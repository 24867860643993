import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import {
  ParseOptions,
  ParsedQuery,
  StringifyOptions,
  parse,
  stringify,
} from 'query-string'

export type QueryStringResult = [
  ParsedQuery,
  Dispatch<SetStateAction<Record<string, any>>>
]

/**
 * Custom hook for managing query string parameters.
 * @param location The location object from react-router-dom.
 * @param navigate The navigate function from react-router-dom.
 * @param parseOptions The options to pass to the query-string parse function.
 * @param stringifyOptions The options to pass to the query-string stringify function.
 * @returns A tuple containing the parsed query string and a function to set the query string.
 */
export const useQueryString = (
  location: Location,
  navigate: (path: string) => void,
  parseOptions?: ParseOptions,
  stringifyOptions?: StringifyOptions
): QueryStringResult => {
  const isFirst = useRef(true)
  const [state, setState] = useState(parse(location.search, parseOptions))

  useEffect((): void => {
    if (isFirst.current) {
      isFirst.current = false
    } else {
      navigate(location.pathname + '?' + stringify(state, stringifyOptions))
    }
  }, [state])

  const setQuery: typeof setState = (values): void => {
    const nextState = typeof values === 'function' ? values(state) : values
    setState(
      (state): ParsedQuery => ({
        ...state,
        ...nextState,
      })
    )
  }

  return [state, setQuery]
}
