import { Badge } from '@mui/material'
import { FC } from 'react'

export const ResponseCodeBadge: FC<{ responseCode: number }> = ({
  responseCode,
}) => {
  const range = responseCode.toString()[0]
  let badgeColor: any = 'default'
  if (range === '2') badgeColor = 'success'
  if (range === '4') badgeColor = 'warning'
  if (range === '5') badgeColor = 'error'
  return (
    <>
      <Badge color={badgeColor} variant="dot" sx={{ marginRight: 2 }} />
      {responseCode}
    </>
  )
}
