import { useParams } from 'react-router-dom'
import { useResellerOrderAudits, useResellerOrderById } from '../../../hooks'
import { OrderView } from '../../../components/OrderView'
import { Paper } from '@mui/material'
import { OrderAuditLogs } from '../../../components'

export function ViewOrder() {
  const { resellerId, orderId } = useParams()

  /**
   * Create the data query to fetch the orders
   */
  const order = useResellerOrderById(
    { resellerId: resellerId!, orderId: orderId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  const logs = useResellerOrderAudits(
    { orderId: orderId!, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true, enabled: order.isSuccess }
  )

  return (
    <>
      <OrderView order={order} />
      <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
        <OrderAuditLogs logs={logs} order={order} />
      </Paper>
    </>
  )
}
