import { useParams } from 'react-router-dom'
import { useResellerDeliveries } from '../../../hooks'
import { DeliveriesTable } from './DeliveriesTable'

export function ResellerDeliveries() {
  let { resellerId } = useParams()

  /**
   * Create the data query to fetch the orders
   */
  const deliveries = useResellerDeliveries(
    { filters: undefined, resellerId: resellerId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  return <DeliveriesTable deliveries={deliveries} />
}
