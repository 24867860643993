import { Box, Switch, Tooltip, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export const ShowOnlineCategoryView: FC<{
  data: any
  handleShowOnline: (showOnline: boolean, categoryId: string) => any
  isLabel?: boolean
}> = (props) => {
  const { data, handleShowOnline, isLabel } = props
  const intl = useIntl()
  const [categoryState, setCategoryState] = useState<any>(data)
  useEffect(() => {
    setCategoryState(data)
  }, [data])
  const switchShowOnline = (event: any, categoryId: string) => {
    setCategoryState({ ...categoryState, showOnline: event.target.checked })
    handleShowOnline(event.target.checked, categoryId)
  }
  if (isLabel) {
    return (
      <Box
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Typography variant="caption">
          {intl.formatMessage({ id: 'label_show_online' })}
        </Typography>
        <Switch
          checked={categoryState.showOnline}
          onChange={() => switchShowOnline(event, categoryState.id)}
          size="small"
        />
      </Box>
    )
  } else {
    return (
      <Tooltip title={intl.formatMessage({ id: 'label_show_online' })}>
        <Switch
          checked={categoryState.showOnline}
          onChange={() => switchShowOnline(event, categoryState.id)}
          size="small"
        />
      </Tooltip>
    )
  }
}
