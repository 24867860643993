import { CloseOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { CommonTextField } from '../../components'
import { useIntl } from 'react-intl'
import { useQueryString } from '../../hooks/useQueryString'
import { useNavigate } from 'react-router-dom'
import { stringify } from 'querystring'
import { debounce } from 'lodash'

interface Props {
  setFilters: (filters: any) => void
  isLoading: boolean
}
export const ResellerSearch: FC<Props> = (props) => {
  const intl = useIntl()
  const { setFilters, isLoading } = props
  const location = window.location
  const navigate = useNavigate()
  const [queryParamsURL, setQuery] = useQueryString(location, navigate)
  const [queryValue, setQueryValue] = useState<any>(queryParamsURL?.query || '')
  const handleChangeText = (e: any) => {
    const value = e.target.value
    setQueryValue(value)
    if (!value) {
      setFilters({ query: '' })
      setQuery({ query: '' })
      navigate(`?${stringify(queryParamsURL)}`)
    }
  }
  const handleClear = () => {
    setQueryValue('')
    setFilters({ query: '' })
    setQuery({ query: '' })
    navigate(`?${stringify(queryParamsURL)}`)
  }
  const onApply = (searchData?: string | undefined) => {
    setFilters({ query: searchData })
    setQuery({ query: searchData })
    navigate(`?${stringify(queryParamsURL)}`)
  }
  const debouncedApply = useCallback(
    debounce((value) => {
      onApply(value)
    }, 500),
    [onApply]
  )

  useEffect(() => {
    if (queryValue) {
      debouncedApply(queryValue)
    }
    return () => {
      debouncedApply.cancel()
    }
  }, [queryValue, debouncedApply])
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <CommonTextField
        id="outlined-basic"
        size="small"
        sx={{ width: '250px' }}
        label={intl.formatMessage({ id: 'action_search' })}
        onChange={handleChangeText}
        value={queryValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isLoading ? (
                <IconButton sx={{ mx: 0.2, p: 1 }}>
                  <CircularProgress size="1.4rem" color="secondary" />
                </IconButton>
              ) : queryValue ? (
                <IconButton edge="end" onClick={handleClear}>
                  <CloseOutlined fontSize="small" />
                </IconButton>
              ) : (
                <IconButton edge="end">
                  <SearchOutlined />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
