import { Typography } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useResellerAccountLocations } from '../../../hooks/useResellerAccountLocations'
import { Column, GenericTable } from '../../../components'
import { CopyToClipboard } from '../../../components/CopyToClipboard'

const columns: readonly Column[] = [
  {
    id: 'id',
    label: 'ID',
    component: (row) => (
      <CopyToClipboard value={row?.id}>
        <Typography variant="caption">{row?.id}</Typography>
      </CopyToClipboard>
    ),
  },
  {
    id: 'name',
    label: 'Name',
    component(row) {
      return <Typography variant="caption">{row?.name}</Typography>
    },
  },
  {
    id: 'address',
    label: 'Address',
    component(row) {
      return <Typography variant="caption">{row?.address?.address}</Typography>
    },
  },
  {
    id: 'timezone',
    label: 'Timezone',
    component: (row) => (
      <Typography variant="caption">{row?.timezone}</Typography>
    ),
  },
]
export const ResellerAccountLocations: FC = () => {
  const { resellerId, accountId } = useParams()
  const accountLocations = useResellerAccountLocations(
    resellerId!,
    accountId!,
    { refetchOnMount: 'always' }
  )
  return (
    <GenericTable
      columns={columns}
      query={accountLocations}
      noDataMessage="No locations found under this account"
      enableDelete={false}
      tableType="location"
      // handleDelete={handleDeleteClick}
    />
  )
}
