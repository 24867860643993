import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Snackbar,
  SnackbarContentProps,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useCategory,
  useCategoryDelete,
  useCategoryUpdate,
  useItems,
} from '../../../../../../hooks'
import {
  StyledButtonContainerForTable,
  StyledTablePaperWrapper,
  NoRecordFound,
  AppLoader,
  AppWarningDialog,
  ClickableIcon,
  ShowOnlineView,
  CreateButtonFlexEnd,
} from '../../../../../../components'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  defaultImageUrl,
  shortenText,
  tableImageStyles,
} from '../../../../../util'
import { MenuCategoryEntity } from '../../../../../../types'
import { FormattedDateTime } from '../../../../../../components/FormattedDateTime'

export function MenuCategory() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId, menuId } = useParams()
  const [isFetching, setIsFetching] = useState(true)
  const category = useCategory(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      setCategoryState(data?.pages?.map((value: any) => value.data).flat())
      setIsFetching(false)
    },
  })
  const menu = useItems(locationId!, menuId!)
  const categoryDelete = useCategoryDelete(locationId, menuId)
  const records = category.data?.pages?.map((value: any) => value.data).flat()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(
    {} as MenuCategoryEntity
  )
  const [selectedCatId, setSelectedCatId] = useState(undefined)
  const [selectedCat, setSelectedCat] = useState({} as MenuCategoryEntity)
  const CatUpdateMutation = useCategoryUpdate(
    locationId!,
    menuId!,
    selectedCatId!
  )

  const [categoryName, setCategoryName] = useState<string>('')
  const [popOpen, setPopOpen] = useState<boolean>(false)

  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false)
  const [highlightedRow, setHighlightedRow] = useState(false)
  const [deletedItem, setDeletedItem] = useState<any>(null)
  const [categoryState, setCategoryState] = useState(records)
  const [deletedList, setDeletedList] = useState<string[]>([])
  const [deletedIndex, setDeletedIndex] = useState<any>(null)
  const [sortedRecords, setSortedRecords] = useState<any>([])
  const undoDuration = 3000

  useEffect(() => {
    setSortedRecords(
      categoryState?.sort((a: any, b: any) => a.position - b.position)
    )
  }, [categoryState])

  useEffect(() => {
    menu.hasNextPage && menu.fetchNextPage()
    const updatedMenu =
      selectedCategory && deletedList.length
        ? records?.filter(
            (item) => ![...deletedList, selectedCategory.id].includes(item.id)
          )
        : records
    setCategoryState(updatedMenu)
  }, [menu.data])

  // const handleDeleteConfirmed = () => {
  //   categoryDelete.mutate(selectedCategory.id, {
  //     onSuccess: () => {
  //       setShowConfirmationDialog(false)
  //     },
  //   })
  // }
  const handleDeleteConfirmed = async () => {
    setShowConfirmationDialog(false)
    setShowUndoSnackbar(true)
    setDeletedList([...deletedList, selectedCategory.id])

    const updatedMenu = categoryState?.filter(
      (cat) => ![...deletedList, selectedCategory.id].includes(cat.id)
    )
    const deletedItem = records?.find((cat) => cat.id === selectedCategory.id)

    const deletedItemIndex = categoryState?.findIndex(
      (cat) => cat.id === deletedItem?.id
    )
    setDeletedIndex(deletedItemIndex)

    setDeletedItem(deletedItem)

    setCategoryState(updatedMenu as any)
  }

  const handleUndo = () => {
    setShowUndoSnackbar(false)
    setHighlightedRow(true)
    setDeletedList([...deletedList.slice(0, -1)])
    if (deletedItem) {
      // Create a copy of the menuItems array
      const updatedMenu = sortedRecords as any

      if (deletedIndex !== -1) {
        updatedMenu.splice(deletedIndex, 0, deletedItem)

        const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
          return (
            updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
          )
        })

        setSortedRecords(uniqueMenu)
      }
    }
  }

  const afterSnackBarClosed = () => {
    setShowUndoSnackbar(false)
    //actuall deletion
    categoryDelete.mutate(selectedCategory.id, {
      onSuccess: () => {
        // setShowConfirmationDialog(false)
        // category.refetch()
      },
    })
  }

  const action = (
    <Fragment>
      <Button
        variant="text"
        color="info"
        size="medium"
        onClick={handleUndo}
        style={{ textTransform: 'none' }}
      >
        Undo
      </Button>
    </Fragment>
  )
  useEffect(() => {
    if (highlightedRow !== false) {
      const timeoutId = setTimeout(() => {
        setHighlightedRow(false)
      }, 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [highlightedRow])

  useEffect(() => {
    let timeoutId: any
    if (showUndoSnackbar) {
      timeoutId = setTimeout(() => {
        setShowUndoSnackbar(false)
        afterSnackBarClosed()
      }, undoDuration)
    }
    return () => clearTimeout(timeoutId)
  }, [showUndoSnackbar, setShowUndoSnackbar, afterSnackBarClosed])

  useEffect(() => {
    selectedCatId !== undefined &&
      CatUpdateMutation.mutate(
        { showOnline: !selectedCat.showOnline },
        {
          onSuccess: () => {
            setSelectedCatId(undefined)
          },
        }
      )
  }, [selectedCatId])
  const handleShowOnlineChange = (e: any, item: any) => {
    setSelectedCatId(item.id)
    setSelectedCat(item)
  }
  if (category.isLoading) {
    return <AppLoader />
  }
  if (category.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  // const handleClick = (category?: any) => {
  //   const { id: catId, name } = category
  //   const itemNames = (
  //     records?.find((obj: any) => obj.id === catId)?.items || []
  //   ).map((data: any) => findNameById(data, itemList))
  //   setItems(itemNames)
  //   setCategoryName(name)
  //   setPopOpen(true)
  // }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">
          {isFetching
            ? `${intl.formatMessage({ id: 'lable_fetching_data' })}`
            : ''}
        </Typography>
        <CreateButtonFlexEnd
          handleClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/category/new`)
          }
          buttonType="category"
        />
      </Box>
      {category.isSuccess &&
      !(
        category?.data?.pages?.map((value: any) => value.data).flat().length > 0
      ) ? (
        <NoRecordFound title="Categories" />
      ) : (
        <StyledTablePaperWrapper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_image' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  {/* <TableCell>
                    {intl.formatMessage({ id: 'label_mapped_item' })}
                  </TableCell> */}
                  <TableCell>
                    {intl.formatMessage({ id: 'label_show_online' })}
                  </TableCell>
                  <TableCell sx={{ width: '300px' }}>
                    {intl.formatMessage({ id: 'label_last_modified_at' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_actions' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRecords?.map((categoryData: any, index: any) => {
                  const shortName = shortenText(categoryData.name, 25)
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{
                        cursor: 'default !important',
                        backgroundColor:
                          highlightedRow &&
                          deletedItem?.name === categoryData.name &&
                          index == deletedIndex
                            ? '#fff1d5'
                            : 'inherit',
                        transition: 'background-color 0.5s linear',
                      }}
                      onClick={() =>
                        navigate(
                          `/app/locations/${locationId}/menu/${menuId}/category/${categoryData.id}`
                        )
                      }
                    >
                      <TableCell>
                        <img
                          style={{ ...tableImageStyles }}
                          src={
                            categoryData.imageUrl
                              ? categoryData.imageUrl
                              : defaultImageUrl
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {categoryData.name?.length > 25 ? (
                          <Tooltip title={categoryData.name}>
                            <Typography variant="body2">
                              {shortenText(categoryData.name, 25)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          categoryData.name
                        )}
                      </TableCell>
                      {/* <TableCell onClick={(e) => e.stopPropagation()}>
                        {categoryData.items
                          .slice(0, 2)
                          .map((data: any, index: number) => {
                            const dataName = findNameById(data, itemList)
                            const maxLength = 40
                            const truncatedDataName = dataName
                              ? shortenText(dataName, maxLength)
                              : dataName
                            return (
                              <Fragment key={index}>
                                {dataName?.length > maxLength ? (
                                  <Tooltip title={dataName}>
                                    <Chip
                                      label={truncatedDataName}
                                      sx={{ margin: 0.3 }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Chip
                                    label={truncatedDataName}
                                    sx={{ margin: 0.3 }}
                                  />
                                )}
                                {index === 1 && categoryData.items.length > 2 && (
                                  <>
                                    <Chip
                                      key={dataName}
                                      label={`+${
                                        categoryData.items.length - 2
                                      }`}
                                      sx={{ margin: 0.3 }}
                                      onClick={() => handleClick(categoryData)}
                                    />
                                  </>
                                )}
                              </Fragment>
                            )
                          })}
                      </TableCell> */}
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <ShowOnlineView
                          item={categoryData}
                          handleShowOnlineChange={(e: any) =>
                            handleShowOnlineChange(e, categoryData)
                          }
                          disabled={isFetching || false}
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={categoryData.updatedAt} />
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Box sx={{ display: 'flex' }}>
                          <ClickableIcon
                            handleNavigate={() =>
                              navigate(
                                `/app/locations/${locationId}/menu/${menuId}/category/${categoryData.id}`
                              )
                            }
                            disabled={isFetching}
                            children={
                              <EditOutlined
                                onClick={() =>
                                  navigate(
                                    `/app/locations/${locationId}/menu/${menuId}/category/${categoryData.id}`
                                  )
                                }
                              />
                            }
                          />
                          <ClickableIcon
                            handleNavigate={() => [
                              setShowConfirmationDialog(true),
                              setSelectedCategory(categoryData),
                            ]}
                            disabled={isFetching}
                            children={
                              <DeleteOutlined
                                onClick={() => [
                                  setShowConfirmationDialog(true),
                                  setSelectedCategory(categoryData),
                                  showUndoSnackbar && afterSnackBarClosed(),
                                ]}
                              />
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {category.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => category.fetchNextPage()}
                disabled={category.isFetching}
                variant="outlined"
                color="primary"
              >
                {category.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </StyledTablePaperWrapper>
      )}
      <AppWarningDialog
        name={selectedCategory.name}
        source="categories"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      />
      <Snackbar
        open={showUndoSnackbar}
        autoHideDuration={undoDuration}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return
          }
          afterSnackBarClosed()
        }}
        message={
          <Typography variant="body2" sx={{ color: 'black' }}>
            Deleted{' '}
            <span style={{ fontWeight: 'bold' }}>{deletedItem?.name}</span>
          </Typography>
        }
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        ContentProps={{
          sx: {
            backgroundColor: 'white',
          } as SnackbarContentProps['sx'],
        }}
      />
    </>
  )
}
