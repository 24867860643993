import { FC } from 'react'
import { Card, CardProps, styled } from '@mui/material'

/**
 * Styled card component.
 */
const StyledCard = styled(Card)(() => ({
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}))

/**
 *
 */
export interface BaseCardProps extends CardProps {
  disabled?: boolean
}

/**
 * @fileoverview BaseCard component.
 * This component is used to render the base card component
 */
export const BaseCard: FC<BaseCardProps> = (props) => {
  return <StyledCard {...props} style={{ opacity: props.disabled ? 0.5 : 1 }} />
}
