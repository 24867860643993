import { Paper, Typography } from '@mui/material'
import { FC } from 'react'

interface NoRecordFoundProps {
  title: string
}

export const NoRecordFound: FC<NoRecordFoundProps> = (props) => {
  return (
    <Paper sx={{ marginTop: 2 }}>
      <Typography align="center" sx={{ padding: 4 }}>
        Hey, it seems you do not have any {''} {props.title} yet. Create your
        first {''}
        {props.title} .
      </Typography>
    </Paper>
  )
}
