import { Add } from '@mui/icons-material'
import { IconButton } from '@mui/material'

interface AddNewIconProps {
  handleNavigate: () => void
}

export const AddNewIcon = (props: AddNewIconProps) => {
  const { handleNavigate } = props
  return (
    <IconButton sx={{ p: 1 }} onClick={handleNavigate}>
      <Add />
    </IconButton>
  )
}
