import { Box, ButtonGroup, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

export const MenuSwitch = (props: any) => {
  const { version } = props
  const navigate = useNavigate()
  const { locationId, menuId } = useParams()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
      <ButtonGroup
        disableElevation
        variant="outlined"
        aria-label="Disabled button group"
      >
        <Button
          color="primary"
          variant={version === 'v1' ? 'contained' : 'outlined'}
          onClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/items`)
          }
        >
          V1 Menu
        </Button>
        <Button
          color="primary"
          onClick={() => {
            navigate(`/app/locations/${locationId}/menus/${menuId}`)
          }}
          variant={version === 'v2' ? 'contained' : 'outlined'}
        >
          V2 Menu
        </Button>
      </ButtonGroup>
    </Box>
  )
}
