import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  Button,
  Grid,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useProductById,
  useCatalogCategory,
  useModifierGroups,
  getLocationQueryById,
} from '../../../../../../hooks'
import { defaultCatalogProductData } from '../../../../../util'
import {
  StyledTablePaperWrapper,
  PageNavigationView,
  AppLoader,
  CatalogViewDetails,
  NutritionalInfoView,
} from '../../../../../../components'
import { CatalogProductEntity } from '../../../../../../types'
import { getCurrencySymbol } from '../../../../../util'

export function ProductView() {
  const navigate = useNavigate()
  const currency = getCurrencySymbol()
  const intl = useIntl()
  const { locationId, productId } = useParams()

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      label: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
    {
      label: 'Items',
      path: `/app/locations/${locationId}/catalog/items`,
    },
  ]
  const [price, setPriceData] = useState<any>()
  const product = useProductById(locationId, productId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setProductState(data.data)
      setPriceData(data.data?.price! / 100)
    },
  })

  const categories = useCatalogCategory(locationId!)
  const modifiers = useModifierGroups(locationId!)
  const categoriesData = categories.data?.pages
    .map((value) => value.data)
    .flat()
  const modifiersData = modifiers.data?.pages.map((value) => value.data).flat()
  useEffect(() => {
    categories.hasNextPage && categories.fetchNextPage()
  }, [categories.data])
  useEffect(() => {
    modifiers.hasNextPage && modifiers.fetchNextPage()
  }, [modifiers.data])
  const categoryOptions =
    (!categories.hasNextPage && categoriesData?.map((record) => record)) || []

  const modifierOptions =
    (!modifiers.hasNextPage && modifiersData?.map((record) => record)) || []

  const [productState, setProductState] = useState<CatalogProductEntity>(
    defaultCatalogProductData
  )

  const selectedCategoryNames = (productState?.categories ?? [])
    ?.map((categoryId: any) => {
      const selectedCategory = categoryOptions?.find(
        (option) => option.id === categoryId
      )
      return selectedCategory ? selectedCategory.name : ''
    })
    .join(', ')
  const selectedModifierGroupNames = (productState?.modifierGroups ?? [])
    ?.map((categoryId: any) => {
      const selectedCategory = modifierOptions?.find(
        (option) => option.id === categoryId
      )
      return selectedCategory ? selectedCategory.name : ''
    })
    .join(', ')

  if (product.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }
  if (
    product.isLoading ||
    !productState?.id ||
    modifiers.hasNextPage ||
    categories.hasNextPage ||
    modifiers.isLoading ||
    categories.isLoading
  ) {
    return <AppLoader />
  }

  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />
      <StyledTablePaperWrapper>
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            onClick={() =>
              navigate(`/app/locations/${locationId}/catalog/items`)
            }
          >
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
        </Box>
        <Grid container spacing={2} sx={{ px: 3 }}>
          <Grid item xs={12} md={9} sm={12}>
            <List>
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({
                    id: 'label_in_stock',
                  })}`,
                  productState?.showOnline
                )}
              </ListItemText>
              {productState.name && (
                <ListItemText sx={{ marginTop: 1 }}>
                  {CatalogViewDetails(
                    `${intl.formatMessage({
                      id: 'label_name',
                    })}`,
                    productState.name
                  )}
                </ListItemText>
              )}
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({
                    id: 'label_price',
                  })}`,
                  `${currency} ${price}`
                )}
              </ListItemText>
              {selectedCategoryNames && (
                <ListItemText sx={{ marginTop: 1 }}>
                  {CatalogViewDetails(
                    `${intl.formatMessage({ id: 'label_category' })}`,
                    selectedCategoryNames
                  )}
                </ListItemText>
              )}
              {selectedModifierGroupNames && (
                <ListItemText sx={{ marginTop: 1 }}>
                  {CatalogViewDetails(
                    `${intl.formatMessage({ id: 'label_modifier_group' })}`,
                    selectedModifierGroupNames
                  )}
                </ListItemText>
              )}
              {productState.description && (
                <ListItemText sx={{ marginTop: 1 }}>
                  {CatalogViewDetails(
                    `${intl.formatMessage({ id: 'label_description' })}`,
                    productState.description
                  )}
                </ListItemText>
              )}
              {productState.containsAlcohol && (
                <ListItemText sx={{ marginTop: 1 }}>
                  {CatalogViewDetails(
                    `${intl.formatMessage({
                      id: 'label_contains_alcohol',
                    })}`,
                    productState.containsAlcohol
                  )}
                </ListItemText>
              )}
              {productState.containsTobacco && (
                <ListItemText sx={{ marginTop: 1 }}>
                  {CatalogViewDetails(
                    `${intl.formatMessage({
                      id: 'label_contains_tobacco',
                    })}`,
                    productState.containsTobacco
                  )}
                </ListItemText>
              )}
            </List>
            <List>
              {productState.nutritionalInfo &&
              Object.keys(productState.nutritionalInfo).length > 0 ? (
                <ListItemText sx={{ marginTop: 1 }}>
                  <b>
                    {intl.formatMessage({
                      id: 'lable_nutritional_info',
                    })}
                  </b>
                  <List>
                    {productState.nutritionalInfo &&
                      Object.entries(productState.nutritionalInfo).map(
                        ([key, value]) => (
                          <ListItemText>
                            <NutritionalInfoView
                              key={key}
                              label={key}
                              value={value}
                            />
                          </ListItemText>
                        )
                      )}
                  </List>
                </ListItemText>
              ) : null}
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box sx={{ display: 'flex' }}>
              <img
                src={product.data?.data?.imageUrl}
                width="100%"
                style={{ borderRadius: '8px' }}
              />
            </Box>
          </Grid>
        </Grid>
      </StyledTablePaperWrapper>
    </>
  )
}
