import { Avatar, Box, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useResellerUsers } from '../../../hooks/useResellerUsers'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppLoader,
  Column,
  GenericTable,
  PageNavigationView,
  UsersTable,
} from '../../../components'
import { CopyToClipboard } from '../../../components/CopyToClipboard'
import { ResellerSearch } from '../ResellerSearch'
import { useQueryString } from '../../../hooks/useQueryString'

const columns: readonly Column[] = [
  {
    // avatar
    id: 'avatar',
    label: '',
    component: (row) => (
      <Avatar variant="rounded">
        {row?.firstName?.charAt(0)?.toUpperCase()}
      </Avatar>
    ),
  },
  {
    id: 'id',
    label: 'ID',
    component: (row) => (
      <CopyToClipboard value={row?.id}>
        <Typography variant="caption">{row?.id}</Typography>
      </CopyToClipboard>
    ),
  },
  {
    id: 'name',
    label: 'Name',
    component: (row) => {
      const fullName = `${row?.firstName} ${row?.lastName}`
      return <Typography variant="caption">{fullName}</Typography>
    },
  },
  {
    id: 'email',
    label: 'Email',
    component: (row) => (
      <CopyToClipboard value={row?.email}>
        <Typography variant="caption">{row?.email}</Typography>
      </CopyToClipboard>
    ),
  },
]

export const ResellerUsers: FC = () => {
  const { resellerId } = useParams()
  const navigate = useNavigate()
  const [queryParamURL, setQuery] = useQueryString(window.location, navigate)
  const [filters, setFilters] = useState(queryParamURL as any)
  const users = useResellerUsers(
    { resellerId: resellerId!, filters: filters! },
    { refetchOnMount: 'always' }
  )

  let navigationPages: any = [
    { label: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { label: 'Users', path: `/app/resellers/${resellerId}/users` },
  ]

  if (users.isLoading) {
    return <AppLoader />
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageNavigationView navigationPages={navigationPages} />
        <ResellerSearch setFilters={setFilters} isLoading={users.isFetching} />
      </Box>
      <UsersTable users={users} filters={filters} />
    </>
  )
}
