import { CardMedia, styled } from '@mui/material'

/**
 * Basic Card Media Component
 */
export const ItemCardMedia = styled(CardMedia)(({ theme }) => ({
  // width: 150,
  // maxWidth: 150,
  // height: 150,
}))
