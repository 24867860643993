import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import axios from 'axios'
import { signalToCancellationToken } from './utils'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export interface UseAccountByIdProps {
  refetchOnMount?: boolean | 'always'
}
export interface UseAccountMetricsOptions {
  range: string
}

export const useAccounts = (options?: any) => {
  const token = useAccessToken()
  const navigate = useNavigate()
  const [setAccountId, logout] = useAuthStore((t) => [t.setAccountId, t.logout])

  return useQuery(
    ['v1', 'accounts'],
    async ({ signal }) => {
      const { data } = await api.get('/v1/accounts', {
        headers: { Authorization: `Bearer ${token?.access_token}` },
        cancelToken: signalToCancellationToken(signal)?.token,
      })
      return data
    },
    {
      // We do not want to retry if we receive an auth error
      retry: (failureCount, error) => {
        return axios.isAxiosError(error) && error.response?.status === 401
          ? false
          : failureCount < 3
      },
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          e.response?.status === 401 && logout()
          navigate('/')
        }
      },
      cacheTime: 3600 * 10,
      onSuccess: (data: any) => {
        setAccountId(data?.data[0]?.id)
      },
      ...options,
    }
  )
}
export const getAccountQueryById = (options?: any) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useQuery(
    ['accounts', accountId],
    ({ signal }) =>
      api
        .get(`/v1/accounts/${accountId}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId,
      ...options,
    }
  )
}

export const useAccountMetrics = (options: UseAccountMetricsOptions) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  // Returnt the query hook
  return useQuery(
    ['accounts', accountId, 'metrics', options.range],
    ({ signal }) =>
      api
        .get(`/v1/accounts/${accountId}/metrics`, {
          params: { range: options.range },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data.data),
    {
      staleTime: 3600 * 60,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId,
    }
  )
}
export interface UseAccountConnectionsProps
  extends Omit<
    UseQueryOptions<any, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}
export const useAccountConnections = (props?: UseAccountConnectionsProps) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  // Returnt the query hook
  return useQuery(
    ['accounts', accountId!, 'connections'],
    ({ signal }) =>
      api
        .get(`/v1/accounts/${accountId}/connections`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data.data),
    {
      enabled: !!token?.access_token && !!accountId,
      refetchOnMount: props?.refetchOnMount,
    }
  )
}

export const useAccountCreate = () => {
  const queryClient = useQueryClient()
  const token = useAccessToken()
  const [setAccountId] = useAuthStore((t) => [t.setAccountId, t.logout])

  return useMutation(
    (account: any) =>
      api
        .post(`/v1/accounts`, account, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        setAccountId(data?.data?.id)
        void queryClient.refetchQueries({
          queryKey: ['v1', 'accounts'],
          exact: true,
        })
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useAccountUpdateMutaion = () => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useMutation(
    (location: any) =>
      api
        .patch(`/v1/accounts/${accountId}`, location, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['v1', 'accounts'],
          exact: true,
        })
        toast.success('Account Updated')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
