import {
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Table,
} from '@mui/material'
import { Fragment } from 'react'
import { ClickableIcon } from '../../../components'
import { EditOutlined } from '@mui/icons-material'

export const RenderListView = (props: any) => {
  const { listOptions, handleNavigation, title } = props
  const sortedModifierGroups = listOptions?.sort(
    (a: any, b: any) => a.position - b.position
  )

  return (
    <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
      <TableContainer style={{ border: '1px solid #eee', borderRadius: 14 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedModifierGroups?.map((group: any, index: any) => (
              <Fragment>
                <TableRow>
                  <TableCell onClick={() => handleNavigation(group.id)}>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography variant="subtitle1" component="p">
                        {group?.name}
                      </Typography>
                      <ClickableIcon
                        handleNavigate={(e: any) => {
                          e.stopPropagation() // Prevents the TableCell click from triggering
                          handleNavigation(group.id)
                        }}
                        children={<EditOutlined />}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
