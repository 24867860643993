import { Box, Switch, Tooltip, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

export const SwitchWithLabel = (props: any) => {
  const { show, handleSwitchChange, disabled, label, size } = props
  const intl = useIntl()
  if (label) {
    return (
      <Box
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <label
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <Switch
            checked={show}
            onChange={(e) => handleSwitchChange(e)}
            disabled={disabled}
            size={size ?? 'small'}
          />
          <Typography variant={size == 'medium' ? 'subtitle2' : 'caption'}>
            {label}
          </Typography>
        </label>
      </Box>
    )
  } else {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: 'label_show_online',
        })}
      >
        <Switch
          checked={show}
          onChange={(e) => handleSwitchChange(e)}
          disabled={disabled}
          size="small"
        />
      </Tooltip>
    )
  }
}
