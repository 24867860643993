import { FC } from 'react'
// @ts-ignore
import home from '../../assets/home.webp'
import { CenteredBox } from '../CenteredBox'

export interface HomeCarouselProps {}
export const HomeCarousel: FC<HomeCarouselProps> = () => {
  return (
    <CenteredBox>
      <img src={home} style={{ height: 'auto', width: '700px' }} />
    </CenteredBox>
  )
}
