import { StateCreator, create } from 'zustand'
import { persist } from 'zustand/middleware'

type AuthToken = {
  token_type: 'Bearer'
  access_token: string
  expires_in: number
  expires_at: number
  refresh_token: string
  scope: string
}

type AuthState = {
  isHydrated: boolean
  isLoggedIn: boolean
  token?: AuthToken
  accountId?: string
  setHydrated: () => void
  setAuth: (token: AuthToken) => void
  setAccountId: (accountId: string) => void
  logout: () => void
}

const storeCreator: StateCreator<AuthState> = (set) => ({
  isHydrated: false,
  isLoggedIn: false,
  setHydrated: () => set((state) => ({ ...state, isHydrated: true })),
  setAuth: (token: AuthToken) =>
    set((a) => ({ ...a, isLoggedIn: true, token })),
  setAccountId: (accountId: string) =>
    set((a) => ({ ...a, isLoggedIn: true, accountId })),
  logout: () => {
    set((state) => ({
      ...state,
      isLoggedIn: false,
      token: undefined,
      accountId: undefined,
    }))
  },
})

export const useAuthStore = create(
  persist<AuthState>(storeCreator, {
    version: 1,
    name: 'auth-storage',
    onRehydrateStorage: (state) => () => state.setHydrated(),
  })
)
