import { useQuery } from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { useAuthStore } from './useAuthStore'
import { useCurrentReseller } from './useResellers'

export interface UseResellerMetricsOptions {
  resellerId: string
  range: string
}

export const useResellerMetrics = (options: UseResellerMetricsOptions) => {
  const reseller = useCurrentReseller()
  const token = useAccessToken()

  // Returnt the query hook
  return useQuery(
    ['v1', 'resellers', options.resellerId, 'metrics', options.range],
    ({ signal }) =>
      api
        .get(`/v1/resellers/${options.resellerId}/metrics`, {
          params: { range: options.range },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data.data),
    {
      staleTime: 3600 * 60,
      keepPreviousData: true,
      enabled: !!token?.access_token && reseller.isSuccess,
    }
  )
}
