/**
 * Select Timezone
 */
import { FC } from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material'
import { timezones } from '../../timezones'
import { useIntl } from 'react-intl'

interface SelectTimezoneProps {
  onChange?: (event: SelectChangeEvent<string>) => void
  errorOnAutoFetch?: boolean
  disableEnable?: boolean
  myInstance?: any
}

export const SelectTimezone: FC<SelectTimezoneProps> = ({
  myInstance,
  onChange,
  errorOnAutoFetch,
  disableEnable,
}) => {
  const intl = useIntl()
  return (
    <FormControl fullWidth disabled={disableEnable}>
      <InputLabel id="timezone-select-label">
        {intl.formatMessage({ id: 'label_timezone' })}
      </InputLabel>
      <Select
        value={myInstance?.timezone}
        labelId="timezone-select-label"
        label={intl.formatMessage({ id: 'label_timezone' })}
        onChange={onChange}
      >
        {timezones.map((timezone) => (
          <MenuItem key={timezone.tzCode} value={timezone.tzCode}>
            {timezone.label}
          </MenuItem>
        ))}
      </Select>
      {errorOnAutoFetch && (
        <FormHelperText>
          {intl.formatMessage({ id: 'error_fetch_timezones' })}
        </FormHelperText>
      )}
    </FormControl>
  )
}
