import { FC } from 'react'
import { FormattedNumber } from 'react-intl'

/**
 * @fileoverview FormattedMoney component
 */

/**
 * FormattedMoneyProps
 * @typedef {Object} FormattedMoneyProps
 * @property {number} value - The value to be formatted
 * @property {string} [currency] - The currency code
 */
export interface FormattedMoneyProps {
  value: number
  currency?: string
  decimalValue?: boolean
}

/**
 * FormattedMoney component
 * @param {FormattedMoneyProps} props - The props
 * @return {JSX.Element} The FormattedMoney component
 */
export const FormattedMoney: FC<FormattedMoneyProps> = (props) => {
  return (
    <FormattedNumber
      value={props.value}
      currency={props.currency}
      style="currency"
      currencySign="accounting"
      maximumFractionDigits={props.decimalValue ? 2 : 0}
      minimumFractionDigits={props.decimalValue ? 2 : undefined}
    />
  )
}
