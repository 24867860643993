import { Box, Typography } from '@mui/material'
import { AppLoader } from '../../components'
import { useIntl } from 'react-intl'
import { FC, useState } from 'react'
import { useAccountMetrics } from '../../hooks'
import { getCurrency } from '../util'
import { CenteredBox } from '../../components/CenteredBox'
import { DashboardView } from './DashboardView'
import { RangeSelector } from '../../components/RangeSelector'

/**
 * Dashboard Component
 * @returns the dashboard component
 */
export const Dashboard: FC = () => {
  const currency = getCurrency()
  const [seelctedTimeRange, setSelectedTimeRange] = useState('1m')
  const metrics = useAccountMetrics({ range: seelctedTimeRange })
  const { formatNumber } = useIntl()

  if (metrics.isLoading) {
    return <AppLoader />
  }

  if (metrics.isError || metrics.isSuccess === false) {
    return (
      <CenteredBox>
        <Typography>
          Metrics are not currently available, please try again later.
        </Typography>
      </CenteredBox>
    )
  }

  /**
   * Extract the different aggregations from the metrics data
   */
  const {
    totalOrders,
    totalOrderValue,
    averageOrderValue,
    totalOrderValueByFulfillemtType,
    totalOrderValueByOrderSource,
    orderByLocationHistogram,
    orderStatusVolume,
  } = metrics.data?.aggregations

  /**
   * Render the dashboardview with the available metrics
   */
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ flexGrow: 1, float: 'right' }}>
        <RangeSelector
          options={['6h', '1d', '3d', '1w', '1m', '3m', '6m']}
          selected={seelctedTimeRange}
          onChange={(newValue) => setSelectedTimeRange(newValue)}
        />
      </Box>
      <DashboardView
        totalOrders={totalOrders}
        totalOrderValue={totalOrderValue}
        orderStatusVolume={orderStatusVolume}
        averageOrderValue={averageOrderValue}
        totalOrderValueByFulfillemtType={totalOrderValueByFulfillemtType}
        totalOrderValueByOrderSource={totalOrderValueByOrderSource}
        orderByLocationHistogram={orderByLocationHistogram}
        formatLabelAsMoney={(tooltipItem: any) =>
          formatNumber(tooltipItem.parsed.y, { currency, style: 'currency' })
        }
        foramtTickAsMoney={(tickValue: any) =>
          formatNumber(+tickValue, { currency: currency, style: 'currency' })
        }
      />
    </Box>
  )
}
