import { Grid } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

export const ResellerAccountAudits: FC = () => {
  const {} = useParams()

  return (
    <Grid container>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        View Account Audits
      </Grid>
    </Grid>
  )
}
