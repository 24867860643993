import { FC } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { Avatar, Typography } from '@mui/material'
import { Column, GenericTable } from '../'
import { CopyToClipboard } from '../CopyToClipboard'

const columns: readonly Column[] = [
  {
    // avatar
    id: 'avatar',
    label: '',
    component: (row) => (
      <Avatar variant="rounded">
        {row?.firstName?.charAt(0)?.toUpperCase()}
      </Avatar>
    ),
  },
  {
    id: 'id',
    label: 'ID',
    component: (row) => (
      <CopyToClipboard value={row?.id}>
        <Typography variant="caption">{row?.id}</Typography>
      </CopyToClipboard>
    ),
  },
  {
    id: 'name',
    label: 'Name',
    component: (row) => {
      const fullName = `${row?.firstName} ${row?.lastName}`
      return <Typography variant="caption">{fullName}</Typography>
    },
  },
  {
    id: 'email',
    label: 'Email',
    component: (row) => (
      <CopyToClipboard value={row?.email}>
        <Typography variant="caption">{row?.email}</Typography>
      </CopyToClipboard>
    ),
  },
]

export interface UsersTableProps {
  users: UseInfiniteQueryResult<any, any>
  filters?: any
}

export const UsersTable: FC<UsersTableProps> = ({ users, filters }) => {
  return (
    <GenericTable
      columns={columns}
      query={users}
      noDataMessage="No users found"
      enableDelete={false}
      tableType="user"
      filters={filters}
    />
  )
}
