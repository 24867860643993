import { Box, Switch, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export const ShowOnlineView = (props: any) => {
  const { item, handleShowOnlineChange, disabled, label } = props
  const intl = useIntl()
  const [showOnline, setShowOnline] = useState(item.showOnline)
  useEffect(() => {
    setShowOnline(item.showOnline)
  }, [item])
  const handleShowOnline = (e: any, item: any) => {
    setShowOnline(e.target.checked)
    handleShowOnlineChange(e, item)
  }
  if (label) {
    return (
      <Box
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <label
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <Switch
            checked={showOnline}
            onChange={(e) => handleShowOnline(e, item)}
            disabled={disabled}
            size="small"
          />
          <Typography variant="caption">{label}</Typography>
        </label>
      </Box>
    )
  } else {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: 'label_show_online',
        })}
      >
        <Switch
          checked={showOnline}
          onChange={(e) => handleShowOnline(e, item)}
          disabled={disabled}
          size="small"
        />
      </Tooltip>
    )
  }
}
