import { Tooltip, Typography } from '@mui/material'

export const TruncateName = (name: string) => {
  const maxLength = 30
  const truncatedDataName =
    name?.length > maxLength ? name.substring(0, maxLength) + '...' : name
  if (name?.length > maxLength) {
    return (
      <Tooltip title={name}>
        <Typography style={{ fontSize: 'inherit' }}>
          {truncatedDataName}
        </Typography>
      </Tooltip>
    )
  } else {
    return truncatedDataName
  }
}
