import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from '@mui/material'
import {
  EmailOutlined,
  AccountCircleOutlined,
  RadioButtonUnchecked,
  RadioButtonChecked,
} from '@mui/icons-material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRegisterMutation } from '../../hooks'
import { RegisterModel } from '../../model/RegisterModel'
// @ts-ignore
import logo from '../../assets/logo.webp'
import { HomeCarousel, AppLoader, CommonTextField } from '../../components'
import { useIntl } from 'react-intl'
import { findIdByName } from '../util'
import { MuiTelInput } from 'mui-tel-input'
import { validatePhoneNumberLength } from 'libphonenumber-js'

const iconRadio = <RadioButtonUnchecked fontSize="small" />
const iconRadioChecked = <RadioButtonChecked fontSize="small" />
export function SignUp() {
  const navigate = useNavigate()
  const [isValidName, setFirstNameValid] = useState(true)
  const [isEmailValid, setEmailValid] = useState(true)
  const [isPasswordValid, setPasswordValid] = useState(true)
  const [isValid, setValid] = useState(false)
  const [myInstance, setMyInstance] = useState(new RegisterModel())
  const registerMutation = useRegisterMutation()
  const [showPassword, setShowPassword] = useState(false)
  const languages = [
    {
      id: 'en-GB',
      name: 'English (United Kingdom)',
    },
    {
      id: 'en-US',
      name: 'English (United States)',
    },
    // {
    //   id: 'fr-FR',
    //   name: 'French',
    // },
    // {
    //   id: 'es-ES',
    //   name: 'Spanish',
    // },
    // {
    //   id: 'de-DE',
    //   name: 'German',
    // },
    // {
    //   id: 'it-IT',
    //   name: 'Italian',
    // },
  ]
  const languageNames = languages.map((languages: any) => languages.name)
  const selectedLanguage = languages.find(
    (language) => language.id === myInstance.language
  )
  function validateAndSet(profileData: any) {
    var newObject = JSON.parse(JSON.stringify(profileData))
    setMyInstance(newObject)
    validationCheck()
  }
  function validationCheck() {
    setValid(
      myInstance.firstName != null &&
        validateFirstName(myInstance.firstName.trim()) &&
        myInstance.email != null &&
        validateEmail(myInstance?.email) &&
        myInstance.password != null &&
        validatePassword(myInstance?.password.trim()) &&
        myInstance.language != null &&
        myInstance.phone != null &&
        !validatePhoneNumberLength(myInstance.phone)
    )
  }
  const handleChangeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.firstName = event.target.value
    validateAndSet(myInstance)
  }

  const handleChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.lastName = event.target.value
    validateAndSet(myInstance)
  }
  const handleChangeLanguage = (event: any, value: any) => {
    const languageId = findIdByName(value, languages)
    myInstance.language = languageId
    validateAndSet(myInstance)
  }
  const handlePhoneChange = (number: string) => {
    const phone = number.replace(/\s/g, '')
    myInstance.phone = phone
    validateAndSet(myInstance)
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.email = event.target.value
    validateAndSet(myInstance)
  }
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.password = event.target.value
    validateAndSet(myInstance)
  }
  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setEmailValid(emailRegex.test(email))
    return emailRegex.test(email)
  }

  const validatePassword = (password: any) => {
    setPasswordValid(password?.length >= 4)
    return password?.length >= 4
  }
  const validateFirstName = (name: any) => {
    setFirstNameValid(name?.length >= 3)
    return name?.length >= 3
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }
  const handleChangeRegister = async () => {
    registerMutation.mutate(myInstance, {
      onSuccess: () => {
        navigate('/', {
          state: {
            email: myInstance.email,
            phone: myInstance.phone,
            from: 'register',
          },
        })
      },
      onError: (error) => {
        myInstance.password = ''
      },
    })
  }
  if (registerMutation.isLoading) {
    return <AppLoader />
  }

  return (
    <>
      <Grid container spacing={0} style={{ minHeight: '100vh' }}>
        <Grid item sm={7} md={7} display={{ xs: 'none', sm: 'block' }}>
          <HomeCarousel />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          sx={{
            paddingLeft: {
              xs: 2,
              lg: 10,
            },
            paddingRight: {
              xs: 2,
              lg: 10,
            },
            background: 'white',
          }}
        >
          <RegisterFormView
            isValidName={isValidName}
            handleChangeFirstName={handleChangeFirstName}
            handleChangeLastName={handleChangeLastName}
            handleEmailChange={handleEmailChange}
            handlePhoneChange={handlePhoneChange}
            handleChangeLanguage={handleChangeLanguage}
            handlePasswordChange={handlePasswordChange}
            handleChangeRegister={handleChangeRegister}
            handleTogglePassword={handleTogglePassword}
            showPassword={showPassword}
            isValid={isValid}
            isEmailValid={isEmailValid}
            isPasswordValid={isPasswordValid}
            languageNames={languageNames}
            myInstance={myInstance}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      </Grid>
    </>
  )
}
const RegisterFormView = (props: any) => {
  const {
    handleChangeFirstName,
    handleChangeLastName,
    handleEmailChange,
    handlePhoneChange,
    handleChangeLanguage,
    handlePasswordChange,
    handleChangeRegister,
    handleTogglePassword,
    showPassword,
    isValid,
    isEmailValid,
    isPasswordValid,
    isValidName,
    languageNames,
    myInstance,
    selectedLanguage,
  } = props
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          style={{ width: 176, position: 'relative', bottom: '55px' }}
          src={logo}
        />
      </Box>
      <Box>
        <Typography variant="h5" component="h5">
          {intl.formatMessage({ id: 'label_register' })}
        </Typography>
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <CommonTextField
          name="firstName"
          type="text"
          label={intl.formatMessage({ id: 'label_first_name' })}
          error={!isValidName}
          autoFocus={true}
          value={myInstance.firstName || ''}
          helperText={
            !isValidName
              ? `${intl.formatMessage({ id: 'misc_name_min_length' })}`
              : ' '
          }
          onChange={handleChangeFirstName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircleOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <CommonTextField
          name="LastName"
          type="text"
          label={intl.formatMessage({ id: 'label_last_name' })}
          onChange={handleChangeLastName}
          value={myInstance.lastName || ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircleOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <MuiTelInput
          name="PhoneNumber"
          label={intl.formatMessage({ id: 'label_phone_number' })}
          placeholder="e.g. +1 123-456-7890"
          value={myInstance.phone || ''}
          onChange={handlePhoneChange}
          fullWidth
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Autocomplete
          color="info"
          limitTags={1}
          id="choose-language"
          options={languageNames}
          onChange={handleChangeLanguage}
          value={selectedLanguage?.name || ''}
          disableClearable
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ padding: 0 }}>
              <Radio
                color="info"
                icon={iconRadio}
                checkedIcon={iconRadioChecked}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={intl.formatMessage({
                id: 'action_select_language',
              })}
              name="selectLanguage"
            />
          )}
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <CommonTextField
          name="email"
          type="email"
          label={intl.formatMessage({ id: 'label_email' })}
          onChange={handleEmailChange}
          value={myInstance.email || ''}
          error={!isEmailValid}
          helperText={
            !isEmailValid
              ? `${intl.formatMessage({ id: 'error_invalid_email' })}`
              : ' '
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <CommonTextField
          name="password"
          type={showPassword ? 'text' : 'password'}
          label={intl.formatMessage({ id: 'label_password' })}
          onChange={handlePasswordChange}
          error={!isPasswordValid}
          onKeyDown={(event) => {
            if (event.key == 'Enter' && isValid) void handleChangeRegister()
          }}
          helperText={
            !isPasswordValid
              ? `${intl.formatMessage({ id: 'misc_password_min_length' })}`
              : ' '
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <Button
          variant="contained"
          color="success"
          sx={{ height: '50px' }}
          onClick={handleChangeRegister}
          disabled={!isValid}
          fullWidth
          size="large"
        >
          {intl.formatMessage({ id: 'action_create_account' })}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 4,
        }}
      >
        <Typography component={'span'}>
          <Typography component={'span'} marginRight={1}>
            {intl.formatMessage({
              id: 'misc_already_have_account_question',
            })}
          </Typography>
          <Typography
            component={'span'}
            color={'primary'}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            {intl.formatMessage({ id: 'action_login' })}
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}
