import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import {
  GetAccountByIdResponse,
  GetAccountLocationByIdResponse,
  ListAccountLocationResponse,
} from '../types'
import { toast } from 'react-toastify'

export interface UseResellerByIdOptions
  extends Omit<
    UseQueryOptions<GetAccountByIdResponse, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}

export interface UseAccountByIdProps
  extends Omit<
    UseQueryOptions<any, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}

/**
 * useResellerById
 */
export const useResellerAccountLocations = (
  resellerId: string,
  accountId: string,
  options?: any
) => {
  const token = useAccessToken()
  return useInfiniteQuery<ListAccountLocationResponse>(
    ['v1', 'resellers', resellerId, 'accounts', accountId, 'locations'],
    ({ signal, pageParam }) =>
      api
        .get(`/v1/resellers/${resellerId}/accounts/${accountId}/locations`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
          params: {
            limit: 20,
            nextPageKey: pageParam,
          },
        })
        .then<ListAccountLocationResponse>(({ data }) => data),
    {
      staleTime: 3600 * 60,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      enabled: !!token?.access_token && !!resellerId,
      ...options,
    }
  )
}

export const useResellerAccountLocationById = (
  resellerId: string,
  accountId: string,
  locationId: string,
  options: UseResellerByIdOptions
) => {
  const token = useAccessToken()

  return useQuery<any, any, GetAccountLocationByIdResponse, string[]>(
    [
      'v1',
      'resellers',
      resellerId,
      'accounts',
      accountId,
      'locations',
      locationId,
    ],
    ({ signal }) =>
      api
        .get(
          `/v1/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!resellerId,
      ...options,
    }
  )
}

export const useResellerAccountLocationUpdate = (
  resellerId: string,
  accountId: string,
  locationId: string
) => {
  const queryClient = useQueryClient()
  const token = useAccessToken()
  return useMutation(
    (location: any) =>
      api
        .patch(
          `/v1/resellers/${resellerId}/accounts/${accountId}/locations/${locationId}`,
          location,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          'v1',
          'resellers',
          resellerId,
          'accounts',
          accountId,
          'locations',
        ])
        toast.success('Location Updated')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useResellerAccountLocationCreate = (
  resellerId: string,
  accountId: string,
  options?: any
) => {
  const token = useAccessToken()
  const queryClient = useQueryClient()

  return useMutation(
    (location: any) =>
      api
        .post(
          `/v1/resellers/${resellerId}/accounts/${accountId}/locations/`,
          location,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          'v1',
          'resellers',
          resellerId,
          'accounts',
          accountId,
          'locations',
        ])
        toast.success('Location Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
      ...options,
    }
  )
}
