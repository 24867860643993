import {
  Drawer,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from '@mui/material'
import { FC, useState } from 'react'
import { AppSideBarMenu } from './AppSideBarMenu'
import { useAccounts, useAuthStore } from '../../hooks'
import { AccountSwitcherDialog } from './AccountSwitcher'

const drawerWidth = 240

interface SideBarProps {
  setIsDrawerOpen?: any
  isDrawerOpen?: any
}

export const AppSideBar: FC<SideBarProps> = (props) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <AppSideBarMenu
        setIsDrawerOpen={props.setIsDrawerOpen}
        isDrawerOpen={props.isDrawerOpen}
      />
      {/* Flex to push down */}
      <div style={{ flex: 1 }} />

      {/* Account Switcher */}
      <AccountListItem />
    </Drawer>
  )
}

export const AccountListItem: FC = () => {
  const accounts = useAccounts()
  const [accountId] = useAuthStore((store) => [store.accountId])
  const [isSwitcherOpen, setSwitcherOpen] = useState(false)

  if (accounts.isSuccess && accounts.data?.data.length <= 1) {
    return null
  }

  const current = accounts.data?.data.find(
    (account: any) => account.id === accountId
  )

  return (
    <>
      {isSwitcherOpen && (
        <AccountSwitcherDialog onClose={() => setSwitcherOpen(false)} />
      )}
      <ListItem disablePadding>
        <ListItemButton onClick={() => setSwitcherOpen(true)}>
          {/* {props.icon && (
            <ListItemIcon sx={{ color: isSelected ? '#000000' : '' }}>
              {props.icon}
            </ListItemIcon>
          )} */}
          <ListItemText
            primary={current.name}
            secondary={current.address.formatted_address}
            secondaryTypographyProps={{ noWrap: true }}
          />
        </ListItemButton>
      </ListItem>
    </>
  )
}
