import {
  ListItem,
  IconButton,
  Divider,
  ListItemText,
  Dialog,
  DialogTitle,
  List,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { FC } from 'react'
interface Props {
  items: string[]
  open: boolean
  categoryName: string
  onClose: () => void
}

export const ItemsListPop: FC<Props> = ({
  categoryName,
  items,
  open,
  onClose,
}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {categoryName}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <List
        sx={{
          width: 360,
          height: 400,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          '& ul': { padding: 0 },
        }}
      >
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}
