import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { Navigate, useLocation, useNavigate } from 'react-router'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useLoginMutation, useLoginOtpMutation } from '../../hooks'
import { useAuthStore } from '../../hooks'
import { LoginModel, LoginOtpModel } from '../../model/LoginModel'
// @ts-ignore
import logo from '../../assets/logo.webp'
import { HomeCarousel, AppLoader, CommonTextField } from '../../components'
import { useIntl } from 'react-intl'
import { LoadingButton } from '../../components/MUIStyledComponents'
import { MuiTelInput } from 'mui-tel-input'
import { validatePhoneNumberLength } from 'libphonenumber-js'

export function SignIn() {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const isPhoneState = location?.state?.phone
  const isEmailState = location?.state?.email
  const [isLoggedIn] = useAuthStore((store) => [store.isLoggedIn])
  const [showPassword, setShowPassword] = useState(false)
  const loginMutation = useLoginMutation()
  const loginOtpMutation = useLoginOtpMutation()
  const [myInstance] = useState(
    new LoginModel({
      userId: location.state?.from === 'register' ? isEmailState : undefined,
    })
  )
  const [loginOtpInstance] = useState(
    new LoginOtpModel({ phone: isPhoneState })
  )
  const [isValid, setValid] = useState(false)
  const [isEmailValid, setEmailValid] = useState(true)
  const [isPasswordValid, setPasswordValid] = useState(true)
  const [currentTab, setCurrentTab] = useState(0)
  const [isValidOTP, setValidOTP] = useState(false)
  const [isOTP, setIsOTP] = useState(isPhoneState ? true : false)
  const [phoneNumber, setPhoneNumber] = useState('' as string)
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(false)

  useEffect(() => {
    if (isPhoneState && location.state?.from === 'register') {
      loginOtpMutation.mutate(loginOtpInstance)
    }
  }, [])
  /**
   * Redirect if the user is logged in
   */

  if (isLoggedIn) {
    if (location.state?.from === 'register') {
      return <Navigate to={'/app/accounts/new'} />
    } else {
      return <Navigate to={'/app/locations'} />
    }
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.username = event.target.value
    setValid(validate(myInstance))
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.password = event.target.value
    setValid(validate(myInstance))
  }

  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setEmailValid(emailRegex.test(email))
    return emailRegex.test(email)
  }

  const validatePassword = (password: any) => {
    setPasswordValid(password?.length >= 4)
    return password?.length >= 4
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const validate = (data: LoginModel) => {
    return (
      data.username != null &&
      validateEmail(data?.username) &&
      data.password != null &&
      validatePassword(data?.password.trim())
    )
  }
  const handleChangeLogin = () => {
    loginMutation.mutate(myInstance, {
      onError: () => {
        myInstance.username = ''
        myInstance.password = ''
        setValid(false)
      },
    })
  }
  const validateOTP = (otp: string) => {
    const otpRegex = /^[0-9]{4,6}$/
    setValidOTP(otpRegex.test(otp))
    return otpRegex.test(otp)
  }
  const handleChangeVerifyOTP = (event: ChangeEvent<HTMLInputElement>) => {
    loginOtpInstance.code = event.target.value
    setValidOTP(validateOTP(event.target.value))
  }
  const handleVerifyOTP = () => {
    loginOtpMutation.mutate(loginOtpInstance, {
      onSuccess: () => {},
      onError: () => {},
    })
  }
  if (loginMutation.isLoading) {
    return <AppLoader />
  }
  const handleSendOTP = () => {
    setIsOTP(true)
    loginOtpMutation.mutate(loginOtpInstance, {
      onSuccess: () => {},
      onError: () => {
        setIsOTP(false)
      },
    })
  }
  const validatePhone = (phone: string) => {
    return validatePhoneNumberLength(phone) ? false : true
  }
  const handlePhoneChange = (number: string) => {
    const phone = number.replace(/\s/g, '')
    setPhoneNumber(phone)
    loginOtpInstance.phone = phone
    setValidPhoneNumber(validatePhone(phone))
  }

  return (
    <>
      <Grid container spacing={0} style={{ minHeight: '100vh' }}>
        <Grid item sm={7} md={7} display={{ xs: 'none', sm: 'block' }}>
          <HomeCarousel />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          sx={{
            paddingLeft: {
              xs: 2,
              lg: 10,
            },
            paddingRight: {
              xs: 2,
              lg: 10,
            },
            background: 'white',
          }}
        >
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ width: 176, position: 'relative', bottom: '55px' }}
                src={logo}
              />
            </Box>
            <Box>
              <Typography variant="h5" component="h5">
                {intl.formatMessage({ id: 'action_login' })}
              </Typography>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={currentTab}
                onChange={(_, newValue) => setCurrentTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  label={intl.formatMessage({ id: 'label_sign_in_with_otp' })}
                />
                <Tab
                  label={intl.formatMessage({
                    id: 'label_sign_in_with_password',
                  })}
                />
              </Tabs>
            </Box>
            {currentTab === 1 && (
              <LoginWithPassword
                handleEmailChange={handleEmailChange}
                handlePasswordChange={handlePasswordChange}
                handleTogglePassword={handleTogglePassword}
                showPassword={showPassword}
                isEmailValid={isEmailValid}
                isPasswordValid={isPasswordValid}
                isValid={isValid}
                handleChangeLogin={handleChangeLogin}
                isLoadingButton={loginMutation.isLoading}
              />
            )}

            {currentTab === 0 && (
              <LoginWithOTP
                handleSendOTP={handleSendOTP}
                isOTP={isOTP}
                handleChangeVerifyOTP={handleChangeVerifyOTP}
                isValidOTP={isValidOTP}
                handlePhoneChange={handlePhoneChange}
                isValidPhoneNumber={isValidPhoneNumber}
                isLoadingButton={loginOtpMutation.isLoading}
                phoneNumber={phoneNumber}
                handleVerifyOTP={handleVerifyOTP}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 4,
              }}
            >
              <Typography component={'span'}>
                <Typography component={'span'} marginRight={1}>
                  {intl.formatMessage({ id: 'misc_no_account_yet_question' })}
                </Typography>
                <Typography
                  component={'span'}
                  color={'primary'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate('/register')}
                >
                  {intl.formatMessage({ id: 'action_register' })}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
const LoginWithOTP = (props: any) => {
  const intl = useIntl()
  const {
    handleSendOTP,
    isOTP,
    handleChangeVerifyOTP,
    handleVerifyOTP,
    isValidOTP,
    handlePhoneChange,
    isLoadingButton,
    isValidPhoneNumber,
    phoneNumber,
  } = props
  const telInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (telInputRef.current) {
      telInputRef.current.focus()
    }
  }, [])
  return (
    <>
      {!isOTP && (
        <>
          <Box sx={{ mt: 4 }}>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              {intl.formatMessage({ id: 'label_enter_phone_for_login' })}
            </Typography>
            <MuiTelInput
              label={intl.formatMessage({ id: 'label_phone_number' })}
              placeholder="e.g. +1 123-456-7890"
              value={phoneNumber || ''}
              autoFocus={true}
              onChange={handlePhoneChange}
              fullWidth
              onKeyDown={(event: any) => {
                if (event.key == 'Enter') void handleSendOTP()
              }}
              inputProps={{
                ref: telInputRef,
              }}
            />
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <LoadingButton
              variant="contained"
              color="success"
              disabled={!isValidPhoneNumber}
              loading={isLoadingButton}
              sx={{ height: '50px' }}
              onClick={() => handleSendOTP()}
              fullWidth
              size="large"
              children={intl.formatMessage({ id: 'action_send_otp' })}
            />
          </Box>
        </>
      )}
      {isOTP && (
        <>
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              {intl.formatMessage({ id: 'label_enter_otp_for_login' })}
            </Typography>
            <CommonTextField
              type="number"
              label={intl.formatMessage({ id: 'label_enter_otp' })}
              autoFocus={true}
              // error={!isValidOTP}
              // helperText={
              //   !isValidOTP
              //     ? `Invalid OTP. Please enter a valid 4-6 digit OTP`
              // : ' '
              // }
              onKeyDown={(event) => {
                if (event.key == 'Enter' && isValidOTP) void handleVerifyOTP()
              }}
              onChange={handleChangeVerifyOTP}
            />
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <LoadingButton
              variant="contained"
              color="success"
              sx={{ height: '50px' }}
              onClick={() => handleVerifyOTP()}
              loading={false}
              disabled={!isValidOTP || isLoadingButton}
              fullWidth
              size="large"
              children={intl.formatMessage({ id: 'action_verify_otp' })}
            />
          </Box>
        </>
      )}
    </>
  )
}
const LoginWithPassword = (props: any) => {
  const {
    handleEmailChange,
    handlePasswordChange,
    handleTogglePassword,
    showPassword,
    isEmailValid,
    isPasswordValid,
    isValid,
    handleChangeLogin,
    isLoadingButton,
  } = props
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <>
      <Box sx={{ marginTop: 4 }}>
        <CommonTextField
          type="email"
          label={intl.formatMessage({ id: 'label_email' })}
          onChange={handleEmailChange}
          error={!isEmailValid}
          autoFocus={true}
          helperText={
            !isEmailValid
              ? `${intl.formatMessage({ id: 'error_invalid_email' })}`
              : ' '
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <CommonTextField
          type={showPassword ? 'text' : 'password'}
          label={intl.formatMessage({ id: 'label_password' })}
          onChange={handlePasswordChange}
          error={!isPasswordValid}
          onKeyDown={(event) => {
            if (event.key == 'Enter' && isValid) handleChangeLogin()
          }}
          helperText={
            !isPasswordValid
              ? `${intl.formatMessage({ id: 'misc_password_min_length' })}`
              : ' '
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: 1,
          }}
        >
          <Typography
            component={'span'}
            color={'primary'}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/reset-password')}
          >
            {intl.formatMessage({ id: 'action_forgot_password' })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <LoadingButton
          variant="contained"
          color="success"
          sx={{ height: '50px' }}
          onClick={handleChangeLogin}
          disabled={!isValid}
          loading={isLoadingButton}
          fullWidth
          size="large"
          children={intl.formatMessage({ id: 'action_login' })}
        />
      </Box>
    </>
  )
}
