import { ChangeEvent, FC, useState } from 'react'
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'
import {
  CheckBoxOutlineBlank,
  CheckBox,
  SearchOutlined,
} from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { AppLoader, CommonTextField } from '../../components'

export interface OrderFilterProps {
  onApply: (data: any) => void
  onReset: () => void
  queryParams: any
  locations: any
  loading?: boolean
}

const iconCheckBox = <CheckBoxOutlineBlank fontSize="small" />
const iconCheckBoxChecked = <CheckBox fontSize="small" />

const statusTypes = [
  'NEW',
  'PLACED',
  'ACCEPTED',
  'PREPARING',
  'READY',
  'DELIVERING',
  'COMPLETED',
  'REJECTED',
  'CANCELLED',
  'DELIVERY_FAILED',
]
const fulfillmentTypes = [
  'DELIVERY',
  'PICKUP',
  'DINE-IN',
  'WAITING',
  'IN_STORE',
]

export const OrderFilter: FC<OrderFilterProps> = (props) => {
  const { locations, queryParams, onApply, loading } = props
  const intl = useIntl()
  console.log('locations', locations)
  /**
   * We create local state of the supported filters so we can power the UI,
   * once the user clicks apply we will inform the parent component of the new filters,
   * which will be persisted in the URL
   */
  const [filters, setFilters] = useState({
    query: queryParams.query,
    status: queryParams.status,
    fulfillmentType: queryParams.fulfillmentType,
    locationId: queryParams.locationId,
  })

  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value || undefined!
    setFilters({ ...filters, query })
  }

  const handleChangeStatus = (event: any, value: any) => {
    const status = value?.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, status })
    onApply({ ...filters, status }) // Auto Apply the filters when the status changes
  }
  const handleChangeType = (event: any, value: any) => {
    const fulfillmentType = value?.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, fulfillmentType })
    onApply({ ...filters, fulfillmentType }) // Auto Apply the filters when the type changes
  }
  const handleChangeLocation = (event: any, value: any[]) => {
    const locationId =
      value?.length > 0
        ? value.map((location) => location.id).join(',')
        : undefined
    setFilters({ ...filters, locationId })
    onApply({ ...filters, locationId }) // Auto Apply the filters when the location changes
  }

  const selectedLocations = filters.locationId?.split(',') || []

  const selected = locations?.filter((location: any) =>
    selectedLocations.includes(location.id)
  )
  if (loading) return <AppLoader />
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={3}>
        <CommonTextField
          id="outlined-basic"
          size="small"
          label={intl.formatMessage({ id: 'action_search' })}
          onChange={handleChangeText}
          value={filters.query || ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={2.6} lg={2.6}>
        <Autocomplete
          color="info"
          multiple
          size="small"
          limitTags={1}
          id="status-checkboxes-tags-demo"
          options={statusTypes}
          onChange={handleChangeStatus}
          value={filters.status?.split(',') || []}
          disableCloseOnSelect
          onInputChange={(event, value, reason) => {
            if (reason === 'clear') {
              // Handle the clear event here
              handleChangeStatus([], null) // Clear the selection
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ padding: 0, textTransform: 'uppercase' }}>
              <Checkbox
                size="small"
                icon={iconCheckBox}
                checkedIcon={iconCheckBoxChecked}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={intl.formatMessage({ id: 'label_status' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2.6} lg={2.6}>
        <Autocomplete
          color="info"
          size="small"
          multiple
          limitTags={1}
          id="fulfillment-types-checkboxes-tags-demo"
          value={filters.fulfillmentType?.split(',') || []}
          options={fulfillmentTypes}
          onChange={handleChangeType}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ padding: 0, textTransform: 'uppercase' }}>
              <Checkbox
                color="info"
                size="small"
                icon={iconCheckBox}
                checkedIcon={iconCheckBoxChecked}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={intl.formatMessage({ id: 'label_fulfilment_type' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2.6} lg={2.6}>
        <Autocomplete
          color="info"
          size="small"
          multiple
          limitTags={1}
          id="locations-filter-tags"
          options={locations}
          loading={loading}
          onChange={handleChangeLocation}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          value={selected} // Ensure selectedLocation is properly passed to the value prop
          renderOption={(props, option, state) => (
            <li {...props} style={{ padding: 0 }}>
              <Checkbox
                color="info"
                size="small"
                icon={iconCheckBox}
                checkedIcon={iconCheckBoxChecked}
                style={{ marginRight: 8 }}
                checked={state.selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={intl.formatMessage({ id: 'action_select_location' })}
            />
          )}
        />
      </Grid>
      <Grid item xs={1} lg={1}>
        <Button
          size="medium"
          variant="outlined"
          onClick={() => onApply(filters)}
        >
          {intl.formatMessage({ id: 'action_apply' })}
        </Button>
      </Grid>
    </Grid>
  )
}
