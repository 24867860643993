import { Box, Button } from '@mui/material'
import { FC } from 'react'
import { useIntl } from 'react-intl'

interface CreateButtonFlexEndProps {
  handleClick: () => void
  buttonType: string
}
export const CreateButtonFlexEnd: FC<CreateButtonFlexEndProps> = (props) => {
  const { handleClick, buttonType } = props
  const intl = useIntl()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
      <Button variant="contained" color="primary" onClick={handleClick}>
        {intl.formatMessage({ id: 'action_create' })}{' '}
        {intl.formatMessage({ id: `label_${buttonType}` })}
      </Button>
    </Box>
  )
}
