import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  PageNavigationView,
  StyledTablePaperWrapper,
} from '../../../components'
import {
  getLocationQueryById,
  useItemById,
  useMenuById,
  useMenuModifierGroupById,
  useMenuModifierGroupUpdate,
  useMenuModifiers,
  useModifierGroupById,
} from '../../../hooks'
import { ModifierGroupEditForm } from '../../../components/ItemEditForm/ModifierGroupEditForm'
import {
  defaultMenuModifierGroupGetState,
  extractMenuModifierGroupPatchPayload,
} from '../../Locations/CardSelect/Menu/utils'
import { MenuModifierGroupEntity } from '../../../types'
import { Box } from '@mui/material'

export function MenuItemModifierGroupEdit() {
  const { locationId, menuId, itemId, modifierGroupId } = useParams()
  const isMenuV2 = window.location.pathname.includes('menus')
  const urlMenu = isMenuV2 ? 'menus' : 'menu'
  const urlItem = isMenuV2 ? 'items' : 'item'
  const navigate = useNavigate()
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
  })
  const modifierGroupData = useMenuModifierGroupById(
    locationId!,
    menuId!,
    modifierGroupId!,
    {
      onSuccess: (data) => {
        if (data?.data) setModifierGroupState(data.data)
      },
      refetchOnMount: 'always',
    }
  )
  const [modifierGroupState, setModifierGroupState] =
    useState<MenuModifierGroupEntity>(
      modifierGroupData.data?.data ?? defaultMenuModifierGroupGetState
    )
  const handleModifierGroupUpdate = (data: any) => {
    modifierGroupUpdate.mutate(extractMenuModifierGroupPatchPayload(data), {
      onSuccess: () => {
        handleNavigation()
      },
    })
  }

  const modifierGroupUpdate = useMenuModifierGroupUpdate(
    locationId!,
    menuId!,
    modifierGroupId!
  )

  const menuModifiers = useMenuModifiers(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifiers.fetchNextPage()
      }
    },
  })
  const menuModifiersData = menuModifiers?.data?.pages
    ?.map((page: any) => page.data)
    .flat()
  const catalogModifierGroup = useModifierGroupById(
    locationId!,
    modifierGroupState.catalogModifierGroupId
  )
  const catalogModifierGroupData = catalogModifierGroup.data?.data
  const modifierOptions = menuModifiersData?.filter((group: any) =>
    catalogModifierGroupData?.modifiers?.some(
      (modifier: any) => modifier.id === group.catalogModifierId
    )
  )

  const handleNavigation = () => {
    itemId && modifierGroupId
      ? isMenuV2
        ? navigate(
            `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/edit`
          )
        : navigate(
            `/app/locations/${locationId}/menu/${menuId}/${urlItem}/${itemId}`
          )
      : navigate(`/app/locations/${locationId}/menu/${menuId}/modifierGroups`)
  }
  // PageNavigation View constants
  const locationName = locations.data?.data?.name
  const menuName = menuData.data?.data?.name
  const itemName = itemData.data?.data?.name
  const modifierGroupName = modifierGroupData.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      label: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}/`
        : `/app/locations/${locationId}/menu/${menuId}/items`,
    },
    {
      label: itemName,
      path: isMenuV2
        ? `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/edit`
        : `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}`,
    },
    { label: modifierGroupName, path: `` },
  ]
  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />

      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <ModifierGroupEditForm
            modifierGroupState={modifierGroupState}
            modifierOptions={modifierOptions}
            handleModifierGroupUpdate={handleModifierGroupUpdate}
            handleNavigation={handleNavigation}
            showModifierEdit={true}
          />
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
