import { FC, useEffect, useState } from 'react'
import {
  useUserApplicationAudits,
  useUserApplicationById,
} from '../../../hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { DeveloperAuditFilter } from './DeveloperAppFilterDrawer'
import { useQueryString } from '../../../hooks/useQueryString'
import { AuditsTable } from '../../../components/AuditsTable'

/**
 *
 * @returns
 */
export const DeveloperAppAudits: FC = () => {
  const { clientId } = useParams<{ clientId: string }>()
  const [aggs, setAggs] = useState<any>([])

  const [queryParams, setQuery] = useQueryString(window.location, useNavigate())

  const audits = useUserApplicationAudits(clientId, {}, { ...queryParams })
  useEffect(() => {
    if (audits.status === 'success') {
      setAggs(
        audits.data?.pages
          ?.map((page) => page?.data?.aggs)
          .filter((item: any) => item !== undefined)
          .flat()
      )
    }
  }, [audits.status, audits.data?.pages])

  /**
   * Render the audits table
   */
  return (
    <>
      <Box
        sx={{
          pt: 2,
          pb: 2,
        }}
      >
        <DeveloperAuditFilter
          onApply={(data: any) => setQuery(data)}
          onReset={() => setQuery({})}
          queryParams={queryParams}
          clientId={clientId!}
          aggs={aggs}
        />
      </Box>

      <AuditsTable audits={audits} />
    </>
  )
}
