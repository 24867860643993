import { Box, Grid, Typography, Button } from '@mui/material'
import { CommonTextField } from '../CommonTextField'
import { useIntl } from 'react-intl'
import { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MenuModifierPatchEntity } from '../../types'
import {
  validateMinMaxValues,
  validationCheckUpdateforModifier,
} from '../../pages/Locations/CardSelect/Menu/utils'
import { getCurrencySymbol } from '../../pages/util'
import { CommonPriceInputField } from '../CommonPriceField'
import { CollapsibleSection } from '../CollapsibleSection'
import { ShowOnlineView } from '../ShowOnlineSwitch'
import { MenuAdvancedOptions } from './MenuAdvancedOptions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const ModifierEditForm = (props: any) => {
  const { modifierState, handleModifierUpdate, handleNavigation } = props
  const currency = getCurrencySymbol()
  const { modifierId } = useParams()
  const intl = useIntl()
  const [valid, setValid] = useState(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [updatedState, setUpdatedState] = useState<MenuModifierPatchEntity>({})
  function validationCheckUpdate() {
    const validationResults = validationCheckUpdateforModifier(updatedState)
    // Check if all the validations pass
    const isValid = Object.values(validationResults).every((value) => value)
    setValid(isValid)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState(newObject)
    validationCheckUpdate()
  }
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }

  const handlePriceChange = (data: any) => {
    updatedState.price = data
    validateAndSet(updatedState)
  }
  const handleInStorePriceChange = (data: any) => {
    updatedState.inStorePrice = data
    validateAndSet(updatedState)
  }
  const handleAdvancedOptionsShow = () => {
    setShowAdvancedOptions(!showAdvancedOptions)
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }
  const handleShowOnlineChange = (event: any) => {
    updatedState.showOnline = event.target.checked
    validateAndSet(updatedState)
  }
  const handleMinimumChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      updatedState.minPermitted = inputValue !== '' ? parseInt(inputValue) : 0
      updatedState.maxPermitted =
        updatedState.maxPermitted ?? modifierState?.maxPermitted
      validateAndSet(updatedState)
      const minMaxValidation = validateMinMaxValues(
        parseInt(inputValue),
        updatedState.maxPermitted ?? modifierState?.maxPermitted
      )
      if (!minMaxValidation) {
        setErrorMessage('Minimum cannot be greater than maximum')
      } else {
        setErrorMessage('')
      }
    }
  }
  const handleMaximumChange = (event: any) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      updatedState.maxPermitted = inputValue !== '' ? parseInt(inputValue) : 0
      updatedState.minPermitted =
        updatedState.minPermitted ?? modifierState?.minPermitted
      validateAndSet(updatedState)
      const minMaxValidation = validateMinMaxValues(
        updatedState.minPermitted ?? modifierState?.minPermitted,
        parseInt(inputValue)
      )
      if (!minMaxValidation) {
        setErrorMessage('Minimum cannot be greater than maximum')
      } else {
        setErrorMessage('')
      }
    }
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="h1">
          {modifierId
            ? `${intl.formatMessage({ id: 'action_update' })}`
            : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
          {intl.formatMessage({ id: 'label_modifier' })}
        </Typography>
        <Box>
          <Button color="inherit" onClick={() => handleNavigation()}>
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleModifierUpdate(updatedState)}
            disabled={!valid}
            color="success"
            sx={{ color: 'white', ml: 2 }}
          >
            {modifierId
              ? `${intl.formatMessage({ id: 'action_update' })}`
              : `${intl.formatMessage({ id: 'action_create' })}`}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12}>
            <ShowOnlineView
              item={modifierState}
              label={intl.formatMessage({ id: 'label_show_online' })}
              handleShowOnlineChange={(e: any) => handleShowOnlineChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={updatedState?.name ?? modifierState?.name}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
              currency={currency}
              value={updatedState?.price! ?? modifierState.price!}
              onChange={handlePriceChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({
                id: 'label_inStorePrice',
              })}`}
              currency={currency}
              value={updatedState?.inStorePrice! ?? modifierState.inStorePrice!}
              onChange={handleInStorePriceChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({
                id: 'label_min_required',
              })}
              onChange={handleMinimumChange}
              value={
                updatedState?.minPermitted ?? (modifierState.minPermitted || 0)
              }
              error={errorMessage !== '' ? true : false}
              helperText={errorMessage ? errorMessage : ' '}
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_max_permitted' })}
              onChange={handleMaximumChange}
              value={
                updatedState?.maxPermitted ?? (modifierState.maxPermitted || 0)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({ id: 'label_description' })}
              onChange={handleDescriptionChange}
              value={
                updatedState?.description ?? (modifierState.description || '')
              }
              rows={4}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              onClick={(e) => {
                e.stopPropagation()
                handleAdvancedOptionsShow()
              }}
              sx={{
                color: '#1976d2',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
              }}
            >
              <Typography
                variant="body1"
                component="a"
                sx={{ color: 'inherit' }}
              >
                {intl.formatMessage({ id: 'label_advanced_options' })}
              </Typography>

              <KeyboardArrowDownIcon
                style={{
                  fontSize: '24px',
                  transform: showAdvancedOptions ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.5s ease-in',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ pt: '0 !important' }}>
            <CollapsibleSection
              menuItemState={modifierState}
              show={showAdvancedOptions}
              children={
                <MenuAdvancedOptions
                  menuItemState={modifierState}
                  updatedState={updatedState}
                  validateAndSet={validateAndSet}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
