import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { GetAccountByIdResponse, ListAccountsResponse } from '../types'
import { toast } from 'react-toastify'

export interface UseResellerByIdOptions
  extends Omit<
    UseQueryOptions<GetAccountByIdResponse, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}

export interface UseAccountByIdProps
  extends Omit<
    UseQueryOptions<any, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}

/**
 * useResellerById
 */
export const useResellerAccounts = (props: any, options?: any) => {
  const token = useAccessToken()
  const { resellerId, filters } = props
  const baseKey = ['v1', 'resellers', resellerId, 'accounts', props?.filters]

  if (filters && filters.query) {
    baseKey.push(filters.query)
  }

  return useInfiniteQuery<ListAccountsResponse>(
    baseKey,
    ({ signal, pageParam }) =>
      api
        .get(`/v1/resellers/${resellerId}/accounts`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
          params: {
            limit: 20,
            nextPageKey: pageParam,
            ...(filters?.query && { query: filters?.query }),
          },
        })
        .then<ListAccountsResponse>(({ data }) => data),
    {
      staleTime: 3600 * 60,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      enabled: !!token?.access_token && !!resellerId,
      ...options,
    }
  )
}

export const useResellerAccountsById = (
  resellerId: string,
  accountId: string,
  options?: UseResellerByIdOptions
) => {
  const token = useAccessToken()

  return useQuery<any, any, GetAccountByIdResponse, string[]>(
    ['v1', 'resellers', resellerId, 'accounts', accountId],
    ({ signal }) =>
      api
        .get(`/v1/resellers/${resellerId}/accounts/${accountId}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!resellerId,
      ...options,
    }
  )
}

export const useResellerAccountCreate = (resellerId: string, options?: any) => {
  const token = useAccessToken()

  return useMutation(
    (account: any) =>
      api
        .post(`/v1/resellers/${resellerId}/accounts/`, account, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        toast.success('Account Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
      ...options,
    }
  )
}

export const useResellerAccountUpdate = (
  resellerId: string,
  accountId: string
) => {
  const queryClient = useQueryClient()
  const token = useAccessToken()
  return useMutation(
    (account: any) =>
      api
        .patch(`/v1/resellers/${resellerId}/accounts/${accountId}`, account, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['v1', 'resellers', resellerId, 'accounts'],
          exact: true,
        })
        toast.success('Account Updated')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
