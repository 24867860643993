/**
 * @fileoverview FormattedTimeZone component
 */
import { FC, useMemo } from 'react'
import { timezones } from '../../timezones'

/**
 * FormattedTimeZoneProps
 * @typedef {Object} FormattedTimeZoneProps
 * @property {string} value - The value to be formatted
 */
export interface FormattedTimeZoneProps {
  value?: string
}

/**
 * FormattedTimeZone component
 * @param {FormattedTimeZoneProps} props - The props
 * @return {JSX.Element} The FormattedTimeZone component
 */
export const FormattedTimeZone: FC<FormattedTimeZoneProps> = (props) => {
  const value = useMemo(() => {
    return timezones.find((timezone) => timezone.tzCode === props.value)
  }, [props.value])

  return <>{value ? value.label : props.value}</>
}
