import { useState, FC } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ClickableIcon } from '../ClickableIcon'

interface PasswordInputFieldProps {
  handleChangePassword: (event: any) => void
  isPasswordValid: boolean
  label: string
  sx?: any
  onKeyDown?: any
  autoFocus?: boolean
  helperText: string
  value?: string
}

export const PasswordInputField: FC<PasswordInputFieldProps> = (props) => {
  const {
    handleChangePassword,
    isPasswordValid,
    label,
    sx,
    onKeyDown,
    autoFocus,
    helperText,
    value,
  } = props
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <TextField
      id={`${label}-id`}
      sx={{ width: '100%', ...sx }}
      type={showPassword ? 'text' : 'password'}
      label={label}
      value={value}
      onChange={handleChangePassword}
      error={!isPasswordValid}
      autoFocus={autoFocus}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onKeyDown()
        }
      }}
      helperText={!isPasswordValid ? helperText : ' '}
      InputProps={{
        autoComplete: 'new-password',
        endAdornment: (
          <InputAdornment position="end">
            <ClickableIcon
              aria-label="toggle password visibility"
              handleNavigate={handleClickShowPassword}
            >
              {showPassword ? (
                <VisibilityOff fontSize="small" color="secondary" />
              ) : (
                <Visibility fontSize="small" color="secondary" />
              )}
            </ClickableIcon>
          </InputAdornment>
        ),
      }}
      onPaste={(e) => e.preventDefault()}
    />
  )
}
