import { FC } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { GenericTable } from '../../../components'
import { FormattedRelativeTime } from '../../../components/FormattedRelativeTime'

export interface DeliveriesTableProps {
  deliveries: UseInfiniteQueryResult<any, Error>
}

export const DeliveriesTable: FC<DeliveriesTableProps> = (props) => {
  return (
    <>
      <GenericTable
        query={props.deliveries}
        columns={[
          {
            id: 'status',
            label: 'Status',
            component: (delivery) => (
              <DelivertStatusLabel status={delivery.status} />
            ),
          },
          { id: 'pickup', label: 'Pickup', component: () => <>-</> },
          { id: 'destination', label: 'Destination', component: () => <>-</> },
          { id: 'deliveryStatus', label: 'Driver Status' },
          {
            id: 'merchantETA',
            label: 'Merchant ETA',
          },
          {
            id: 'customerETA',
            label: 'Customer ETA',
          },
          {
            id: 'createdAt',
            label: 'Created At',
            component: (delivery) => (
              <FormattedRelativeTime value={delivery.createdAt} />
            ),
          },
        ]}
        enableDelete={false}
        noDataMessage="No deliveries found"
        tableType="submit"
      />
    </>
  )
}

export interface DelivertStatusLabelProps {
  status: string
}
export const DelivertStatusLabel: FC<DelivertStatusLabelProps> = (props) => {
  return <>{props.status}</>
}
