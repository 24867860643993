import { Button } from '@mui/material'
import { FC } from 'react'

type OrderStatusValue =
  | 'PLACED'
  | 'ACCEPTED'
  | 'PREPARING'
  | 'READY'
  | 'FULFILLED'
  | 'HIDDEN'
  | 'CANCELLED'
  | 'DELETED'
  | 'COMPLETED'
  | 'REJECTED'
interface OrderStatusProps {
  value: OrderStatusValue
}
const getStatusColor = (status: OrderStatusValue) => {
  const colors: Record<OrderStatusValue, string> = {
    PLACED: '#198EBC',
    ACCEPTED: '#009A90',
    PREPARING: '#FEBE1B',
    READY: '#EF901C',
    FULFILLED: '#745CA8',
    HIDDEN: '#CCCCCC',
    CANCELLED: '#D82927',
    DELETED: '#2B0E3A',
    COMPLETED: '#5bae75',
    REJECTED: '#D82927',
  }
  return colors[status] || '#111111'
}

const OrderStatusButton: FC<OrderStatusProps> = ({ value }) => {
  const getcolor = getStatusColor(value)

  const buttonStyle = {
    backgroundColor: `${getcolor} !important`,
    borderColor: `${getcolor}33 !important`,
    '&:hover': {
      backgroundColor: `${getcolor}99 !important`,
      borderColor: `${getcolor} !important`,
    },
  }

  return (
    <Button sx={buttonStyle} variant="contained">
      {value}
    </Button>
  )
}

export default OrderStatusButton
