export class RegisterModel {
  firstName: string | null = null
  lastName: string | null = null
  email: string | null = null
  password: string | null = null
  language: string | null = null
  phone: string | null = null

  constructor(data?: any) {
    if (data) {
      this.deserilize(data)
    }
  }
  private deserilize(data: any) {
    const keys = Object.keys(data)
    for (const key of keys) {
      if (data.hasOwnProperty(key)) (this as any)[key] = data[key]
    }
  }
}
