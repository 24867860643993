import { useMutation } from 'react-query'
import { api } from '../services/api'
import { toast } from 'react-toastify'
import { RegisterModel } from '../model/RegisterModel'
import axios from 'axios'

export const useRegisterMutation = () => {
  return useMutation(
    (register: RegisterModel) =>
      api.post('/auth/register', register).then(({ data }) => data),
    {
      onError: (error: any) => {
        if (axios.isAxiosError(error)) {
          toast.error(JSON.stringify(error?.response?.data.error))
        } else toast.error(JSON.stringify(error.message))
      },
    }
  )
}
