import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import {
  ErrorResponse,
  GetCatalogCategoryByIdResponse,
  ListCatalogCategoryResponse,
  CatalogCategoryCreateEntity,
  CatalogCategoryPatchEntity,
} from '../types'
import { toast } from 'react-toastify'

/**
 *
 */
export const useCatalogCategories = (locationId: string) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()
  const queryClient = useQueryClient()

  return useQuery<ListCatalogCategoryResponse, ErrorResponse>(
    ['accounts', accountId, 'locations', locationId, 'categories'],
    () =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/categories`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId && !!locationId,
      onSuccess: (data) => {
        queryClient.setQueryData(
          ['accounts', accountId, 'locations', locationId, 'categories'],
          data
        )
      },
    }
  )
}

export interface UseCategoryByIdProps<
  TData = GetCatalogCategoryByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useCatalogCategory = (locationId: string, options?: any) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()
  // const queryClient = useQueryClient()

  return useInfiniteQuery<ListCatalogCategoryResponse>(
    ['accounts', accountId, 'locations', locationId, 'categories'],
    ({ pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/categories`,
          {
            params: {
              limit: 100,
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId,
      ...options,
    }
  )
}

export const useCatalogCategoryCreate = (locationId: string) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetCatalogCategoryByIdResponse,
    ErrorResponse,
    CatalogCategoryCreateEntity
  >(
    (category) =>
      api
        .post<GetCatalogCategoryByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/categories`,
          category,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'categories',
          ],
          exact: true,
        })
        toast.success('Category Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useCatalogCategoryDelete = (locationId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    (categoryId: string) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/categories/${categoryId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'categories',
        ])
        toast.success('Category Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useCatalogCategoryById = (
  locationId?: string,
  categoryId?: string,
  props?: UseCategoryByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useQuery<GetCatalogCategoryByIdResponse, ErrorResponse>(
    ['accounts', accountId, 'locations', locationId, 'categories', categoryId],
    ({ signal }) =>
      api
        .get<GetCatalogCategoryByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/categories/${categoryId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled:
        !!token?.access_token && !!accountId && !!locationId && !!categoryId,
      ...props,
    }
  )
}

export const useCatalogCategoryUpdate = (
  locationId: string,
  categoryId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetCatalogCategoryByIdResponse,
    ErrorResponse,
    CatalogCategoryPatchEntity
  >(
    (category) =>
      api
        .patch<GetCatalogCategoryByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/categories/${categoryId}`,
          category,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'categories',
          ],
          exact: true,
        })
        toast.success('Category Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
