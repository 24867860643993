import { Paper } from '@mui/material'
import { useParams } from 'react-router-dom'
import { OrderAuditLogs } from '../../components'
import { useOrderAudit, useOrderByIdNew } from '../../hooks'

// v1
import { OrderView } from '../../components/OrderView'

export function OrderDetails() {
  const { orderId } = useParams()
  /**
   * Order Loader
   */
  const order = useOrderByIdNew(
    { orderId: orderId! },
    { refetchInterval: 10000, keepPreviousData: true }
  )

  /**
   * Audit Logs Laoder
   */
  const logs = useOrderAudit(
    { orderId: orderId! },
    { refetchInterval: 10000, keepPreviousData: true, enabled: order.isSuccess }
  )

  return (
    <>
      <OrderView order={order} />
      <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
        <OrderAuditLogs logs={logs} order={order} />
      </Paper>
    </>
  )
}
