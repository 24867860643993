import {
  LogoutOutlined,
  PersonRounded,
  Settings,
  DeveloperMode,
} from '@mui/icons-material'
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material'
import { ArticleOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { useAuthStore } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { useProfile } from '../../hooks/useProfile'

/**
 *
 */
export const UserDropdown = () => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const isOpen = anchor !== null
  const [selectedItem, setSelectedItem] = useState('')
  const navigate = useNavigate()
  const profile = useProfile()
  const logout = useAuthStore((state) => state.logout)
  const handleLogout = () => {
    logout()
    navigate('/')
  }
  const styles = {
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 16px',
    },
  }

  return (
    <>
      <IconButton onClick={(event) => setAnchor(event.currentTarget)}>
        <Avatar variant="rounded">
          {profile.data?.data?.firstName?.charAt(0)?.toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={() => setAnchor(null)}
        onClick={() => setAnchor(null)}
        PaperProps={{ sx: { overflow: 'visible', mt: 1 } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem
          sx={styles.menuItem}
          selected={selectedItem === 'profile'}
          onClick={() => {
            navigate('/app/profile/me')
            setSelectedItem('profile')
          }}
        >
          <ListItemIcon>
            <PersonRounded />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <MenuItem
          sx={styles.menuItem}
          selected={selectedItem === 'developer'}
          onClick={() => {
            navigate('/app/developer')
            setSelectedItem('developer')
          }}
        >
          <ListItemIcon>
            <DeveloperMode />
          </ListItemIcon>
          <ListItemText primary="Developer" />
        </MenuItem>
        <MenuItem
          sx={styles.menuItem}
          onClick={() =>
            window.open(`${API_ENDPOINT}/docs/index.html`, '_blank')
          }
        >
          <ListItemIcon>
            <ArticleOutlined />
          </ListItemIcon>
          <ListItemText primary="API Documentation" />
        </MenuItem>
        {accountId && (
          <MenuItem
            sx={styles.menuItem}
            selected={selectedItem === 'accounts'}
            onClick={() => {
              navigate('/app/accounts')
              setSelectedItem('accounts')
            }}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>
        )}
        <MenuItem style={styles.menuItem} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined sx={{ marginRight: 2 }} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  )
}
