import {
  Snackbar,
  SnackbarContentProps,
  Button,
  Typography,
} from '@mui/material'

import { Fragment } from 'react'
import { UndoSnackbarProps } from '../../pages/types'

export const UndoSnackbar = (props: UndoSnackbarProps) => {
  const { showUndoSnackbar, onUndo, deletedProduct, performDeletion } = props

  const undoDuration = 3000
  const action = (
    <Fragment>
      <Button
        variant="text"
        color="info"
        size="medium"
        onClick={onUndo}
        style={{ textTransform: 'none' }}
      >
        Undo
      </Button>
    </Fragment>
  )
  return (
    <Snackbar
      open={showUndoSnackbar}
      autoHideDuration={undoDuration}
      onClose={(event, reason) => {
        performDeletion(reason)
      }}
      message={
        <Typography variant="body2" sx={{ color: 'black' }}>
          Deleted{' '}
          <span style={{ fontWeight: 'bold' }}>{deletedProduct?.name}</span>
        </Typography>
      }
      action={action}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      ContentProps={{
        sx: {
          backgroundColor: 'white',
        } as SnackbarContentProps['sx'],
      }}
    />
  )
}
