import { createContext, useContext, FC } from 'react'
import { IntlProvider as BaseIntlProvider } from 'react-intl'
import { useProfile } from '../../hooks'
import { useQuery } from 'react-query'

/**
 * Initialise a new context
 */
const LocaleContext = createContext({})

/**
 * Hook to access the locale context
 * @returns
 */
export const useLocale = () => useContext(LocaleContext)

/**
 *
 */
export const useIntlMessages = (locale: string) => {
  return useQuery(['.intl', 'messages', locale], () =>
    import(`../../messages/${locale}.json`).then((res) => res.default)
  )
}

/**
 *
 */
export interface IntlProviderProps {
  defaultLocale: string
  availableLanguages: string[]
  defaultMessages: Record<string, string>
}

/**
 * Create a provider to wrap the application
 * @param param0 Props
 * @returns IntlProvider
 */
export const IntlProvider: FC<IntlProviderProps> = (props) => {
  const me = useProfile({})
  const locale = me.data?.data.language || props.defaultLocale
  const messages = useIntlMessages(locale)

  const contextValue = {
    locale: me.data?.language || props.defaultLocale,
    availableLanguages: ['en-GB', 'en-US'],
  }

  return (
    <LocaleContext.Provider value={contextValue}>
      <BaseIntlProvider
        locale={locale}
        messages={{ ...props.defaultMessages, ...messages.data }}
      >
        {props.children}
      </BaseIntlProvider>
    </LocaleContext.Provider>
  )
}
