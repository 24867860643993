import { FC, useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
// @ts-ignore
import noImage from '../../assets/noimage.webp'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { getCurrency, shortenText } from '../../pages/util'
import { FormattedNumber } from 'react-intl'
import {
  AppWarningDialog,
  AppEditDialog,
  StyledIconButton,
} from '../../components'

// @ts-ignore
import noImageSmall from '../../assets/no_image_small.jpeg'

interface CardWithImageProps {
  data: any
  isEditable: boolean
  isRedirect: boolean
  multiSelectItems: boolean
  onDelete: (value: any) => void
  index: any
  isItem?: boolean
  selectedItem?: any
  onClickCard?: any
  indexState?: any
}

export const CardWithImage: FC<CardWithImageProps> = (props) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const currency = getCurrency()
  const {
    data,
    isEditable,
    selectedItem,
    onClickCard,
    isRedirect,
    multiSelectItems,
    index,
    indexState,
    onDelete,
  } = props

  const [showDelete, setShowDelete] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [myInstance, setMyInstance] = useState(data)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    if ((!isEditable && multiSelectItems) || data) {
      const itemIsSelected = selectedItem?.some(
        (item: any) => item.id === data.id
      )
      setIsSelected(itemIsSelected)
      setMyInstance(data)
    }
  }, [selectedItem, data])

  const handleItemClick = () => {
    if (isEditable) {
      handleEditClick()
    } else {
      if (multiSelectItems) {
        setIsSelected(!isSelected)
      }
      onClickCard(data, index, !isSelected)
    }
  }

  const handleEditClick = () => {
    !isRedirect ? setDrawerOpen(true) : navigate(onClickCard)
  }

  const handleDeleteClick = (e: any) => {
    e.stopPropagation()
    setShowConfirmationDialog(true)
    setMyInstance(data)
  }
  const style = {
    iconStyle: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      display: showDelete ? 'flex' : 'none',
    },
    editIconStyle: { left: '50px' },
  }
  const StyledCardContent = styled(CardContent)(() => ({
    padding: 0,
    display: 'flex',
    '&:last-child': {
      paddingBottom: 0,
    },
  }))
  useEffect(() => {
    if (window.innerWidth <= theme.breakpoints.values.md) {
      setShowDelete(true)
    }
  }, [])
  const handleMouseEnter = () => {
    setShowDelete(true)
  }
  const handleMouseLeave = () => {
    if (window.innerWidth <= theme.breakpoints.values.md) {
      setShowDelete(true)
    } else {
      setShowDelete(false)
    }
  }
  return (
    <>
      <Box m={1}>
        <Card
          sx={{
            borderRadius: 2,
            position: 'relative',
            display: 'flex',
            cursor: 'pointer',
            backgroundColor:
              !isEditable &&
              ((isSelected && multiSelectItems) ||
                (!multiSelectItems && index === indexState))
                ? 'primary.light'
                : 'transparent',
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleItemClick}
        >
          <StyledCardContent>
            <CardMedia
              sx={{ height: 140, width: 140 }}
              image={myInstance?.imageUrl ? myInstance?.imageUrl : noImageSmall}
              title={`${myInstance?.name} product`}
            />
            <Box sx={{ p: 1 }}>
              <Typography gutterBottom variant="h6" component="div">
                {shortenText(myInstance.name, 25)}
              </Typography>
              {myInstance?.price && (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  component="div"
                >
                  <FormattedNumber
                    value={myInstance?.price / 100}
                    style="currency"
                    currency={currency}
                    minimumFractionDigits={2}
                  />
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary">
                {myInstance?.description
                  ? window.innerWidth <= theme.breakpoints.values.sm
                    ? shortenText(myInstance.description, 25)
                    : myInstance.description
                  : null}
              </Typography>
              {myInstance?.showOnline == false && (
                <Typography variant="body2" color="red">
                  Offline
                </Typography>
              )}
            </Box>
          </StyledCardContent>
          {isEditable && (
            <>
              <StyledIconButton
                sx={{ ...style.iconStyle }}
                onClick={handleDeleteClick}
              >
                <DeleteOutline />
              </StyledIconButton>
              <StyledIconButton
                sx={{ ...style.iconStyle, ...style.editIconStyle }}
                onClick={handleEditClick}
              >
                <EditOutlined />
              </StyledIconButton>
            </>
          )}
        </Card>
      </Box>
      <Dialog
        open={drawerOpen}
        sx={{ borderRadius: 2 }}
        onClose={() => setDrawerOpen(false)}
      >
        <AppEditDialog
          data={myInstance}
          title="item"
          onConfirm={(value) => {
            setMyInstance(value), onClickCard(value, index, isSelected)
          }}
          onCancel={() => setDrawerOpen(false)}
        />
      </Dialog>
      <AppWarningDialog
        name={myInstance.name}
        source="product"
        open={showConfirmationDialog}
        onConfirm={() => onDelete(myInstance)}
        onCancel={() => setShowConfirmationDialog(false)}
      />
    </>
  )
}
