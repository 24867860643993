import {
  DialogProps as MuiDialogProps,
  Dialog as MuiDialog,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'

export interface DialogProps extends MuiDialogProps {}

/**
 * Dialog component
 */
export const Dialog: FC<DialogProps> = styled(MuiDialog)(({ theme }) => ({}))
