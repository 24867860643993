export class LoginModel {
  username: string
  password: string
  grant_type: string = 'password'
  client_id: string = 'ee17da2c-06ef-4f48-8c11-96d3350ea177'
  scope: string =
    'orders.read orders.write locations.read locations.write accounts.read accounts.write user.read user.write catalogs.write catalogs.read menus.read menus.write'

  constructor(data?: any) {
    if (data) {
      this.deserilize(data)
    }
  }
  private deserilize(data: any) {
    const keys = Object.keys(data)
    for (const key of keys) {
      if (data.hasOwnProperty(key)) (this as any)[key] = data[key]
    }
  }
}
export class LoginOtpModel {
  grant_type: string = 'otp'
  phone: string
  code?: string
  client_id: string = 'ee17da2c-06ef-4f48-8c11-96d3350ea177'
  scope: string =
    'orders.read orders.write locations.read locations.write accounts.read accounts.write user.read user.write catalogs.write catalogs.read menus.read menus.write'

  constructor(data?: any) {
    if (data) {
      this.deserilize(data)
    }
  }
  private deserilize(data: any) {
    const keys = Object.keys(data)
    for (const key of keys) {
      if (data.hasOwnProperty(key)) (this as any)[key] = data[key]
    }
  }
}
