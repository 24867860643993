import { useMutation } from 'react-query'
import { useAuthStore } from './useAuthStore'
import { api } from '../services/api'
import { ErrorResponse, OrderByIdResponse, OrderCreateEntity } from '../types'
import { useAccessToken } from './useAccessToken'

/**
 * Creates an order for the given location.
 */
export const useOrderCreateMutation = () => {
  const token = useAccessToken()
  const accountId = useAuthStore((state) => state.accountId)

  /**
   *
   */
  return useMutation<
    OrderByIdResponse,
    ErrorResponse,
    { locationId: string; order: OrderCreateEntity }
  >({
    mutationFn: ({ locationId, order }) => {
      return api.post(
        `/v1/accounts/${accountId}/locations/${locationId}/orders`,
        order,
        {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        }
      )
    },
  })
}
