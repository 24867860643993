import { useMutation } from 'react-query'
import { useOrderCreateMutation } from './useOrderCreate'
import { ErrorResponse, OrderByIdResponse, OrderCreateEntity } from '../types'

/**
 *
 */
export const useCreateOrderSample = () => {
  const orderCreateMutation = useOrderCreateMutation()
  return useMutation<OrderByIdResponse, ErrorResponse, { locationId: string }>(
    (props) => {
      return orderCreateMutation.mutateAsync({
        locationId: props.locationId,
        order: randomOrderGenerator(),
      })
    }
  )
}

export const randomOrderGenerator = (): OrderCreateEntity => {
  return {
    charges: [],
    discounts: [],
    payments: [],
    tax: [], // should be called taxes
    items: [],
    fulfillmentType: 'COLLECTION',
    partnerId: 'string',
    placedOn: new Date().toISOString(),
    sourceName: 'FOODHUB',
    totalTax: 0,
    subTotal: 0,
    total: 0,
    customer: {
      firstName: 'John',
      lastName: 'Doe',
    },
  }
}
