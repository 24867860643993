import { FC } from 'react'
import { useIntl } from 'react-intl'
import { GenericCard } from '../../../components'
import { Chart } from 'react-chartjs-2'

export const TotalOrderValueByFulfillmentTypes: FC<{
  totalOrderValueByFulfillemtType: any
}> = (props) => {
  const { formatMessage } = useIntl()
  const title = formatMessage({ id: 'dashboard_ordersByFulfillmentType_title' })
  const subtitle = formatMessage({
    id: 'dashboard_ordersByFulfillmentType_subtitle',
  })
  const helpText = formatMessage({
    id: 'dashboard_ordersByFulfillmentType_helpText',
  })
  return (
    <GenericCard title={title} subtitle={subtitle} helpText={helpText}>
      <Chart
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
              align: 'start',
            },
          },
          scales: {
            // x: { type: 'timeseries', stacked: false },
            // y: { type: 'linear', ticks: { stepSize: 5 } },
          },
        }}
        data={{
          labels: ['Revenue'],
          datasets: props.totalOrderValueByFulfillemtType?.buckets.map(
            (bucket: any) => ({
              label: `${bucket.key}`,
              data: [bucket.doc_count],
            })
          ),
        }}
        height={80}
        type="bar"
      ></Chart>
    </GenericCard>
  )
}
