import { Button, ButtonGroup } from '@mui/material'
import { FC } from 'react'

/**
 * RangeSelectorProps
 */
export interface RangeSelectorProps {
  options: string[]
  selected: string
  onChange: (selected: string) => void
}

/**
 * Provides a range selector component that allows the user to select from a range of options
 * @param props the range selector props
 * @returns the range selector component
 */
export const RangeSelector: FC<RangeSelectorProps> = (props) => {
  return (
    <ButtonGroup size="small">
      {props.options.map((option) => (
        <Button
          key={option}
          onClick={() => props.onChange(option)}
          variant={props.selected === option ? 'contained' : 'outlined'}
          sx={{ borderRadius: 2 }}
          color="info"
        >
          {option}
        </Button>
      ))}
    </ButtonGroup>
  )
}
