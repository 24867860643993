import { useParams } from 'react-router-dom'
import { AuditsTable } from '../../../components/AuditsTable'
import { useResellerAudits } from '../../../hooks/useResellerAudits'

/**
 *
 * @returns
 */
export const ResellerAudits = () => {
  const { resellerId } = useParams()
  const audits = useResellerAudits({ resellerId: resellerId! })

  return <AuditsTable audits={audits} />
}
