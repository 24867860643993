import { useMutation, useQueryClient } from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { toast } from 'react-toastify'
import { LoginModel, LoginOtpModel } from '../model/LoginModel'
import axios from 'axios'

export const useLoginMutation = () => {
  const [setAuth] = useAuthStore((t) => [t.setAuth])
  const queryClient = useQueryClient()

  return useMutation(
    (login: LoginModel) =>
      api
        .post('/oauth2/token', login, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(({ data }) => data),
    {
      onSuccess: async (data) => {
        if (data?.access_token != null && data?.refresh_token != null) {
          setAuth({
            ...data,
            expires_at: new Date().getTime() / 1000 + data.expires_in,
          })
          queryClient.clear()
        }
      },
      onError: (error: any) => {
        if (axios.isAxiosError(error)) {
          toast.error(JSON.stringify(error?.response?.data.error))
        } else toast.error(JSON.stringify(error.message))
      },
    }
  )
}

export const useLoginOtpMutation = () => {
  const [setAuth] = useAuthStore((t) => [t.setAuth])
  const queryClient = useQueryClient()

  return useMutation(
    (login: LoginOtpModel) =>
      api
        .post('/oauth2/token', login, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(({ data }) => data),
    {
      onSuccess: async (data) => {
        if (data?.access_token != null && data?.refresh_token != null) {
          setAuth({
            ...data,
            expires_at: new Date().getTime() / 1000 + data.expires_in,
          })
          queryClient.clear()
        }
      },
      onError: (error: any) => {
        if (axios.isAxiosError(error)) {
          error.response?.status === 400
            ? toast.error('The entered value is incorrect. Please try again.')
            : toast.error(JSON.stringify(error?.response?.data.error))
        } else toast.error(JSON.stringify(error.message))
      },
    }
  )
}
