import { validatePhoneNumberLength } from 'libphonenumber-js'
import { LocationCreateEntity, LocationPatchEntity } from '../../types'

/** MENU */
export const defaultLocationState: LocationCreateEntity | LocationPatchEntity =
  {
    name: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    address: {
      address: '', // provide a default value for the 'address' property
      googlePlaceId: '',
      latitude: 0,
      longitude: 0,
    },
    businessHours: [],
    availability: {
      collection: [],
      delivery: [],
    },
    timezone: 'Europe/London',
    autoCloseOrders: false,
  }

export const extractLocationPatchPayload = (
  location: LocationCreateEntity | LocationPatchEntity
): LocationPatchEntity => {
  return {
    name: location.name,
    address: location.address,
    businessHours: location.businessHours,

    availability: {
      collection: location?.availability?.collection!,
      delivery: location?.availability?.delivery,
    },
    phoneNumber: location.phoneNumber,
    timezone: location.timezone,
    autoCloseOrders: location.autoCloseOrders,
    autoCloseOrdersAfter: location.autoCloseOrdersAfter,
    firstName: location.firstName?.trim(),
    lastName: location.lastName,
    email: location.email,
  }
}
export const extractLocationCreatePayload = (
  location: LocationCreateEntity | LocationPatchEntity
): LocationCreateEntity => {
  return {
    name: location.name!,
    address: location.address!,
    businessHours: location.businessHours!,
    availability: {
      collection: location?.availability?.collection!,
      delivery: location?.availability?.delivery!,
    },
    phoneNumber: location.phoneNumber!,
    timezone: location.timezone!,
    autoCloseOrders: location.autoCloseOrders!,
    autoCloseOrdersAfter: location.autoCloseOrdersAfter!,
    firstName: location.firstName?.trim(),
    lastName: location.lastName,
    email: location.email,
  }
}
export const extractResellerLocationUpdatePayload = (location: any): any => {
  return {
    name: location.name!,
    address: location.address!,
    businessHours: location.businessHours!,
    phoneNumber: location.phoneNumber!,
    timezone: location.timezone!,
    autoCloseOrders: location.autoCloseOrders!,
    autoCloseOrdersAfter: location.autoCloseOrdersAfter!,
    accountId: location.accountId!,
    firstName: location.firstName?.trim(),
    lastName: location.lastName,
    email: location.email,
  }
}
export const extractResellerLocationCreatePayload = (location: any): any => {
  return {
    name: location.name!,
    address: location.address!,
    businessHours: location.businessHours!,
    phoneNumber: location.phoneNumber!,
    timezone: location.timezone!,
    autoCloseOrders: location.autoCloseOrders!,
    autoCloseOrdersAfter: location.autoCloseOrdersAfter!,
    accountId: location.accountId!,
    firstName: location.firstName?.trim(),
    lastName: location.lastName,
    email: location.email,
  }
}

export const validateFirstName = (name: string) => {
  return name?.length ? name.length >= 3 : true
}

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return email ? emailRegex.test(email) : true
}

export const validateLocationEntity = (
  location: LocationCreateEntity | LocationPatchEntity
) => {
  return (
    location &&
    location.name?.trim() !== '' &&
    location.phoneNumber?.trim() !== '' &&
    location?.phoneNumber &&
    !validatePhoneNumberLength(location?.phoneNumber) &&
    location.address?.address?.trim() !== '' &&
    location.timezone?.trim() !== '' &&
    validateFirstName(location.firstName?.trim()!) &&
    validateEmail(location.email!)
  )
}
export const validateResellerLocationEntity = (location: any) => {
  return (
    location &&
    location.name?.trim() !== '' &&
    location.phoneNumber?.trim() !== '' &&
    location?.phoneNumber &&
    !validatePhoneNumberLength(location?.phoneNumber) &&
    location.address?.address?.trim() !== '' &&
    location.timezone?.trim() !== '' &&
    location.accountId !== ('' || null || undefined) &&
    validateFirstName(location.firstName?.trim()) &&
    validateEmail(location.email)
  )
}

export const generateTimePeriods = () => {
  const timePeriods = []

  for (let hour = 0; hour <= 24; hour++) {
    if (hour === 0) {
      timePeriods.push('Disable')
    } else {
      timePeriods.push(`${hour} (hours)`)
    }
    if (hour < 24) {
      timePeriods.push(`${hour} (hours) 30 (minutes)`)
    }
  }

  return timePeriods
}
export const extractTimeInMinutes = (timeString: any) => {
  // Extract numbers from the string using a regular expression
  const numbers = timeString.match(/\d+/g)

  // Convert extracted strings to integers
  const hours = parseInt(numbers[0], 10)
  const minutes = parseInt(numbers[1], 10)
  // Calculate the total time in minutes
  return hours * 60 + minutes
}
// Function to find the closest time period from the array
export const findClosestTimePeriod = (seconds: any, timePeriods: any) => {
  if (seconds === 0) return 'Disable'

  const hours = seconds / 3600
  const wholeHours = Math.floor(hours)
  const remainingMinutes = (hours - wholeHours) * 60

  if (remainingMinutes === 0) {
    return `${wholeHours} (hours)`
  } else if (remainingMinutes <= 30) {
    return `${wholeHours} (hours) 30 (minutes)`
  } else {
    return `${wholeHours + 1} (hours)`
  }
}
export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}
