import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Dialog, DialogProps } from '../../../components/Dialog'
import {
  useItemCreate,
  useMenuModifierCreate,
  useMenuModifierGroupCreate,
  useMenuModifierGroups,
  useMenuModifiers,
  useModifierGroups,
  useModifiers,
} from '../../../hooks'
import {
  Button,
  DialogContent,
  Grid,
  Typography,
  DialogActions,
  CardMedia,
  CardContent,
  Card,
  InputAdornment,
  Box,
  styled,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { MenuItemCreateEntity } from '../../../types'
import { toast } from 'react-toastify'
import { LoadingButton } from '../../../components'
import { rebuildMenuItemData, shortenText } from '../../util'
import { CommonTextField } from '../../../components'
import { SearchOutlined } from '@mui/icons-material'
// @ts-ignore
import noImageSmall from '../../../assets/no_image_small.jpeg'

/**
 * Props for the AddItemDialog component.
 */
export interface AddItemDialogProps extends DialogProps {
  locationId: string
  menuId: string
  categoryId: string
  products: any
  categories: any
}

/**
 * Dialog for editing an item in the menu.
 */
export const AddItemDialog: FC<AddItemDialogProps> = (props) => {
  const intl = useIntl()
  const {
    locationId,
    menuId,
    categoryId,
    products,
    categories,
    ...dialogProps
  } = props
  const addMenuItem = useItemCreate(locationId, menuId)
  const createMenuModifier = useMenuModifierCreate(locationId!, menuId!)
  const createMenuModifierGroup = useMenuModifierGroupCreate(
    locationId!,
    menuId!
  )
  const [productsData, setProductsData] = useState<any[] | undefined>(undefined)
  const [searchedVal, setSearchedVal] = useState<string>('')
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([])
  const handleProductSelect = (productId: string) => {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds(selectedProductIds.filter((id) => id !== productId))
    } else {
      setSelectedProductIds([...selectedProductIds, productId])
    }
  }
  // Fetch the catalog modifiers and modifier groups data
  const catalogModifierGroups = useModifierGroups(locationId, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogModifierGroups.fetchNextPage()
      }
    },
  })
  const catalogModifiers = useModifiers(locationId, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogModifiers.fetchNextPage()
      }
    },
  })
  const catalogModifierGroupsData = catalogModifierGroups?.data?.pages
    ?.map((value: any) => value.data)
    .flat()
  const catalogModifiersData = catalogModifiers?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Fetch the menu modifier groups
  const menuModifierGroups = useMenuModifierGroups(locationId, menuId, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifierGroups.fetchNextPage()
      }
    },
  })
  const menuModifierGroupsData = menuModifierGroups?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Fetch the menu Modifers
  const menuModifiers = useMenuModifiers(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifiers.fetchNextPage()
      }
    },
  })
  const menuModifiersData = menuModifiers?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Combine the catalog modifier groups and modifiers
  const combinedCatalogModifiers = catalogModifierGroupsData?.map(
    (catalogModifierGroup: any) => {
      // Find modifiers for the current catalogModifierGroup
      const modifiers = catalogModifiersData?.filter((modifier) =>
        catalogModifierGroup.modifiers.some(
          (catalogModifierId: any) => catalogModifierId.id === modifier.id
        )
      )
      return {
        ...catalogModifierGroup,
        modifiers: modifiers?.map(
          ({
            id,
            name,
            price,
            description,
            showOnline,
            imageUrl,
            position,
          }) => ({
            id,
            name,
            price,
            description,
            showOnline,
            imageUrl,
            position,
          })
        ),
      }
    }
  )
  useEffect(() => {
    const data = products?.data?.pages.map((value: any) => value.data).flat()
    setProductsData(data)
  }, [products.data])
  const [uploadModifierGroups, setUploadModifierGroups] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  /**  creating item manually from catalog 
  we will check the modifier group existed in the menu modifier group DB or not
  then we create the modifiers and modifier groups if they are not existed in the menu modifier group DB 
  */
  const handleCreateModifiers = async (modifier: any) => {
    try {
      const response = await createMenuModifier.mutateAsync({
        catalogModifierId: modifier.id,
        price: modifier.price,
        showOnline: modifier.showOnline || true,
        imageUrl: modifier.imageUrl,
        name: modifier.name,
        description: modifier.description,
        position: modifier.position,
      })
      return response.data // Return modifier ID
    } catch (error) {
      console.error('Error creating menu modifier:', error)
      throw error // Rethrow error to handle it outside
    }
  }

  // Function to create modifiers and modifier groups in the menu from catalog data
  const handleCreateModifierGroups = async (
    modifierGroupForSelectedProduct: any,
    catalogModifierGroupData: any,
    modifiersFound: any
  ) => {
    let createdModifierGroupIds: string[] = [] // Array to store created modifier group IDs
    try {
      // Exclude modifierGroupForSelectedProduct from catalogModifierGroupData
      const filteredCatalogModifierGroupData = catalogModifierGroupData.filter(
        (group: any) =>
          !modifierGroupForSelectedProduct.some(
            (selectedGroup: any) =>
              selectedGroup.catalogModifierGroupId === group.id
          )
      )
      for (const catalogModifierGroup of filteredCatalogModifierGroupData) {
        if (!catalogModifierGroup) continue // Skip if catalogModifierGroup is not found

        // Array to store promises returned by createMenuModifier.mutate
        const modifierPromises =
          catalogModifierGroup.modifiers?.map(async (modifier: any) => {
            if (modifiersFound.length) {
              const foundModifier = modifiersFound.find((group: any) =>
                group.find(
                  (mod: any) => mod?.catalogModifierId === modifier?.id
                )
              )
              if (!foundModifier) {
                // If no matching modifier is found, call handleCreateModifiers
                return handleCreateModifiers(modifier)
              } else {
                // Extract unique modifier ids from foundModifier array
                const modifierIds = foundModifier.map((mod: any) => mod.id)
                const uniqueModifierIds = Array.from(new Set(modifierIds))
                return uniqueModifierIds
              }
            } else {
              // If modifiersFound is empty, call handleCreateModifiers
              return handleCreateModifiers(modifier)
            }
          }) || []

        // Wait for all createMenuModifier.mutate calls to complete
        const resolvedModifierArray = await Promise.all(modifierPromises)

        // Flatten the array of arrays and remove duplicates
        const modifierIds = Array.from(
          new Set(
            resolvedModifierArray?.map((modifier: any) => modifier.id).flat()
          )
        )

        // Once all modifiers are created, proceed with createMenuModifierGroup.mutate
        const createdModifierGroup: any =
          await createMenuModifierGroup.mutateAsync({
            catalogModifierGroupId: catalogModifierGroup.id,
            name: catalogModifierGroup.name,
            description: catalogModifierGroup.description,
            maxPermitted: catalogModifierGroup.maxPermitted ?? 0,
            minPermitted: catalogModifierGroup.minPermitted ?? 0,
            position: catalogModifierGroup.position,
            modifiers: modifierIds,
          })
        createdModifierGroupIds.push(createdModifierGroup?.data) // Push the created modifier group ID
      }

      return createdModifierGroupIds // Return array of created modifier group IDs
    } catch (error) {
      console.log('Error in creating modifiers in menu:', error)
      throw error // Rethrow error to handle it outside
    }
  }

  useEffect(() => {
    const selectedProducts: any[] = []

    // Call the asynchronous function
    selectedProductIds.map((id) => {
      const product = productsData?.find((product: any) => product.id === id)
      selectedProducts.push(product)
    })
    const modifierGroupsFromSelectedProducts = selectedProducts
      .map((product) => product.modifierGroups)
      .flat()
      //remove duplicates from modifierGroupsFromSelectedProducts
      .filter((value, index, self) => self.indexOf(value) === index)
    setUploadModifierGroups(modifierGroupsFromSelectedProducts)
  }, [selectedProductIds])

  // Define an asynchronous function inside useEffect
  const fetchModifierGroupData = async () => {
    let modiferGroupSelected: any[] = []
    if (uploadModifierGroups?.length) {
      const modifierGroupForSelectedProduct: any =
        menuModifierGroupsData?.filter((menuModifierGroup) =>
          uploadModifierGroups.includes(
            menuModifierGroup.catalogModifierGroupId
          )
        )
      const catalogModifierGroupData = uploadModifierGroups.map(
        (modifierGroupId: any) =>
          combinedCatalogModifiers?.find(
            (catalogModifierGroup) =>
              catalogModifierGroup.id === modifierGroupId
          )
      )
      const modifiersFound = catalogModifierGroupData.map((group: any) =>
        group.modifiers.map((modifier: any) => {
          // Find the corresponding modifier in menuModifiersData based on id or catalogModifierId
          const foundModifier: any = menuModifiersData?.find(
            (menuModifier) => menuModifier.catalogModifierId === modifier.id
          )
          return foundModifier
        })
      )
      const isAnyModiferMissingInMenu = modifiersFound.some((group: any) =>
        group.some((mod: any) => !mod)
      )
      if (
        modifierGroupForSelectedProduct?.length !==
        catalogModifierGroupData?.length
      ) {
        try {
          // Wait for handleCreateModifierGroups to complete and get the result
          const res = await handleCreateModifierGroups(
            modifierGroupForSelectedProduct,
            catalogModifierGroupData,
            modifiersFound
          )
          modiferGroupSelected = [...res, ...modifierGroupForSelectedProduct]
        } catch (error) {
          console.error('Error while creating modifiers:', error)
        }
      } else {
        modiferGroupSelected = modifierGroupForSelectedProduct?.map(
          (modiferGroup: any) => modiferGroup
        )
      }
    }
    return modiferGroupSelected
  }
  // Save the item
  const save = async () => {
    var toastMessage = ''
    setIsLoading(true)
    const modifierGroupData = await fetchModifierGroupData()
    const itemsToAdd = selectedProductIds.map((productId) => {
      const product = productsData?.find((product) => product.id === productId)
      const menuItemData = rebuildMenuItemData(product)
      const modifierGroupSelected = modifierGroupData?.filter((group) =>
        product?.modifierGroups.includes(group.catalogModifierGroupId)
      )
      return {
        ...menuItemData,
        categories: [categoryId],
        showOnline: menuItemData?.showOnline ?? true,
        modifierGroups: modifierGroupSelected?.map((group) => group.id) || [],
      }
    })

    itemsToAdd.forEach((item) => {
      addMenuItem.mutate(item as MenuItemCreateEntity, {
        onSuccess: () => {
          setIsLoading(false)
          dialogProps.onClose?.({}, 'escapeKeyDown')
        },
      })
    })
    toastMessage =
      selectedProductIds.length > 1 ? 'Items Created' : 'Item Created'
    toast.success(toastMessage)
  }
  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.trim() !== '') {
      setSearchedVal(event?.target?.value)
    } else {
      setSearchedVal('')
    }
  }

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth={'md'}
      sx={{ p: '0 !important' }}
    >
      <DialogTitle sx={{ textAlign: 'center', mb: 1 }}>
        <Typography component="h5" variant="h5" sx={{ mb: 1 }}>
          {intl.formatMessage({ id: 'label_items' })}
        </Typography>
        <CommonTextField
          autoFocus
          sx={{ paddingLeft: 0 }}
          size="small"
          placeholder="Search for items..."
          onChange={handleChangeText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </DialogTitle>
      <DialogContent>
        {/* List of products */}
        <CatalogProducts
          products={productsData || []}
          selectedProductIds={selectedProductIds}
          onItemSelect={(productId) => handleProductSelect(productId)}
          searchedVal={searchedVal}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dialogProps.onClose?.({}, 'escapeKeyDown')}>
          {intl.formatMessage({ id: 'action_cancel' })}
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          disabled={selectedProductIds.length == 0}
          loading={isLoading}
          onClick={() => save()}
          size="small"
          children={`${intl.formatMessage({ id: 'action_add_items' })}
          ${
            selectedProductIds?.length > 0
              ? ` (${selectedProductIds.length})`
              : ''
          }`}
        />
      </DialogActions>
    </Dialog>
  )
}

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  display: 'flex',
  '&:last-child': {
    paddingBottom: 0,
  },
}))
export const CatalogProducts: FC<{
  products: any[]
  selectedProductIds: string[]
  onItemSelect: (productId: string) => void
  searchedVal: string
}> = (props) => {
  const { products, selectedProductIds, onItemSelect, searchedVal } = props

  return (
    <>
      <Grid container spacing={1} sx={{ padding: 1 }}>
        {products
          ?.filter(
            (row) =>
              !searchedVal.length ||
              (row as any)?.name
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchedVal.toString().toLowerCase())
          )
          ?.map((product) => (
            <Grid item xs={12} sm={6} md={3} key={product.id}>
              <Card
                onClick={() => onItemSelect(product.id)}
                sx={{
                  cursor: 'pointer',
                  height: '70px',
                  backgroundColor: selectedProductIds.includes(product.id)
                    ? 'primary.light'
                    : 'transparent',
                  borderRadius: 2,
                  padding: 1,
                }}
              >
                <StyledCardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 0,
                    alignItems: 'center',
                  }}
                >
                  <CardMedia
                    sx={{ height: 40, width: 40, borderRadius: 1 }}
                    image={product?.imageUrl ? product?.imageUrl : noImageSmall}
                    title={`${product?.name} product`}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 1,
                    }}
                  >
                    <Tooltip
                      title={product.name.length > 12 ? product.name : ''}
                    >
                      <Typography gutterBottom variant="body1" component="div">
                        {shortenText(product.name, 12)}
                      </Typography>
                    </Tooltip>
                    {product?.price ? (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                      >
                        {product?.price / 100}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                      >
                        {0}
                      </Typography>
                    )}
                  </Box>
                </StyledCardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  )
}
