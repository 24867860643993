import {
  Box,
  Button,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useCatalogCategory } from '../../../../../hooks'
import {
  StyledButtonContainerForTable,
  StyledTablePaperWrapper,
  NoRecordFound,
  AppLoader,
} from '../../../../../components'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { defaultImageUrl, shortenText } from '../../../../util'
import { FormattedDateTime } from '../../../../../components/FormattedDateTime'

export function CatalogCategory() {
  const intl = useIntl()
  const { locationId } = useParams()
  const category = useCatalogCategory(locationId!)
  const recordsData = category.data?.pages?.map((value) => value.data).flat()
  const records = recordsData?.sort((a: any, b: any) => a.position - b.position)
  const tableImageStyles = { width: 50, height: 50, borderRadius: 8 }
  useEffect(() => {
    category.hasNextPage && void category.fetchNextPage()
  }, [category.data])
  if (category.isLoading) {
    return <AppLoader />
  }

  if (category.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }
  return (
    <>
      {category.isSuccess && !recordsData?.length ? (
        <NoRecordFound title="Categories" />
      ) : (
        <StyledTablePaperWrapper sx={{ mt: 1 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_image' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_show_online' })}
                  </TableCell>
                  <TableCell sx={{ width: '300px' }}>
                    {intl.formatMessage({ id: 'label_created_at' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map((categoryData, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'default !important' }}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <img
                            style={{ ...tableImageStyles }}
                            src={
                              categoryData.imageUrl
                                ? categoryData.imageUrl
                                : defaultImageUrl
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        {categoryData.name?.length > 25 ? (
                          <Tooltip title={categoryData.name}>
                            <Typography variant="body2">
                              {shortenText(categoryData.name, 25)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          categoryData.name
                        )}
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={categoryData?.showOnline}
                          disabled
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={categoryData.createdAt} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {category.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => category.fetchNextPage()}
                disabled={category.isFetching}
                variant="outlined"
                color="primary"
              >
                {category.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </StyledTablePaperWrapper>
      )}
    </>
  )
}
