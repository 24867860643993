import { UseQueryOptions, useQuery } from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { useProfile } from './useProfile'
import { GetResellerByIdResponse } from '../types'

export interface UseResellerByIdOptions
  extends Omit<
    UseQueryOptions<GetResellerByIdResponse, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}

/**
 * useResellerById
 */
export const useResellerById = <TData = GetResellerByIdResponse>(
  resellerId: string,
  options?: UseResellerByIdOptions
) => {
  const token = useAccessToken()

  return useQuery<any, any, TData, string[]>(
    ['v1', 'resellers', resellerId],
    ({ signal }) =>
      api
        .get(`/v1/resellers/${resellerId}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!resellerId,
      ...options,
    }
  )
}

export const useCurrentReseller = (options?: UseResellerByIdOptions) => {
  const token = useAccessToken()
  const user = useProfile()

  return useResellerById(user.data?.data.resellerId, {
    ...options,
    enabled:
      !!token?.access_token && !!user.isSuccess && !!user.data?.data.resellerId,
  })
}
