import { Grid } from '@mui/material'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppLoader, PageNavigationView } from '../../../components'
import {
  useResellerUserCreate,
  useResellerUserUpdate,
  useResellerUsersById,
} from '../../../hooks/useResellerUsers'
import { ProfileEditForm } from '../../Profile/edit/ProfileEditForm'
import { extractResellerUserPatchPayload } from '../../util'
import { useQueryString } from '../../../hooks/useQueryString'

export const ResellerUserView: FC = () => {
  const { resellerId, userId } = useParams()
  const [queryParams, setQuery] = useQueryString(window.location, useNavigate())
  const [updateError, setUpdateError] = useState<any>(null)
  const navigate = useNavigate()
  const userById = userId
    ? useResellerUsersById(resellerId!, userId!, {
        onSuccess: (data: any) => {
          setMyInstance({ ...data?.data, password: undefined })
        },
        refetchOnMount: 'always',
      })
    : null
  const userUpdateMutation = useResellerUserUpdate(resellerId!, userId!)
  const userCreateMutation = useResellerUserCreate(resellerId!)
  const [myInstance, setMyInstance] = useState<any>(
    userById?.data?.data || { accounts: [] }
  )
  const [upadtedData, setUpadtedData] = useState<any>({})
  let navigationPages: any = [
    { label: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { label: 'Users', path: `/app/resellers/${resellerId}/users` },
    {
      label: myInstance?.firstName
        ? `${myInstance?.firstName} ${myInstance?.lastName}`
        : 'New User',
      path: '',
    },
  ]
  const handleUserChange = () => {
    setUpdateError(undefined)
    userId
      ? userUpdateMutation.mutate(
          extractResellerUserPatchPayload(upadtedData),
          {
            onSuccess: () =>
              navigate(
                `/app/resellers/${resellerId}/users?query=${
                  queryParams?.query ? queryParams?.query : ''
                }`
              ),
            onError: (error: any) => {
              if (error.response?.data?.error) {
                const errorData = error.response.data.error?.toLowerCase()
                setUpdateError(errorData)
                setMyInstance({ ...myInstance, password: '' })
                if (errorData.includes('phone')) {
                  setMyInstance({ ...myInstance, password: '' })
                } else if (errorData.includes('email')) {
                  setMyInstance({ ...myInstance, password: '' })
                }
              }
            },
          }
        )
      : userCreateMutation.mutate(extractResellerUserPatchPayload(myInstance), {
          onSuccess: () =>
            navigate(
              `/app/resellers/${resellerId}/users?query=${
                queryParams?.query ? queryParams?.query : ''
              }`
            ),
          onError: (error: any) => {
            if (error.response?.data?.error) {
              const errorData = error.response.data.error?.toLowerCase()
              setUpdateError(errorData)
              setMyInstance({ ...myInstance, password: '' })
              if (errorData.includes('phone')) {
                setMyInstance({ ...myInstance, password: '' })
              } else if (errorData.includes('email')) {
                setMyInstance({ ...myInstance, password: '' })
              }
            }
          },
        })
  }
  if (userById?.isLoading) return <AppLoader />
  return (
    <Grid container>
      <PageNavigationView navigationPages={navigationPages} />
      <Grid item xs={12}>
        <ProfileEditForm
          myInstance={myInstance}
          setMyInstance={setMyInstance}
          onCancelClick={() =>
            navigate(
              `/app/resellers/${resellerId}/users?query=${
                queryParams?.query ? queryParams?.query : ''
              }`
            )
          }
          handleProfileChange={handleUserChange}
          isAdmin={true}
          setUpadtedData={setUpadtedData}
          error={updateError}
        />
      </Grid>
    </Grid>
  )
}
