import { FC, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import {
  Dashboard,
  HomeOutlined,
  PointOfSale,
  Fastfood,
  Group,
  People,
  AccountTree,
  LocationOn,
  Assignment,
  DeliveryDining,
} from '@mui/icons-material'
import { useAuthStore, useProfile } from '../../hooks'
import { useCurrentReseller } from '../../hooks/useResellers'

/**
 * SideBarMenuProps
 */
interface SideBarMenuProps {
  setIsDrawerOpen?: any
  isDrawerOpen?: any
}

/**
 * AppSideBarMenu Component
 * @param param0
 */
export const AppSideBarMenu: FC<SideBarMenuProps> = (props) => {
  const [accountId] = useAuthStore((store) => [store.accountId])
  const profile = useProfile()
  const reseller = useCurrentReseller()

  /**
   * Determine if the current user is the owner of the current reseller
   */
  const isResellerOwner =
    reseller.isSuccess &&
    profile.isSuccess &&
    reseller?.data?.data?.ownerId === profile.data?.data?.id

  return (
    <Box sx={{ overflow: 'auto', p: 0, m: 0 }}>
      {!accountId && <SidebarNewUserMenu />}
      {accountId && <SidebarUserMenu />}
      {isResellerOwner && (
        <SidebarResellerMenu resellerId={reseller.data?.data?.id!} />
      )}
    </Box>
  )
}

export const SidebarNewUserMenu: FC = () => {
  return (
    <List>
      <SidebarItem
        title="Welcome"
        path="/app/accounts/new"
        icon={<HomeOutlined />}
      />
    </List>
  )
}

export interface SidebarResellerMenuProps {
  resellerId: string
}

export const SidebarResellerMenu: FC<SidebarResellerMenuProps> = (props) => {
  return (
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Resellers
        </ListSubheader>
      }
    >
      <SidebarItem
        title="Dashboard"
        path={`/app/resellers/${props.resellerId}/dashboard`}
        icon={<Group />}
        {...props}
      />
      <SidebarItem
        path={`/app/resellers/${props.resellerId}/orders`}
        title="Orders"
        icon={<Fastfood />}
      />
      <SidebarItem
        path={`/app/resellers/${props.resellerId}/deliveries`}
        title="Deliveries"
        icon={<DeliveryDining />}
      />
      <SidebarItem
        path={`/app/resellers/${props.resellerId}/users`}
        title="Users"
        icon={<People />}
      />
      <SidebarItem
        path={`/app/resellers/${props.resellerId}/accounts`}
        title="Accounts"
        icon={<AccountTree />}
      />
      <SidebarItem
        path={`/app/resellers/${props.resellerId}/locations`}
        title="Locations"
        icon={<LocationOn />}
      />
      <SidebarItem
        path={`/app/resellers/${props.resellerId}/audits`}
        title="Audits"
        icon={<Assignment />}
      />
    </List>
  )
}

export interface SidebarUserMenuProps {}

export const SidebarUserMenu: FC<SidebarUserMenuProps> = (props) => {
  return (
    <List
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          My Account
        </ListSubheader>
      }
    >
      <SidebarItem
        title="Dashboard"
        path="/app/dashboard"
        icon={<Dashboard />}
        {...props}
      />
      <SidebarItem
        title="Orders"
        path="/app/orders"
        icon={<Fastfood />}
        {...props}
      />
      <SidebarItem
        title="Locations"
        path="/app/locations"
        icon={<PointOfSale />}
        {...props}
      />
    </List>
  )
}

export interface SidebarItemProps {
  title: string
  path: string
  disabled?: boolean
  icon?: ReactNode
  children?: ReactNode
  setIsDrawerOpen?: any
  isDrawerOpen?: any
}

/**
 *
 * @param param0
 */
export const SidebarItem: FC<SidebarItemProps> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isSelected = location.pathname.startsWith(props.path)
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          selected={isSelected}
          disabled={props.disabled}
          sx={{ bgcolor: 'transparent', py: 1 }}
          onClick={() => {
            navigate(props.path)
            props.isDrawerOpen && props.setIsDrawerOpen(false)
          }}
        >
          {props.icon && (
            <ListItemIcon sx={{ color: isSelected ? '#000000' : '' }}>
              {props.icon}
            </ListItemIcon>
          )}
          <ListItemText primary={props.title} />
        </ListItemButton>
      </ListItem>
      {props.children ? (
        <Collapse in={isSelected} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {props.children}
          </List>
        </Collapse>
      ) : null}
    </>
  )
}
