/**
 * @fileoverview FormattedRelativeTime component
 */
import { FC } from 'react'
import { FormattedRelativeTime as IntlFormattedRelativeTime } from 'react-intl'

/**
 * FormattedRelativeTimeProps
 * @typedef {Object} FormattedRelativeTimeProps
 * @property {number} value - The value to be formatted
 */
export interface FormattedRelativeTimeProps {
  value: string
}

/**
 * FormattedRelativeTime component
 * @param {FormattedRelativeTimeProps} props - The props
 * @return {JSX.Element} The FormattedRelativeTime component
 */
export const FormattedRelativeTime: FC<FormattedRelativeTimeProps> = (
  props
) => {
  const value = (new Date().getTime() - new Date(props.value).getTime()) / 1000
  return (
    <IntlFormattedRelativeTime
      value={-value}
      style="long"
      updateIntervalInSeconds={1}
    />
  )
}
