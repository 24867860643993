import { Box, Drawer, IconButton, Toolbar, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'
import { useAccounts, useAuthStore } from '../hooks'
import { useState } from 'react'
// @ts-ignore
import logo from '../assets/logo.webp'
import { AppSideBar, AppBar, AppLoader } from '../components'
import { Close } from '@mui/icons-material'

export default function LoggedInRoot() {
  const accounts = useAccounts()
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'))

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openSideBar = (event: any) => {
    if (event) {
      setIsDrawerOpen(true)
    } else {
      setIsDrawerOpen(false)
    }
  }

  if (!isLoggedIn) {
    return <Navigate to="/" />
  }

  if (accounts.isLoading) {
    return <AppLoader />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar status={isDrawerOpen} setSideBar={openSideBar} />
      {!isDesktop && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          variant="temporary"
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
            },
          }}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <IconButton>
              <Close />
            </IconButton>
          </Box>
          <AppSideBar
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
          />
        </Drawer>
      )}
      {isDesktop && <AppSideBar />}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}
