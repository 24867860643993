import { Grid } from '@mui/material'
import { FC } from 'react'

export const Reseller: FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Reseller</h1>
      </Grid>
    </Grid>
  )
}
