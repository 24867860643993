import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { AppLoader } from '../../../components'
import { useProfile, useProfileUpdateMutation } from '../../../hooks/useProfile'
import { ProfileEditForm } from './ProfileEditForm'
import { extractUserPatchPayload } from '../../util'

export function ProfileEdit() {
  const navigate = useNavigate()
  const profile = useProfile({
    onSuccess: (data: any) => {
      setMyInstance(data)
    },
  })
  const updateProfile = useProfileUpdateMutation()
  const [myInstance, setMyInstance] = useState<any>(
    profile.isSuccess ? profile?.data?.data : null
  )
  const [upadtedData, setUpadtedData] = useState<any>({})

  const handleProfileChange = () => {
    updateProfile.mutate(extractUserPatchPayload(upadtedData), {
      onSuccess: () => navigate('/app/profile/me'),
    })
  }

  if (profile.isLoading || updateProfile.isLoading) {
    return <AppLoader />
  }
  return (
    <>
      {myInstance && (
        <Box>
          <ProfileEditForm
            onCancelClick={() => navigate('/app/profile/me')}
            myInstance={myInstance}
            setMyInstance={setMyInstance}
            handleProfileChange={handleProfileChange}
            isAdmin={false}
            setUpadtedData={setUpadtedData}
          />
        </Box>
      )}
    </>
  )
}
