import { FC } from 'react'
import { getCurrency } from '../../util'
import { FormattedNumber, useIntl } from 'react-intl'
import { GenericCard } from '../../../components'
import { Typography } from '@mui/material'

export const AvgOrderValueWidget: FC<{ value: number }> = (props) => {
  const { formatMessage } = useIntl()
  const currency = getCurrency()
  const title = formatMessage({ id: 'dashboard_avgOrderValue_title' })
  const subtitle = formatMessage({ id: 'dashboard_avgOrderValue_subtitle' })
  const helpText = formatMessage({ id: 'dashboard_avgOrderValue_helpText' })

  return (
    <GenericCard title={title} subtitle={subtitle} helpText={helpText}>
      <Typography variant="h4">
        <FormattedNumber
          value={props.value}
          style="currency"
          currency={currency}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      </Typography>
    </GenericCard>
  )
}
