import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { toast } from 'react-toastify'

export interface UseProfileByIdProps
  extends Omit<
    UseQueryOptions<any, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}
export const useProfile = (props?: UseProfileByIdProps) => {
  const token = useAccessToken()

  return useQuery(
    ['users', 'me'],
    ({ signal }) =>
      api
        .get(`/v1/users/me`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token,
      refetchOnMount: props?.refetchOnMount,
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useProfileUpdateMutation = () => {
  const token = useAccessToken()
  const queryClient = useQueryClient()
  return useMutation(
    (data: any) =>
      api
        .patch(`/v1/users/me`, data, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['users', 'me'])
        toast.success('Profile Updated')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
