import { Box } from '@mui/material'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useResellerAccountCreate,
  useResellerAccountUpdate,
  useResellerAccountsById,
} from '../../../hooks/useResellerAccounts'
import { AppLoader, PageNavigationView } from '../../../components'
import { AccountEditView } from '../../Account/AccountEditForm'
import { useQueryString } from '../../../hooks/useQueryString'

export const ResellerAccountEdit: FC = () => {
  const { resellerId, accountId } = useParams()
  const navigate = useNavigate()
  const [queryParamURL, setQuery] = useQueryString(window.location, navigate)
  const accountById = accountId
    ? useResellerAccountsById(resellerId!, accountId!, {
        onSuccess: (data: any) => {
          setMyInstance({
            ...data,
            address: {
              ...data.address,
              latitude: data.address?.lat,
              longitude: data.address?.lng,
            },
          })
        },
        refetchOnMount: 'always',
      })
    : null
  const [myInstance, setMyInstance] = useState<any>(accountById?.data || {})
  const accountUpdateMutation = useResellerAccountUpdate(
    resellerId!,
    accountId!
  )
  const accountCreateMutation = useResellerAccountCreate(resellerId!)
  let navigationPages: any = [
    { label: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { label: 'Accounts', path: `/app/resellers/${resellerId}/accounts` },
    {
      label: myInstance?.name,
      path: '',
    },
  ]
  const handleAccountChange = (updatedInstance: any) => {
    accountId
      ? accountUpdateMutation.mutate(updatedInstance, {
          onSuccess: () => {
            navigate(
              `/app/resellers/${resellerId}/accounts?query=${
                queryParamURL?.query ? queryParamURL?.query : ''
              }`
            )
          },
        })
      : accountCreateMutation.mutate(updatedInstance, {
          onSuccess: () => {
            navigate(
              `/app/resellers/${resellerId}/accounts?query=${
                queryParamURL?.query ? queryParamURL?.query : ''
              }`
            )
          },
        })
  }
  if (accountById?.isLoading) return <AppLoader />
  return (
    <Box>
      <PageNavigationView navigationPages={navigationPages} />
      <AccountEditView
        accountId={accountId}
        myInstance={myInstance}
        onCancel={() =>
          accountId && queryParamURL.from !== 'account'
            ? navigate(
                `/app/resellers/${resellerId}/accounts/${accountId}/settings?query=${
                  queryParamURL?.query ? queryParamURL?.query : ''
                }`
              )
            : navigate(
                `/app/resellers/${resellerId}/accounts?query=${
                  queryParamURL?.query ? queryParamURL?.query : ''
                }`
              )
        }
        handleAccountChange={handleAccountChange}
        isAdmin={true}
      />
    </Box>
  )
}
