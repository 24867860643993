import { Breakpoint, useMediaQuery, useTheme } from '@mui/material'

/**
 * Returns true if the screen width is less than the given breakpoint
 * @param query either a number or a Breakpoint enum value
 * @returns true if the screen width is less than the given breakpoint
 */
export const useResponsiveQuery = (query: number | Breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down(query))
}
