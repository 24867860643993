import IconButton from '@mui/material/IconButton'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import Tooltip from '@mui/material/Tooltip'

interface CopyToClipboardProps {
  value: string // The value to be copied to the clipboard
  children: React.ReactNode // The child components to be rendered
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  value,
  children,
}) => {
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation() // Prevent the click from propagating to parent elements
    try {
      await navigator.clipboard.writeText(value)
      console.log('Value copied to clipboard:', value)
      // Optionally, show feedback to the user here (like a toast notification)
    } catch (err) {
      console.error('Failed to copy value to clipboard:', err)
      // Handle errors (e.g., clipboard permissions denied) here
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
      <Tooltip title="Copy to clipboard">
        <IconButton onClick={handleClick}>
          <FileCopyOutlinedIcon sx={{ fontSize: 8 }} />
        </IconButton>
      </Tooltip>
      {children}
    </div>
  )
}
