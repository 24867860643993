import {
  styled,
  Box,
  Paper,
  List,
  IconButton,
  ButtonProps,
  CSSObject,
  Button,
  CircularProgress,
} from '@mui/material'
import { FunctionComponent } from 'react'
export const StyledButtonContainerForTable = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[2],
  borderRadius: Number(theme.shape.borderRadius) * 2,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}))
export const StyledTablePaperWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: Number(theme.shape.borderRadius) * 2,
}))

export const StyledList = styled(List)(({ theme }) => ({
  padding: '4px',
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiListItem-root': {
    borderWidth: 0,
    borderBottomWidth: '1px',
    borderColor: '#eeeeee',
    borderStyle: 'solid',
  },
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#00000066',
  width: '35px',
  height: '35px',
  borderRadius: '50px',
  '&:hover': {
    backgroundColor: '#00000088',
  },
  '& .MuiSvgIcon-root': {
    fill: '#eeeeee',
  },
}))

export const StyledIconAsButton = styled(IconButton)<{
  variant?: Exclude<ButtonProps['variant'], 'text'>
}>(({ theme, variant, color }) => {
  const overrides: CSSObject = {}

  const colorAsVariant =
    color === undefined || color === 'inherit' || color === 'default'
      ? 'primary'
      : color

  if (variant === 'contained') {
    overrides.backgroundColor = theme.palette[colorAsVariant].main
    overrides.color = theme.palette[colorAsVariant].contrastText
    overrides[':hover'] = {
      backgroundColor: theme.palette[colorAsVariant].dark,
    }
  }

  if (variant === 'outlined') {
    overrides.border = `1px solid ${theme.palette[colorAsVariant].main}`
    overrides.color = theme.palette[colorAsVariant].main
  }

  return {
    ...overrides,
  }
})
export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
}

export const LoadingButton: FunctionComponent<LoadingButtonProps> = (props) => {
  return (
    <Button
      {...props}
      disabled={props.disabled || props.loading}
      startIcon={
        props.loading ? (
          <CircularProgress size="1.5rem" color={props.color} />
        ) : undefined
      }
    >
      {props.children}
    </Button>
  )
}
