import { UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import {
  ListCatalogProductResponse,
  ErrorResponse,
  GetCatalogProductByIdResponse,
  CatalogProductCreateEntity,
  CatalogProductPatchEntity,
} from '../types'
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

export const useCatalogProducts = (locationId: string) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useQuery<ListCatalogProductResponse>(
    ['accounts', accountId, 'locations', locationId, 'products'],
    ({ signal, pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/products`,
          {
            params: {
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId,
    }
  )
}

export const useProducts = (locationId: string, options?: any) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useInfiniteQuery<ListCatalogProductResponse>(
    ['accounts', accountId, 'locations', locationId, 'products'],
    ({ signal, pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/products`,
          {
            params: {
              limit: 100,
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageKey
      },
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId,
      ...options,
    }
  )
}

export const useProductCreate = (
  locationId: string,
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetCatalogProductByIdResponse,
    ErrorResponse,
    CatalogProductCreateEntity
  >(
    (product) =>
      api
        .post<GetCatalogProductByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/products`,
          product,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => {
          return data
        }),
    {
      onSuccess: (data, variables, context) => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'products',
          ],
          exact: true,
        })
        options?.onSuccess &&
          options?.onSuccess(data, variables as any, context)
        toast.success('Product Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useProductUpdate = (
  locationId: string,
  productId: string,
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetCatalogProductByIdResponse,
    ErrorResponse,
    CatalogProductPatchEntity
  >(
    (products) =>
      api
        .patch<GetCatalogProductByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/products/${productId}`,
          products,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: (data, variables, context) => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'products',
          ],
          exact: true,
        })
        options?.onSuccess &&
          options?.onSuccess(data, variables as any, context)
        toast.success('Product Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export interface UseProductByIdProps<
  TData = GetCatalogProductByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useProductById = (
  locationId?: string,
  productId?: string,
  props?: UseProductByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useQuery<GetCatalogProductByIdResponse, ErrorResponse>(
    ['accounts', accountId, 'catalogs', locationId, 'products', productId],
    ({ signal }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/products/${productId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled:
        !!token?.access_token && !!accountId && !!locationId && !!productId,
      ...props,
    }
  )
}

export const useProductDelete = (locationId: string) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    (productId: string) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/catalog/products/${productId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: [
            'accounts',
            accountId,
            'locations',
            locationId,
            'products',
          ],
          exact: true,
        })
        toast.success('Product Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
