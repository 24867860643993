import { Typography } from '@mui/material'

// Reusable component
export const CatalogViewDetails = (label: string, value: any) => {
  return (
    <Typography component="span" color="action" sx={{ fontSize: 'inherit' }}>
      <Typography
        component="span"
        color="grayText"
        sx={{ textTransform: 'capitalize', fontSize: 'inherit' }}
      >
        {label}
        {' :'}
      </Typography>
      <Typography component="span" sx={{ marginLeft: 1, fontSize: 'inherit' }}>
        {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
      </Typography>
    </Typography>
  )
}
