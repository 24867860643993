import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  TabNavigation,
  PageNavigationView,
  MenuSyncDialog,
  ItemsWarningDialog,
  MenuSwitch,
} from '../../../../../components'
import { MenuItems } from './Items'
import { MenuCategory } from './Category'
import { MenuModifiers } from './Modifiers'
import { MenuModifierGroups } from './ModifierGroups'
import { useIntl } from 'react-intl'
import { getLocationQueryById, useMenuById } from '../../../../../hooks'

export function MappingMenu() {
  const [currentTab, setCurrentTab] = useState(0)
  const { locationId, menuId, data } = useParams()
  const [dataAfterPublish, setDataAfterPublish] = useState<any>(undefined)

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  const menus = useMenuById(locationId!, menuId!)
  const menuName = menus.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    { label: menuName, path: `` },
  ]
  const [syncDialogId, setSyncDialogId] = useState<string | undefined>(
    undefined
  )
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const navigate = useNavigate()
  const intl = useIntl()
  useEffect(() => {
    if (data === 'categories') {
      setCurrentTab(0)
    } else if (data === 'items') {
      setCurrentTab(1)
    } else if (data === 'modifierGroups') {
      setCurrentTab(2)
    } else {
      setCurrentTab(3)
    }
  }, [data])
  const handleOnClosePublishDialog = (data: any) => {
    setDataAfterPublish(data),
      data?.warnings?.length > 0 && setShowWarningDialog(true)
    setSyncDialogId(undefined)
  }
  const onCancelItemsWarning = () => {
    setShowWarningDialog(false)
    setDataAfterPublish(undefined)
  }
  const handleTabChange = (newValue: any) => {
    setCurrentTab(newValue)
    switch (newValue) {
      case 0:
        navigate(`/app/locations/${locationId}/menu/${menuId}/categories`, {
          replace: true,
        })
        break
      case 1:
        navigate(`/app/locations/${locationId}/menu/${menuId}/items`, {
          replace: true,
        })
        break
      case 2:
        navigate(`/app/locations/${locationId}/menu/${menuId}/modifierGroups`, {
          replace: true,
        })

        break
      case 3:
        navigate(`/app/locations/${locationId}/menu/${menuId}/modifiers`, {
          replace: true,
        })
        break
      default:
        navigate(`/app/locations/${locationId}/menu/${menuId}/categories`, {
          replace: true,
        })
        break
    }
  }
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 50,
          zIndex: 999,
          padding: '10px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <PageNavigationView navigationPages={navigationPages} />
          <MenuSwitch version="v1" />
        </Box>
        <TabNavigation
          currentTab={currentTab}
          setCurrentTab={handleTabChange}
          navigate={navigate}
          path={`/app/locations/${locationId}/menus`}
          labels={['Categories', 'Items', 'Modifier Groups', 'Modifiers']}
          buttonProps={{ color: 'success', show: true }}
          buttonOnClick={() => setSyncDialogId(menuId)}
          buttonText={intl.formatMessage({ id: 'action_publish' })}
        />
      </Box>
      <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        {currentTab === 0 && <MenuCategory />}
        {currentTab === 1 && <MenuItems />}
        {currentTab === 2 && <MenuModifierGroups />}
        {currentTab === 3 && <MenuModifiers />}
      </Box>
      {syncDialogId && (
        <MenuSyncDialog
          menuId={syncDialogId}
          onClose={handleOnClosePublishDialog}
        />
      )}
      {showWarningDialog && (
        <ItemsWarningDialog
          dataAfterPublish={dataAfterPublish}
          onCancelItemsWarning={onCancelItemsWarning}
        />
      )}
    </>
  )
}
