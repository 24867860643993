import axios from 'axios'

/**
 * Return a pre-configured axios http instance configured for the correct api
 */
export const api = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: 'application/json',
  },
})
