import { useEffect, useState } from 'react'
import { defaultItemPatchState } from '../../Locations/CardSelect/Menu/utils'
import { MenuItemCreateEntity, MenuItemPatchEntity } from '../../../types'
import {
  ItemEditForm,
  PageNavigationView,
  StyledTablePaperWrapper,
} from '../../../components'
import {
  getLocationQueryById,
  useCategory,
  useItemById,
  useItemUpdate,
  useMenuById,
  useMenuModifierGroups,
  useProducts,
} from '../../../hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'

/**
 * Dialog for editing an item in the menu.
 */
export function EditItemMenuV2() {
  const navigate = useNavigate()
  const { locationId, menuId, itemId } = useParams()
  const isMenuV2 = window.location.pathname.includes('menus')
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      if (
        (data?.data?.fulfillmentTypes && data.data.fulfillmentTypes?.length) ||
        (data?.data?.serviceAvailability &&
          data.data.serviceAvailability?.length)
      ) {
        setItemSpecificAvailability(true)
      }
    },
  })?.data?.data
  const itemUpdateMutation = useItemUpdate(locationId!, menuId!, itemId!)
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const menuDetails = menuData.data?.data
  const [menuItemState, setMenuItemState] = useState<
    MenuItemCreateEntity | MenuItemPatchEntity
  >(defaultItemPatchState)
  const catalogProducts = useProducts(locationId!, {
    onSuccess(data: any) {
      const item = data.pages.map((page: any) => page.data).flat()
      fetchItemData(item)
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogProducts.fetchNextPage()
      }
    },
  })
  const [catalogProductData, setCatalogProductData] = useState<any>(null)
  const fetchItemData = (catalogProducts: any) => {
    const item = catalogProducts?.find(
      (item: any) => item.id === itemData?.catalogProductId
    )
    setCatalogProductData(item)
  }
  useEffect(() => {
    catalogProducts?.data &&
      fetchItemData(
        catalogProducts?.data?.pages.map((page: any) => page.data).flat()
      )
    if (itemData) {
      setMenuItemState({
        ...itemData,
        price: itemData.price / 100,
        fulfillmentTypes: itemData?.fulfillmentTypes?.length
          ? itemData.fulfillmentTypes
          : menuDetails?.fulfillmentTypes,
        serviceAvailability: itemData?.serviceAvailability?.length
          ? itemData.serviceAvailability
          : menuDetails?.serviceAvailability,
        isBikeFriendly: itemData?.isBikeFriendly || true,
        inStorePrice: itemData?.inStorePrice! / 100,
      })
    }
  }, [itemData, menuData.data?.data])
  const categories = useCategory(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void categories.fetchNextPage()
      }
    },
  })
  const categoriesOptions = categories.data?.pages
    .map((value) => value.data)
    .flat()
    ?.map((record) => record)

  const modifierGroups = useMenuModifierGroups(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifierGroups.fetchNextPage()
      }
    },
  })
  const [itemSpecificAvailability, setItemSpecificAvailability] =
    useState<boolean>(false)
  const modifierGroupsData = modifierGroups?.data?.pages
    ?.map((page: any) => page.data)
    .flat()

  const modifierGroupOptions = modifierGroupsData?.filter((group: any) =>
    catalogProductData?.modifierGroups?.includes(group.catalogModifierGroupId)
  )

  // PageNavigation View constants
  const locationName = locations.data?.data?.name
  const menuName = menuData.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      label: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}`
        : `/app/locations/${locationId}/menu/${menuId}/items`,
    },
    { label: itemData?.name, path: `` },
  ]

  const handleNavigation = () => {
    isMenuV2
      ? navigate(`/app/locations/${locationId}/menus/${menuId}`)
      : navigate(`/app/locations/${locationId}/menu/${menuId}/items`)
  }
  const areArraysEqual = (arr1: string[], arr2: string[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays to ensure they can be compared
    const sortedArr1 = [...arr1].sort()
    const sortedArr2 = [...arr2].sort()

    // Compare each element of the sorted arrays
    return sortedArr1.every((value, index) => value === sortedArr2[index])
  }
  const areTimePeriodsEqual = (arr1: any[], arr2: any[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays to ensure they can be compared
    const sortedArr1 = [...arr1].sort((a, b) =>
      a.startTime.localeCompare(b.startTime)
    )
    const sortedArr2 = [...arr2].sort((a, b) =>
      a.startTime.localeCompare(b.startTime)
    )

    // Compare each element of the sorted arrays
    return sortedArr1.every((item, index) => {
      const correspondingItem = sortedArr2[index]
      return (
        item.startTime === correspondingItem.startTime &&
        item.endTime === correspondingItem.endTime
      )
    })
  }

  const areServiceAvailabilityEqual = (arr1: any[], arr2: any[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays by weekday
    const sortedArr1 = [...arr1].sort((a, b) =>
      a.weekday.localeCompare(b.weekday)
    )
    const sortedArr2 = [...arr2].sort((a, b) =>
      a.weekday.localeCompare(b.weekday)
    )

    // Compare each item in the sorted arrays
    return sortedArr1.every((item, index) => {
      const correspondingItem = sortedArr2[index]

      // Check if the weekdays are the same
      if (item.weekday !== correspondingItem.weekday) return false

      // Check if the timePeriods are equal using the helper function
      return areTimePeriodsEqual(
        item.timePeriods,
        correspondingItem.timePeriods
      )
    })
  }
  const handleSubmitChange = (data: any) => {
    const updatedState = { ...data }
    // Handle price conversion with rounding
    if (data.price) {
      updatedState.price = Math.round(parseFloat(data.price) * 100)
    }

    if (data.inStorePrice) {
      updatedState.inStorePrice = Math.round(
        parseFloat(data.inStorePrice) * 100
      )
    }

    // Handle fulfillment types update
    if (data.fulfillmentTypes) {
      const isEqual = areArraysEqual(
        data.fulfillmentTypes,
        menuDetails?.fulfillmentTypes || []
      )
      updatedState.fulfillmentTypes = isEqual ? [] : data.fulfillmentTypes
    }

    // Handle service availability update
    if (data.serviceAvailability) {
      const serviceAvailabilityEqual = areServiceAvailabilityEqual(
        data.serviceAvailability,
        menuDetails?.serviceAvailability || []
      )
      updatedState.serviceAvailability = serviceAvailabilityEqual
        ? []
        : data.serviceAvailability
    }
    // Perform the mutation
    itemUpdateMutation.mutate(updatedState, {
      onSuccess: () => {
        toast.success('Item Updated')
        handleNavigation()
      },
    })
  }
  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />

      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <ItemEditForm
            menuItemState={menuItemState}
            categoriesOptions={categoriesOptions}
            modifierGroupOptions={modifierGroupOptions}
            itemSpecificAvailability={itemSpecificAvailability}
            setItemSpecificAvailability={setItemSpecificAvailability}
            handleSave={handleSubmitChange}
            handleNavigation={handleNavigation}
          />
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
