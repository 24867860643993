import { FC } from 'react'
import { getLocationQueryById, useLocationAudits } from '../../../hooks'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { AuditFilter } from './AuditFilterDrawer'
import { useNavigate } from 'react-router-dom'
import { useQueryString } from '../../../hooks/useQueryString'
import { AuditsTable } from '../../../components/AuditsTable'

/**
 *
 * @returns
 */
export const AuditLogs: FC = () => {
  const { locationId } = useParams<{ locationId: string }>()
  const location = getLocationQueryById(locationId)
  const [queryParams, setQuery] = useQueryString(window.location, useNavigate())

  const audits = useLocationAudits(locationId, queryParams as any)

  const aggs: any = audits?.data?.pages
    ?.map((page) => page.aggs)
    .filter((item: any) => item !== undefined)
    .flat()

  if (location.isLoading) {
    return <div>Loading...</div>
  }

  if (location.isError) {
    return <div>Error: {location.error}</div>
  }
  /**
   *
   * Render the audits table
   */
  return (
    <>
      <Box sx={{ pt: 2, pb: 2 }}>
        <AuditFilter
          onApply={(data: any) => setQuery(data)}
          onReset={() => setQuery({})}
          queryParams={queryParams}
          locationId={locationId!}
          aggs={aggs}
        />
      </Box>

      <AuditsTable audits={audits} />

      {/* <AuditPage locationId={locationId!} audits={audits} /> */}
    </>
  )
}
