import {
  Grid,
  InputAdornment,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { Close, SearchOutlined } from '@mui/icons-material'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { CardWithImage } from '../CardWithImage'
import { CommonTextField } from '../CommonTextField'
import { StyledButtonContainerForTable } from '../MUIStyledComponents'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useMenuModifierCreate, useModifiers } from '../../hooks'
import { MenuModifierCreateEntity } from '../../types'
import { AppLoader } from '../AppLoader'
import { validateMenuModifierEntity } from '../../pages/Locations/CardSelect/Menu/utils'

interface ModifierListTableProps {
  locationId: string
  handleClose: (text: boolean) => void
}

export const ModifierListTable: FC<ModifierListTableProps> = (props) => {
  const { menuId } = useParams()
  const intl = useIntl()

  const [selectedModifierIndex, setSelectedModifierIndex] = useState<
    number | null
  >()
  const [searchedVal, setSearchedVal] = useState('')

  const catalogModifier = useModifiers(props.locationId!)

  const modifierCreateMutation = useMenuModifierCreate(
    props.locationId!,
    menuId
  )

  const modifierList = catalogModifier.data?.pages
    ?.map((value) => value.data)
    .flat()
  const [menuModifierState, setMenuModifierState] = useState<
    MenuModifierCreateEntity[]
  >([])
  const isValid = useMemo(
    () =>
      menuModifierState
        .map((menu) => validateMenuModifierEntity(menu))
        .every(Boolean, menuModifierState),
    [menuModifierState]
  )

  const selectedModifier = (value: any, index: any, isSelected: boolean) => {
    setSelectedModifierIndex(index)
    if (isSelected) {
      setMenuModifierState((state) => {
        // Create a new array by copying the existing state
        const newState = [...state]
        // Add a new item to the array
        newState.push({
          catalogModifierId: value.id,
          name: value.name,
          description: value.description,
          price: value.price,
          showOnline: value?.showOnline || true,
          position: value?.position,
          imageUrl: value?.imageUrl || '',
        })
        return newState
      })
    } else {
      setMenuModifierState((state) => {
        // Create a new array by copying the existing state
        const newState = [...state]

        // Find the index of the item to remove
        const index = newState.findIndex(
          (item) => item.catalogModifierId === value.id
        )

        // Remove the item from the array
        newState.splice(index, 1)
        return newState
      })
    }
  }

  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.trim() !== '') {
      setSearchedVal(event?.target?.value)
    } else {
      setSearchedVal('')
    }
  }
  if (modifierCreateMutation.isSuccess) {
    props.handleClose(false)
  }

  if (catalogModifier.isLoading) {
    return <AppLoader />
  }
  const handleSubmit = async () => {
    for (const menuModifier of menuModifierState) {
      modifierCreateMutation.mutate(menuModifier, {
        onSuccess: () => {
          props.handleClose(false)
        },
        onError: () => {},
      })
    }
    props.handleClose(false)
  }

  return (
    <>
      <DialogTitle sx={{ p: 0 }} id="customized-dialog-title">
        {intl.formatMessage({ id: 'action_add_modifiers' })}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => props.handleClose(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ p: 0 }} dividers>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <CommonTextField
              autoFocus
              placeholder="Search for modifiers..."
              onChange={handleChangeText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {modifierList
            ?.filter(
              (row) =>
                !searchedVal.length ||
                (row as any)?.name
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchedVal.toString().toLowerCase())
            )
            ?.map((modifier, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <CardWithImage
                  onClickCard={selectedModifier}
                  data={modifier}
                  isRedirect={false}
                  onDelete={() => {}}
                  isEditable={false}
                  isItem={true}
                  multiSelectItems={true}
                  index={index}
                  indexState={selectedModifierIndex}
                />
              </Grid>
            ))}
        </Grid>
        {catalogModifier.hasNextPage && (
          <StyledButtonContainerForTable
            display="flex"
            justifyContent="center"
            py={2}
          >
            <Button
              onClick={() => catalogModifier.fetchNextPage()}
              disabled={catalogModifier.isFetching}
            >
              {catalogModifier.isFetching
                ? `${intl.formatMessage({ id: 'status_loading' })}`
                : `${intl.formatMessage({ id: 'action_load_more' })}`}
            </Button>
          </StyledButtonContainerForTable>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          disabled={!isValid || modifierCreateMutation.isLoading}
          onClick={handleSubmit}
          color="success"
          variant="contained"
        >
          {intl.formatMessage({ id: 'action_create' })}
        </Button>
      </DialogActions>
    </>
  )
}
