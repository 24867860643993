import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { api } from '../services/api'

export interface UserResellerAuditsProps {
  resellerId: string
  filters?: {
    query?: string
  }
}

export const useResellerAudits = (
  props: UserResellerAuditsProps,
  options?: UseInfiniteQueryOptions<any, any, any, any, any>
) => {
  const { resellerId, filters } = props
  const token = useAccessToken()
  const baseKey = ['v1', 'resellers', resellerId, 'audits', filters]

  return useInfiniteQuery(
    baseKey,
    ({ signal, pageParam }) => {
      return api
        .get(`/v1/resellers/${resellerId}/audits`, {
          params: {
            limit: 25,
            nextPageKey: pageParam,
            ...(filters?.query && { query: filters.query }),
          },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data)
    },
    {
      ...options,
      enabled: !!token?.access_token,
    }
  )
}
