import {
  InfoOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Radio,
  TextField,
  Tooltip,
} from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { ChangeEvent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {
  StyledTablePaperWrapper,
  CommonTextField,
  AutoCompleteAddress,
  ServiceAvailabilityInput,
} from '../../../components'
import { SelectTimezone } from '../../../components/SelectTimezone'
import { fetchTimeZoneData, findIdByName, findNameById } from '../../util'
import {
  generateTimePeriods,
  validateEmail,
  validateFirstName,
  validateLocationEntity,
  validateResellerLocationEntity,
} from '../utils'
import { useResellerAccounts } from '../../../hooks/useResellerAccounts'
import { useParams } from 'react-router-dom'

const iconRadio = <RadioButtonUnchecked fontSize="small" />
const iconRadioChecked = <RadioButtonChecked fontSize="small" />
// Function to convert formatted time period string to seconds
const convertTimePeriodToSeconds = (value: any) => {
  if (value === 'Disable') return 0

  const [hoursPart, minutesPart] = value.split(' (hours) ')
  const hours = parseInt(hoursPart) || 0
  const minutes = minutesPart ? parseInt(minutesPart.split(' ')[0]) : 0

  return hours * 3600 + minutes * 60
}
export const LocationEditForm = (props: any) => {
  const {
    locationId,
    handleLocationChange,
    myInstance,
    setMyInstance,
    isAdminAccess,
    autoCompletePeriod,
    setAutoCompletePeriod,
    handleAddreesState,
    timeZoneFetchError,
    handleTimeZoneData,
    onCancelClick,
    showAccounts,
  } = props
  const intl = useIntl()
  const { resellerId } = useParams()
  const timePeriods = generateTimePeriods()
  const accounts = showAccounts ? useResellerAccounts({ resellerId }!) : null
  const accountsList = accounts?.data?.pages
    ?.map((value: any) => value.data)
    .flat()
  const accountNames = accountsList?.map((account: any) => account.name)
  const isValid = showAccounts
    ? validateResellerLocationEntity(myInstance)
    : validateLocationEntity(myInstance)
  const selectedAccountName = myInstance?.accountId
    ? findNameById(myInstance?.accountId, accountsList)
    : null
  useEffect(() => {
    accounts?.hasNextPage && accounts.fetchNextPage()
  }, [accounts?.data])

  const [isValidFirstName, setValidFirstName] = useState(true)
  const [isEmailValid, setEmailValid] = useState(true)
  const [enabled, setEnabled] = useState(false)

  const handleChangeAccount = (e: any, value: string) => {
    const id = findIdByName(value, accountsList)
    setMyInstance((state: any) => ({
      ...state,
      accountId: id,
    }))
  }
  const handleRemoveAddress = () => {
    setMyInstance((state: any) => ({
      ...state,
      address: {
        address: '',
      },
    }))
  }
  const handleChangeLocationName = (event: ChangeEvent<HTMLInputElement>) => {
    setMyInstance((state: any) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleChangefirstName = (event: ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value?.trimStart()
    setValidFirstName(validateFirstName(firstName?.trim()))
    setMyInstance((state: any) => ({
      ...state,
      firstName: firstName,
    }))
  }
  const handleChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setMyInstance((state: any) => ({
      ...state,
      lastName: event.target.value?.trim(),
    }))
  }
  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value?.trim()
    setEmailValid(validateEmail(email))
    setMyInstance((state: any) => ({
      ...state,
      email: email,
    }))
  }
  const handleChangePhoneNumber = (number: any) => {
    const num = number.replace(/\s/g, '')
    setMyInstance((state: any) => ({
      ...state,
      phoneNumber: num,
    }))
  }

  const handleChangeTimezone = (timezone: string) => {
    setMyInstance((state: any) => ({
      ...state,
      timezone,
    }))
  }
  const handleCheckBoxChange = (event: any) => {
    handleAddreesState(event.target.checked)
  }
  const handleChangeTimePeriod = (event: any, value: any) => {
    setAutoCompletePeriod(value)
    if (value === 'Disable') {
      setMyInstance((state: any) => ({
        ...state,
        autoCloseOrders: false,
        autoCloseOrdersAfter: undefined,
      }))
    } else {
      const timeSelected = convertTimePeriodToSeconds(value)
      setMyInstance((state: any) => ({
        ...state,
        autoCloseOrders: true,
        autoCloseOrdersAfter: timeSelected,
      }))
    }
  }
  const handleLocationAddress = async (address: any) => {
    const results = await geocodeByAddress(address)
    const latLng = await getLatLng(results[0])
    const timezoneData = await fetchTimeZoneData(latLng)
    handleTimeZoneData(timezoneData)
    setMyInstance((state: any) => ({
      ...state,
      address: {
        address: results[0].formatted_address,
        googlePlaceId: results[0].place_id,
        latitude: latLng.lat,
        longitude: latLng.lng,
      },
    }))
  }

  const onAvailabilityChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      businessHours: availability,
    }))
  }

  // Handle the syncing of collection and delivery service availabilities
  const onAvailabilityCollectionAndDeliveryChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      availability: {
        collection: availability,
        delivery: availability, // Sync with collection
      },
    }))
  }

  // Handle only collection availability changes
  const onAvailabilityCollectionChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      availability: {
        ...state.availability,
        collection: availability,
        ...(enabled && {
          delivery: availability,
        }), // Sync only if enabled
      },
    }))
  }

  // Handle only delivery availability changes
  const onAvailabilityDeliveryChange = (availability: any) => {
    setMyInstance((state: any) => ({
      ...state,
      availability: {
        ...state.availability,
        delivery: availability,
      },
    }))
  }

  // Toggle the sync between collection and delivery
  const handleLocationEnabled = (event: any) => {
    const isChecked = event.target.checked
    setEnabled(isChecked) // Update the local enabled state

    // Sync collection and delivery availabilities if checked
    if (isChecked) {
      setMyInstance((state: any) => ({
        ...state,
        availability: {
          ...state.availability,
          delivery: state.availability.collection, // Sync delivery with collection
        },
      }))
    }
  }

  return myInstance ? (
    <StyledTablePaperWrapper>
      <Box sx={{ paddingX: 3, paddingY: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9}>
            <Box>
              <Typography variant="h6" component="h1">
                {!locationId
                  ? `${intl.formatMessage({ id: 'action_create' })}`
                  : `${intl.formatMessage({ id: 'action_update' })}`}{' '}
                {intl.formatMessage({ id: 'label_location' })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button color="inherit" onClick={onCancelClick}>
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
            <Button
              variant="contained"
              onClick={handleLocationChange}
              disabled={!isValid}
              color="success"
              sx={{ ml: 2 }}
            >
              {locationId
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          {!isAdminAccess && !locationId ? (
            <Grid item xs={12} md={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  onChange={handleCheckBoxChange}
                  control={<Checkbox defaultChecked={true} />}
                  label={intl.formatMessage({
                    id: 'label_auto_address_fetch',
                  })}
                />
                <Tooltip
                  title={intl.formatMessage({
                    id: 'label_auto_address_fetch_description',
                  })}
                >
                  <InfoOutlined fontSize="small" color="info" />
                </Tooltip>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_location_name' })}
              autoFocus={true}
              onChange={handleChangeLocationName}
              value={myInstance?.name || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiTelInput
              label={intl.formatMessage({ id: 'label_phone_number' })}
              value={myInstance?.phoneNumber || ''}
              placeholder="e.g. +1 123-456-7890"
              onChange={handleChangePhoneNumber}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Autocomplete
                color="info"
                size="medium"
                sx={{ width: '100%' }}
                limitTags={1}
                id="order_auto_complete_time_period"
                options={timePeriods}
                onChange={handleChangeTimePeriod}
                value={autoCompletePeriod}
                disableClearable
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: 0 }}>
                    <Radio
                      color="info"
                      icon={iconRadio}
                      checkedIcon={iconRadioChecked}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'label_auto_complete_orders',
                    })}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'label_auto_complete_orders_description',
                          })}
                        >
                          <InfoOutlined fontSize="small" color="info" />
                        </Tooltip>
                      ),
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          {showAccounts && (
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                  color="info"
                  size="medium"
                  sx={{ width: '100%' }}
                  limitTags={1}
                  id="accountsList"
                  disabled={!showAccounts}
                  options={accountNames || []}
                  onChange={handleChangeAccount}
                  value={selectedAccountName || ''}
                  disableClearable
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: 0 }}>
                      <Radio
                        color="info"
                        icon={iconRadio}
                        checkedIcon={iconRadioChecked}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: 'action_select_account',
                      })}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <SelectTimezone
              myInstance={myInstance}
              onChange={(v) => handleChangeTimezone(v.target.value)}
              errorOnAutoFetch={timeZoneFetchError}
              disableEnable={
                locationId != null && !isAdminAccess ? true : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_first_name' })}
              onChange={handleChangefirstName}
              value={myInstance?.firstName || ''}
              error={!isValidFirstName}
              helperText={
                !isValidFirstName
                  ? `${intl.formatMessage({ id: 'misc_name_min_length' })}`
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_last_name' })}
              onChange={handleChangeLastName}
              value={myInstance?.lastName || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_email' })}
              placeholder="example@domain.com"
              onChange={handleChangeEmail}
              value={myInstance?.email || ''}
              error={!isEmailValid}
              helperText={
                !isEmailValid
                  ? `${intl.formatMessage({ id: 'error_invalid_email' })}`
                  : ' '
              }
            />
          </Grid>

          <Grid item xs={12} sm={showAccounts ? 6 : 12}>
            <AutoCompleteAddress
              disableEnable={
                locationId != null && !isAdminAccess ? true : false
              }
              placeholderName={intl.formatMessage({
                id: 'label_billing_address',
              })}
              formattedAddress={myInstance?.address?.address || ''}
              handleChangeAddress={handleLocationAddress}
              removeAddress={handleRemoveAddress}
            />
          </Grid>

          {myInstance?.businessHours?.length ? (
            <>
              <Grid item xs={12} sm={6}>
                <ServiceAvailabilityInput
                  availabilities={myInstance?.businessHours}
                  onChange={onAvailabilityChange}
                  isMandatory={true}
                  title={intl.formatMessage({ id: 'label_business_hours' })}
                  setLocationState={setMyInstance}
                  fullWidth={true}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enabled} // Use local `enabled` state
                      onChange={handleLocationEnabled} // Toggle local state
                    />
                  }
                  label="Use the same service availability for both collection and delivery."
                />
              </Grid>
              {!enabled ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <ServiceAvailabilityInput
                      availabilities={myInstance?.availability?.collection}
                      onChange={onAvailabilityCollectionChange}
                      isMandatory={true}
                      title={'Collection Hours'}
                      type={'location'}
                      setLocationState={setMyInstance}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ServiceAvailabilityInput
                      availabilities={myInstance?.availability?.delivery}
                      onChange={onAvailabilityDeliveryChange}
                      isMandatory={true}
                      title={'Delivery Hours'}
                      type={'location'}
                      setLocationState={setMyInstance}
                      fullWidth={true}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={6}>
                  <ServiceAvailabilityInput
                    availabilities={myInstance?.availability?.collection}
                    onChange={onAvailabilityCollectionAndDeliveryChange}
                    isMandatory={true}
                    title={'Delivery & Collection Hours'}
                    type={'location'}
                    setLocationState={setMyInstance}
                    fullWidth={true}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </StyledTablePaperWrapper>
  ) : null
}
