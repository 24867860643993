import { Typography, Box, Paper, CardContent } from '@mui/material'
import { AppLoader } from '../'
import { useIntl } from 'react-intl'
import { OrderViewHeader } from './OrderViewHeader'
import { OrderViewItems } from './OrderViewItems'
import { OrderViewFooter } from './OrderViewFooter'
import { FC } from 'react'
import { UseQueryResult } from 'react-query'
import { useLocation } from 'react-router-dom'

interface OrderViewProps {
  order: UseQueryResult<any, any>
}

/**
 * This component is used to display the order details
 * @returns
 */
export const OrderView: FC<OrderViewProps> = ({ order }) => {
  const intl = useIntl()

  if (order.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }

  if (order.isLoading) {
    return <AppLoader />
  }

  return (
    <Paper sx={{ marginTop: 2, marginBottom: 2 }}>
      <CardContent>
        {order.isSuccess && (
          <>
            <OrderViewHeader order={order.data.data} />
            <OrderViewItems order={order} />
            <OrderViewFooter order={order.data.data} />
          </>
        )}
      </CardContent>
    </Paper>
  )
}
