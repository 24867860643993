import { Grid } from '@mui/material'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageNavigationView } from '../../../components'
import { LocationEditForm } from '../../Locations/upsert/LocationEditForm'
import { useResellerAccountsById } from '../../../hooks/useResellerAccounts'
import {
  useResellerAccountLocationById,
  useResellerAccountLocationCreate,
  useResellerAccountLocationUpdate,
} from '../../../hooks/useResellerAccountLocations'
import {
  defaultLocationState,
  extractLocationPatchPayload,
  extractTimeInMinutes,
  findClosestTimePeriod,
  generateTimePeriods,
} from '../../Locations/utils'
import { fetchTimeZoneData } from '../../util'

export const ResellerAccountLocationEdit: FC = () => {
  const { resellerId, accountId, locationId } = useParams()
  const navigate = useNavigate()
  const timePeriods = generateTimePeriods()
  const defaultFormattedTime = extractTimeInMinutes(timePeriods[1]) * 60
  const accountLocationById: any = locationId
    ? useResellerAccountLocationById(resellerId!, accountId!, locationId!, {
        onSuccess: (data: any) => {
          setMyInstance(data?.data)
          if (
            data.data.autoCloseOrdersAfter === undefined ||
            (!data.data.autoCloseOrders && data.data.autoCloseOrdersAfter) ||
            !data.data.autoCloseOrders
          ) {
            setAutoCompletePeriod(timePeriods[0])
          } else if (
            data.data.autoCloseOrders &&
            (!data.data.autoCloseOrdersAfter ||
              data.data.autoCloseOrdersAfter === 0)
          ) {
            setAutoCompletePeriod(timePeriods[1])
            setMyInstance((state: any) => ({
              ...state,
              autoCloseOrdersAfter: 1800,
            }))
          } else {
            const timePeriod = findClosestTimePeriod(
              data.data.autoCloseOrdersAfter,
              timePeriods
            )
            setAutoCompletePeriod(timePeriod)
          }
        },
        refetchOnMount: 'always',
      })
    : null
  const [myInstance, setMyInstance] = useState<any>(
    accountLocationById?.data?.data || {
      ...defaultLocationState,
      autoCloseOrders: true,
      autoCloseOrdersAfter: defaultFormattedTime,
    }
  )
  const accountById: any = useResellerAccountsById(resellerId!, accountId!, {
    onSuccess: (data: any) => {
      if (!locationId) {
        setMyInstance((state: any) => ({
          ...state,
          address: {
            country: data.address.country,
            address: data.address.formatted_address,
            latitude: data.address.lat,
            longitude: data.address.lng,
            place_id: data.address.place_id,
          },
        }))
      }
      //fetch the timezone data for auto filled address from account state
      const fetchTimezoneData = async () => {
        try {
          if (data.address?.lat && data.address?.lng) {
            const timezoneData = await fetchTimeZoneData({
              lat: data.address.lat,
              lng: data.address.lng,
            })
            handleTimeZoneData(timezoneData)
          }
        } catch (error) {
          console.error('Error in fetching timezone:', error)
        }
      }
      !locationId && fetchTimezoneData()
    },
    refetchOnMount: 'always',
  })
  const accountLocationUpdate = useResellerAccountLocationUpdate(
    resellerId!,
    accountId!,
    locationId!
  )
  const accountLocationCreate = useResellerAccountLocationCreate(
    resellerId!,
    accountId!
  )
  const accountName = accountById?.data?.name
  const [autoCompletePeriod, setAutoCompletePeriod] = useState<any>(
    timePeriods[1]
  )
  const [timeZoneFetchError, setTimeZoneFetchError] = useState(false)
  let navigationPages: any = [
    { label: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { label: 'Accounts', path: `/app/resellers/${resellerId}/accounts` },
    {
      label: accountName,
      path: `/app/resellers/${resellerId}/accounts/${accountId}`,
    },
    {
      label: 'Locations',
      path: `/app/resellers/${resellerId}/accounts/${accountId}/locations`,
    },
    {
      label: myInstance?.name,
      path: '',
    },
  ]
  //update the timezone in the location state
  const handleTimeZoneData = (timezoneData: any) => {
    if (timezoneData.error || timezoneData.status === 'INVALID_REQUEST') {
      setTimeZoneFetchError(true)
    } else {
      setMyInstance((state: any) => ({
        ...state,
        timezone: timezoneData?.timeZoneId,
      }))
    }
  }

  const handleLocationChange = () => {
    locationId
      ? accountLocationUpdate.mutate(extractLocationPatchPayload(myInstance), {
          onSuccess: (data) => {
            setMyInstance(data.data)
            navigate(
              `/app/resellers/${resellerId}/accounts/${accountId}/locations`
            )
          },
        })
      : accountLocationCreate.mutate(extractLocationPatchPayload(myInstance), {
          onSuccess: () => {
            navigate(
              `/app/resellers/${resellerId}/accounts/${accountId}/locations`
            )
          },
        })
  }
  return (
    <Grid container>
      <PageNavigationView navigationPages={navigationPages} />
      <LocationEditForm
        locationId={locationId}
        handleLocationChange={handleLocationChange}
        myInstance={myInstance}
        setMyInstance={setMyInstance}
        isAdminAccess={true}
        autoCompletePeriod={autoCompletePeriod}
        setAutoCompletePeriod={setAutoCompletePeriod}
        handleTimeZoneData={handleTimeZoneData}
        timeZoneFetchError={timeZoneFetchError}
        onCancelClick={() =>
          navigate(
            `/app/resellers/${resellerId}/accounts/${accountId}/locations`
          )
        }
      />
    </Grid>
  )
}
