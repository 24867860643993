import { OrdersTable } from '../../components/OrdersTable'
import { OrderFilter } from './OrderFilterDrawer'
import { useQueryString } from '../../hooks/useQueryString'
import { useNavigate } from 'react-router-dom'
import { useAccountOrders, useLocations } from '../../hooks'

export function Orders() {
  // Use the useQueryString hook to get the query parameters from the URL as state
  // to use with the order filtering, the order filter will update hte url and the url will update
  // the orders table
  const [queryParams, setQuery] = useQueryString(window.location, useNavigate())

  const locations = useLocations({
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void locations.fetchNextPage()
      }
    },
  })
  const locationsData = locations.data?.pages
    .map((page: any) => page.data)
    .flat()

  return (
    <>
      <OrderFilter
        onApply={(data: any) => setQuery(data)}
        onReset={() => setQuery({})}
        queryParams={queryParams}
        locations={locationsData}
        loading={locations.isLoading}
      />
      <OrdersPage queryParams={queryParams} />
    </>
  )
}

const OrdersPage = ({ queryParams }: { queryParams: any }) => {
  const filters = { ...queryParams }
  /**
   * Create the data query to fetch the orders
   */
  const orders = useAccountOrders(
    { filters },
    { refetchInterval: 10000, keepPreviousData: true }
  )
  return <OrdersTable orders={orders} />
}
