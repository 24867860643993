import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { toast } from 'react-toastify'
import {
  ErrorResponse,
  GetAccountLocationByIdResponse,
  ListAccountLocationResponse,
  LocationCreateEntity,
  LocationPatchEntity,
} from '../types'

export const useLocations = (options?: any) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListAccountLocationResponse>(
    ['accounts', accountId, 'locations'],
    ({ pageParam }) =>
      api
        .get(`/v1/accounts/${accountId}/locations`, {
          params: {
            limit: 50,
            nextPageKey: pageParam,
          },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken()?.token,
        })
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId,
      ...options,
    }
  )
}

export const useLocationCreate = () => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountLocationByIdResponse,
    ErrorResponse,
    LocationCreateEntity
  >(
    (location: any) =>
      api
        .post<GetAccountLocationByIdResponse>(
          `/v1/accounts/${accountId}/locations`,
          location,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.removeQueries({
          queryKey: ['accounts', accountId, 'locations'],
          exact: true,
        })
        toast.success('Location Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export interface UseLocationByIdProps<
  TData = GetAccountLocationByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const getLocationQueryById = (
  locationId?: any,
  props?: UseLocationByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useQuery<GetAccountLocationByIdResponse, ErrorResponse>(
    ['accounts', accountId, 'locations', locationId],
    ({ signal }) =>
      api
        .get<GetAccountLocationByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId && !!locationId,
      ...props,
    }
  )
}

export interface UseLocationMetricsOptions {
  locationId: string
  range: string
}

export const useLocationMetrics = (options: UseLocationMetricsOptions) => {
  const { locationId, range } = options
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useQuery(
    ['accounts', accountId, 'locations', locationId, 'metrics', range],
    ({ signal }) =>
      api
        .get(`/v1/accounts/${accountId}/locations/${locationId}/metrics`, {
          params: { range: range },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data.data),
    {
      staleTime: 3600 * 60,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId,
    }
  )
}
export interface useAuditOptions {
  query?: string
  requestMethod?: 'GET' | 'POST' | 'PATCH' | 'DELETE'
  resourcePath?: string
  responseCode?: any
  id?: any
  locationId?: string
  clientId?: string
  clientName?: string
}

export const useLocationAudits = (
  locationId: any,
  filter?: useAuditOptions
) => {
  const token = useAccessToken()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const baseKey = [
    'accounts',
    accountId,
    'locations',
    locationId,
    'audits',
    filter,
  ]
  return useInfiniteQuery(
    baseKey,
    ({ signal, pageParam }) =>
      api
        .get(`/v1/accounts/${accountId}/locations/${locationId}/audits`, {
          params: {
            ...filter,
            nextPageKey: pageParam,
          },
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      enabled: !!token?.access_token && !!accountId && !!locationId,
    }
  )
}

export const useLocationUpdate = (locationId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountLocationByIdResponse,
    ErrorResponse,
    LocationPatchEntity
  >(
    (location: any) =>
      api
        .patch<GetAccountLocationByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}`,
          location,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['accounts', accountId, 'locations'],
          exact: true,
        })
        toast.success('Location Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useLocationDelete = (locationId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    () =>
      api.delete(`/v1/accounts/${accountId}/locations/${locationId}`, {
        headers: { Authorization: `Bearer ${token?.access_token}` },
      }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['accounts', accountId, 'locations'],
          exact: true,
        })
        void queryClient.removeQueries({
          queryKey: ['accounts', accountId, 'locations', locationId],
        })
        toast.success('Location Deleted')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
