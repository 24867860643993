import { styled } from '@mui/material'
import { ItemCard } from './ItemCard'

/**
 * Blank Card
 */
export const BlankCard = styled(ItemCard)(() => ({
  borderStyle: 'dashed',
  borderColor: '#ccc',
  borderWidth: '1px',
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
}))
