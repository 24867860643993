import { FC } from 'react'
import { FormattedNumber, useIntl } from 'react-intl'
import { GenericCard } from '../../../components'
import { Typography } from '@mui/material'

export const CancelledOrdersWidget: FC<{
  buckets: { key: string; doc_count: number }[]
}> = (props) => {
  const { formatMessage } = useIntl()
  const title = formatMessage({ id: 'dashboard_cancelledOrders_title' })
  const subtitle = formatMessage({ id: 'dashboard_cancelledOrders_subtitle' })
  const helpText = formatMessage({ id: 'dashboard_cancelledOrders_helpText' })

  return (
    <GenericCard title={title} subtitle={subtitle} helpText={helpText}>
      <Typography variant="h4">
        <FormattedNumber
          value={
            props.buckets.find((bucket: any) => bucket.key === 'CANCELLED')
              ?.doc_count ?? 0
          }
          style="decimal"
        />
      </Typography>
    </GenericCard>
  )
}
