import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { HelpOutline } from '@mui/icons-material'

export const GenericCard: FC<
  PropsWithChildren<{ title: string; subtitle: string; helpText?: string }>
> = (props) => {
  return (
    <Card sx={{ width: '100%' }}>
      <GenericCardHeader
        title={props.title}
        subtitle={props.subtitle}
        helpText={props.helpText}
      />
      <CardContent sx={{ py: 1, '&:last-child': { pb: 1 } }}>
        {props.children}
      </CardContent>
    </Card>
  )
}

export const GenericCardHeader: FC<{
  title: string
  subtitle: string
  helpText?: string
}> = (props) => {
  return (
    <CardHeader
      titleTypographyProps={{ variant: 'subtitle1' }}
      subheaderTypographyProps={{ variant: 'subtitle2' }}
      title={props.title}
      sx={{ py: 1, px: 2 }}
      subheader={props.subtitle}
      action={
        <Tooltip title={props.helpText ?? ''}>
          <IconButton>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      }
    />
  )
}
