import { Grid } from '@mui/material'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageNavigationView } from '../../../components'
import { LocationEditForm } from '../../Locations/upsert/LocationEditForm'
import {
  useResellerLocationById,
  useResellerLocationCreate,
  useResellerLocationUpdate,
} from '../../../hooks/useResellerLocations'
import {
  defaultLocationState,
  extractResellerLocationCreatePayload,
  extractResellerLocationUpdatePayload,
  extractTimeInMinutes,
  findClosestTimePeriod,
  generateTimePeriods,
} from '../../Locations/utils'
import { useQueryString } from '../../../hooks/useQueryString'

export const ResellerLocationView: FC = () => {
  const { resellerId, locationId } = useParams()
  const [queryParams, setQuery] = useQueryString(window.location, useNavigate())
  const navigate = useNavigate()
  const timePeriods = generateTimePeriods()
  const defaultFormattedTime = extractTimeInMinutes(timePeriods[1]) * 60
  const resellerLocationUpdate = useResellerLocationUpdate(
    resellerId!,
    locationId!
  )
  const resellerLocationCreate = useResellerLocationCreate(resellerId!)
  const [autoCompletePeriod, setAutoCompletePeriod] = useState<any>(
    timePeriods[1]
  )
  const [timeZoneFetchError, setTimeZoneFetchError] = useState(false)
  const locationById = locationId
    ? useResellerLocationById(resellerId!, locationId!, {
        onSuccess: (data: any) => {
          setMyInstance(data.data)
          if (
            data.data.autoCloseOrdersAfter === undefined ||
            (!data.data.autoCloseOrders && data.data.autoCloseOrdersAfter) ||
            !data.data.autoCloseOrders
          ) {
            setAutoCompletePeriod(timePeriods[0])
          } else if (
            data.data.autoCloseOrders &&
            (!data.data.autoCloseOrdersAfter ||
              data.data.autoCloseOrdersAfter === 0)
          ) {
            setAutoCompletePeriod(timePeriods[1])
            setMyInstance((state: any) => ({
              ...state,
              autoCloseOrdersAfter: 1800,
            }))
          } else {
            const timePeriod = findClosestTimePeriod(
              data.data.autoCloseOrdersAfter,
              timePeriods
            )
            setAutoCompletePeriod(timePeriod)
          }
        },
        refetchOnMount: 'always',
      })
    : null
  const [myInstance, setMyInstance] = useState<any>(
    locationById?.data?.data || {
      ...defaultLocationState,
      autoCloseOrders: true,
      autoCloseOrdersAfter: defaultFormattedTime,
    }
  )
  //update the timezone in the location state
  const handleTimeZoneData = (timezoneData: any) => {
    if (timezoneData.error || timezoneData.status === 'INVALID_REQUEST') {
      setTimeZoneFetchError(true)
    } else {
      setMyInstance((state: any) => ({
        ...state,
        timezone: timezoneData?.timeZoneId,
      }))
    }
  }
  let navigationPages: any = [
    { label: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { label: 'Locations', path: `/app/resellers/${resellerId}/locations` },
    {
      label: myInstance?.name,
      path: '',
    },
  ]
  const handleLocationChange = () => {
    locationId
      ? resellerLocationUpdate.mutate(
          extractResellerLocationUpdatePayload(myInstance),
          {
            onSuccess: (data) => {
              setMyInstance(data.data)
              navigate(
                `/app/resellers/${resellerId}/locations?query=${
                  queryParams?.query ? queryParams?.query : ''
                }`
              )
            },
          }
        )
      : resellerLocationCreate.mutate(
          extractResellerLocationCreatePayload(myInstance),
          {
            onSuccess: () => {
              navigate(
                `/app/resellers/${resellerId}/locations?query=${
                  queryParams?.query ? queryParams?.query : ''
                }`
              )
            },
          }
        )
  }
  return (
    <Grid container>
      <PageNavigationView navigationPages={navigationPages} />
      <LocationEditForm
        locationId={locationId}
        handleLocationChange={handleLocationChange}
        myInstance={myInstance}
        setMyInstance={setMyInstance}
        isAdminAccess={true}
        autoCompletePeriod={autoCompletePeriod}
        setAutoCompletePeriod={setAutoCompletePeriod}
        handleTimeZoneData={handleTimeZoneData}
        timeZoneFetchError={timeZoneFetchError}
        onCancelClick={() =>
          navigate(
            `/app/resellers/${resellerId}/locations?query=${
              queryParams?.query ? queryParams?.query : ''
            }`
          )
        }
        showAccounts={true}
      />
    </Grid>
  )
}
