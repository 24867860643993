import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  DialogActions,
  Button,
  styled,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { FC } from 'react'

interface ItemsWarningDialogProps {
  dataAfterPublish: any
  onCancelItemsWarning: () => void
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
}))
export const ItemsWarningDialog: FC<ItemsWarningDialogProps> = (props: any) => {
  const intl = useIntl()
  const { dataAfterPublish, onCancelItemsWarning } = props
  return (
    <Dialog
      maxWidth="md"
      open={true}
      sx={{ borderRadius: 2 }}
      onClose={onCancelItemsWarning}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
        }}
        variant="h6"
      >
        {dataAfterPublish.headingMessage ||
          intl.formatMessage({ id: 'label_items_warning_message' })}
      </DialogTitle>
      <DialogContent>
        <TableContainer
          component={Paper}
          elevation={2}
          sx={{ border: '1px solid #eee' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {intl.formatMessage({ id: 'label_name' })}
              </StyledTableCell>
              <StyledTableCell>
                {intl.formatMessage({ id: 'label_type' })}
              </StyledTableCell>
              <StyledTableCell>
                {intl.formatMessage({ id: 'label_warning' })}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataAfterPublish?.warnings?.map((item: any) => (
              <TableRow>
                <StyledTableCell>
                  <Typography variant="body2" component={'p'}>
                    {item.name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="body2"
                    component={'p'}
                    sx={{ textAlign: 'center' }}
                  >
                    {item.type}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="body2"
                    component={'p'}
                    sx={{ textAlign: 'center' }}
                  >
                    {item.message}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancelItemsWarning}>
          {intl.formatMessage({ id: 'action_ok' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
