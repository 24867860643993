import { Close } from '@mui/icons-material'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { CommonTextField } from '../CommonTextField'

interface AppEditDialog {
  data: any
  title: string
  onConfirm: (value: any) => void
  onCancel: (value: any) => void
}

export const AppEditDialog: FC<AppEditDialog> = (props) => {
  const [myInstance, setMyInstance] = useState<any>(props.data)
  const [price, setPriceData] = useState<any>(
    myInstance?.price ? myInstance?.price / 100 : 0
  )

  const handleChangeName = (event: any) => {
    setMyInstance((state: any) => ({
      ...state,
      name: event.target.value,
    }))
  }

  const handleChangePrice = (event: any) => {
    const inputValue = event.target.value
    // Check if the input is a positive number or an empty string
    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
      setMyInstance((state: any) => ({
        ...state,
        price: inputValue * 100,
      }))
      setPriceData(inputValue)
    }
  }
  const intl = useIntl()

  return (
    <>
      <DialogTitle sx={{ p: 0 }} id="customized-dialog-title">
        {intl.formatMessage({ id: 'action_edit_item' })}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => props.onCancel(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ p: 0 }} dividers>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-basic"
              size="medium"
              label="name"
              autoFocus={true}
              value={myInstance?.name || ''}
              onChange={handleChangeName}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <CommonTextField
              id="outlined-basic"
              size="medium"
              label="price"
              value={price}
              onChange={handleChangePrice}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button
          autoFocus
          variant="contained"
          disabled={myInstance?.name?.trim() == '' || myInstance?.price == null}
          onClick={() => {
            props.onConfirm(myInstance), props.onCancel(false)
          }}
          color="success"
          sx={{ color: 'white' }}
        >
          {intl.formatMessage({ id: 'action_save' })}
        </Button>
      </DialogActions>
    </>
  )
}
