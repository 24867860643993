import axios, { CancelTokenSource } from 'axios'

/**
 * Helper to return the next page token from a response
 */
export const getNextPageToken = (
  page: Record<string, any>
): string | undefined => {
  return page?.nextPageToken ? page.nextPageToken : undefined
}

/**
 *
 */
export const signalToCancellationToken = (
  signal?: AbortSignal
): CancelTokenSource | undefined => {
  if (!signal) return undefined
  const source = axios.CancelToken.source()

  signal?.addEventListener('abort', () => {
    source.cancel()
  })

  return source
}
