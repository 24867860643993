import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { Box, Card, styled, useTheme } from '@mui/material'
// @ts-ignore
import noImage from '../../assets/noimage.webp'
import { DriveFolderUploadOutlined, EditOutlined } from '@mui/icons-material'
import { StyledIconButton } from '../MUIStyledComponents'

interface ImageUploadProps {
  existingImage?: string
  progress?: any
  onImageChange: (image: File | null) => void
  height?: string
}

export const ImageUpload: FC<ImageUploadProps> = ({
  existingImage,
  progress,
  onImageChange,
  height,
}) => {
  const theme = useTheme()
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [showEditIcon, setShowEditIcon] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (window.innerWidth <= theme.breakpoints.values.md) {
      setShowEditIcon(true)
    }
  }, [])

  useEffect(() => {
    if (progress === 100) {
      setLoading(false)
    }
  }, [progress])

  const StyledImageBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    height: height ? height : '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: selectedImage
      ? `url(${URL.createObjectURL(selectedImage)})`
      : `url(${existingImage ?? noImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }))
  const StyledLoadingBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#000',
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: height ? height : '250px',
  }))

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setSelectedImage(file || null)
    onImageChange(file || null)
    setLoading(true)
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const iconStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: showEditIcon ? 'flex' : 'none',
  }
  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
      {loading && (
        <Box sx={{ width: '100%', position: 'relative' }}>
          <Card
            onClick={handleUploadButtonClick}
            sx={{ borderRadius: 2, position: 'relative', cursor: 'pointer' }}
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
          >
            <StyledImageBox>
              <StyledLoadingBox>
                <StyledIconButton sx={{ ...iconStyle }}>
                  <EditOutlined />
                </StyledIconButton>
              </StyledLoadingBox>
            </StyledImageBox>
          </Card>
        </Box>
      )}
      {!loading && (
        <Box>
          <Card
            onClick={handleUploadButtonClick}
            sx={{ borderRadius: 2, position: 'relative', cursor: 'pointer' }}
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
          >
            <StyledImageBox>
              {selectedImage || existingImage ? (
                <>
                  <StyledIconButton sx={{ ...iconStyle }}>
                    <EditOutlined />
                  </StyledIconButton>
                </>
              ) : (
                <StyledIconButton sx={{ ...iconStyle }}>
                  <DriveFolderUploadOutlined />
                </StyledIconButton>
              )}
            </StyledImageBox>
          </Card>
        </Box>
      )}
    </>
  )
}
