import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  PageNavigationView,
  StyledTablePaperWrapper,
} from '../../../components'
import {
  getLocationQueryById,
  useItemById,
  useMenuById,
  useMenuModifierById,
  useMenuModifierGroupById,
  useMenuModifierUpdate,
} from '../../../hooks'
import { defaultMenuModifierState } from '../../Locations/CardSelect/Menu/utils'
import {
  MenuModifierCreateEntity,
  MenuModifierPatchEntity,
} from '../../../types'
import { ModifierEditForm } from '../../../components/ItemEditForm/ModifierEditForm'
import { Box } from '@mui/material'

export function MenuItemModifierGroupModifierEdit() {
  const isMenuV2 = window.location.pathname.includes('menus')
  const urlMenu = isMenuV2 ? 'menus' : 'menu'
  const urlItem = isMenuV2 ? 'items' : 'item'
  const { locationId, menuId, itemId, modifierGroupId, modifierId } =
    useParams()
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const navigate = useNavigate()

  const modifier = useMenuModifierById(locationId!, menuId!, modifierId!, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data?.data) {
        let existingData = data.data
        existingData['price'] = data.data?.price! / 100
        setMenuModifierState(existingData)
      }
    },
  })
  const [modifierState, setMenuModifierState] = useState<
    MenuModifierCreateEntity | MenuModifierPatchEntity
  >(modifier?.data?.data ?? defaultMenuModifierState)
  const modifierUpdate = useMenuModifierUpdate(
    locationId!,
    menuId!,
    modifierId!
  )
  const handleNavigation = () => {
    itemId && modifierGroupId
      ? navigate(
          `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/modifier-groups/${modifierGroupId}`
        )
      : navigate(`/app/locations/${locationId}/menu/${menuId}/modifiers`)
  }
  const handleSubmitChange = async (data: any) => {
    const updatedState = { ...data }

    // Handle price conversion with rounding
    if (data.price) {
      updatedState.price = Math.round(parseFloat(data.price) * 100)
    }

    if (data.inStorePrice) {
      updatedState.inStorePrice = Math.round(
        parseFloat(data.inStorePrice) * 100
      )
    }
    modifierUpdate.mutate(updatedState, {
      onSuccess: () => handleNavigation(),
    })
  }
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
  })
  const modifierGroupData = useMenuModifierGroupById(
    locationId!,
    menuId!,
    modifierGroupId!
  )
  // PageNavigation View constants
  const locationName = locations.data?.data?.name
  const menuName = menuData.data?.data?.name
  const itemName = itemData.data?.data?.name
  const modifierGroupName = modifierGroupData.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      label: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}/`
        : `/app/locations/${locationId}/menu/${menuId}/items`,
    },
    {
      label: itemName,
      path: isMenuV2
        ? `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/edit`
        : `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}`,
    },
    {
      label: modifierGroupName,
      path: `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/modifier-groups/${modifierGroupId}`,
    },
    { label: modifierState?.name, path: `` },
  ]
  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />

      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <ModifierEditForm
            modifierState={modifierState}
            handleModifierUpdate={handleSubmitChange}
            handleNavigation={handleNavigation}
          />
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
