import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  TableFooter,
  CircularProgress,
  IconButton,
  Box,
  List,
  Collapse,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material'
import { FC, useState } from 'react'
import { UseInfiniteQueryResult } from 'react-query'
import { FormattedDateTime } from '../FormattedDateTime'
import { ResponseCodeBadge } from '../ResponseCodeBadge'

export interface AuditsTableProps {
  audits: UseInfiniteQueryResult<any, any>
}

/**
 *
 * AuditsTable
 */
export const AuditsTable: FC<AuditsTableProps> = (props) => {
  const { audits } = props

  const rows = audits.data?.pages.flatMap((page) => page.data) || []
  console.log(rows)
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={5}>
              <Typography sx={{ fontWeight: 'bold' }}>Logs</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>METHOD</TableCell>
            <TableCell>ENDPOINT</TableCell>
            <TableCell>RESPONSE</TableCell>
            <TableCell>TIME</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <Row row={row} />
          ))}
        </TableBody>
        {audits.hasNextPage &&
          rows.length >= 25 && ( // using here to overcome opensearch next page result
            <TableFooter>
              <TableRow>
                {/* Load next page using standard button */}
                <TableCell colSpan={5}>
                  {audits.isRefetching ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    <Button
                      onClick={() => audits.fetchNextPage()}
                      variant="contained"
                      disabled={!audits.hasNextPage}
                    >
                      Load More
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
      </Table>
    </TableContainer>
  )
}

/**
 *
 * @param props
 * @returns
 */
export const Row: FC<any> = (props: any) => {
  const { row } = props
  const [open, setOpen] = useState(false)
  const [openRequest, setOpenRequest] = useState(false)
  const [openResponse, setOpenResponse] = useState(false)

  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          title={row.requestMethod}
          sx={{ textTransform: 'uppercase' }}
        >
          {row.requestMethod}
        </TableCell>
        {row.clientName ? (
          <TableCell title={row.clientName}>{row.clientName}</TableCell>
        ) : (
          <TableCell title={row.requestPath}>{row.resourcePath}</TableCell>
        )}
        <TableCell>
          <ResponseCodeBadge responseCode={row?.responseCode} />
        </TableCell>
        <TableCell>
          <FormattedDateTime value={row.createdAt} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              <ListItem style={{ padding: '0px' }}>
                <Typography variant="body2" color="black"></Typography>
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography variant="body2" component="h1" color="black">
                    Timestamp: <FormattedDateTime value={row.createdAt} />
                  </Typography>
                  <Typography variant="body2" component="h1" color="black">
                    Endpoint: {row?.requestMethod} {row?.requestPath}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Typography style={{ fontWeight: 'bold' }}>Headers</Typography>
              <ListItem style={{ padding: '0px', marginTop: 1 }}>
                <ListItemText style={{ marginLeft: '12px' }}>
                  {Object.keys(row.requestHeaders).map((key) => (
                    <Typography variant="body2" component="h1" color="black">
                      {key}: {row?.requestHeaders[key]}
                    </Typography>
                  ))}
                </ListItemText>
              </ListItem>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                onClick={() => setOpenRequest(!openRequest)}
              >
                <IconButton
                  aria-label="expand request body"
                  size="small"
                  onClick={() => setOpenRequest(!openRequest)}
                >
                  {openRequest ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography style={{ fontWeight: 'bold' }}>Request:</Typography>
              </Box>
              {openRequest && (
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    marginTop: '8px',
                    backgroundColor: '#f4f4f4',
                    padding: '10px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontFamily: 'monospace',
                  }}
                >
                  <code>
                    {JSON.stringify(JSON.parse(row?.requestBody), null, 2)}
                  </code>
                </pre>
              )}
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                onClick={() => setOpenResponse(!openResponse)}
              >
                <IconButton
                  size="small"
                  onClick={() => setOpenResponse(!openResponse)}
                >
                  {openResponse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography sx={{ fontWeight: 'bold' }}>Response:</Typography>
              </Box>
              {openResponse && (
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    marginTop: '8px',
                    backgroundColor: '#f4f4f4',
                    padding: '10px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontFamily: 'monospace',
                  }}
                >
                  <code>
                    {JSON.stringify(JSON.parse(row?.responseBody), null, 2)}
                  </code>
                </pre>
              )}
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
