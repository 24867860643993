import { Tabs, Tab, Box, styled, Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { FC } from 'react'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'center',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.primary.contrastText,
}))

const StyledTab = styled(Tab)(() => ({
  textTransform: 'none',
}))

interface TableNavigation {
  currentTab: any
  setCurrentTab: (value: any) => void
  navigate: (path: any) => void
  path: any
  labels: any
  buttonOnClick?: () => void
  buttonText?: string
  buttonProps?: any
}
export const TabNavigation: FC<TableNavigation> = (props) => {
  return (
    <StyledBox>
      <Box sx={{ display: 'flex', alignSelf: 'flex-start' }}>
        <ArrowBack
          color="inherit"
          onClick={() => props.navigate(props.path)}
          sx={{ alignSelf: 'center', marginRight: 2 }}
          style={{ cursor: 'pointer', width: '1.2em', height: '1.2em' }}
        />
        <Tabs
          value={props.currentTab}
          onChange={(_, newValue) => props.setCurrentTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          {props.labels?.map((value: any) => {
            return <StyledTab label={value} key={value} />
          })}
        </Tabs>
      </Box>
      <Box sx={{ display: 'flex', alignSelf: 'center' }}>
        {props?.buttonProps?.show && (
          <Button
            color={
              props.buttonProps.color ? props.buttonProps.color : 'primary'
            }
            variant="contained"
            sx={{ display: 'flex', alignSelf: 'flex-end' }}
            size="small"
            onClick={props.buttonOnClick}
          >
            {props.buttonText}
          </Button>
        )}
      </Box>
    </StyledBox>
  )
}
