import { ArrowBack } from '@mui/icons-material'
import {
  Typography,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@mui/material'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedDateTime } from '../'
import {
  CallOutlined,
  LocationCityOutlined,
  PersonOutlineOutlined,
  TextSnippetOutlined,
} from '@mui/icons-material'

export interface OrderViewHeaderProps {
  order: any
}

/**
 * OrderViewHeader
 */
export const OrderViewHeader: FC<OrderViewHeaderProps> = (props) => {
  const { order } = props
  const location = useLocation()
  const navigate = useNavigate()
  const handleOnclickBack = () => {
    navigate(location.state.back ?? -1)
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            onClick={handleOnclickBack}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              p: 1,
              borderRadius: 4,
              transition: 'background-color 0.5s ease-in-out',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#f5f5f5'
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent'
            }}
          >
            <ArrowBack
              onClick={handleOnclickBack}
              sx={{
                cursor: 'pointer',
                width: '1.2em',
                height: '1.2em',
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" align="center">
              {order.status}
            </Typography>
            <Typography variant="body2" align="center">
              Placed: <FormattedDateTime value={order.placedOn} />
            </Typography>
            <Typography variant="body2" color="black" textAlign={'right'}>
              {order.id}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            {order?.payments?.length > 0 && (
              <Typography
                variant="body2"
                component="p"
                textTransform={'uppercase'}
                textAlign={'right'}
              >
                {order?.payments
                  ?.map((payment: any) => payment.name)
                  .join(', ')}
              </Typography>
            )}
            <Typography
              variant="body2"
              component="p"
              textTransform={'uppercase'}
              textAlign={'right'}
            >
              {order?.fulfillmentType} {'ORDER'}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />
      </Grid>

      {/* Customer detials aligned ot the left, order details aligned to the right */}
      <Grid item xs={12} md={6}>
        <Box display="flex" flexDirection="column">
          <List>
            {/* Customer Name */}
            <ListItem style={{ padding: '0px' }}>
              <PersonOutlineOutlined color="action" />
              <ListItemText style={{ marginLeft: '12px' }}>
                <Typography variant="body2" component="h1" color="black">
                  <span>Customer:</span> {order?.customer?.firstName}{' '}
                  {order?.customer?.lastName}
                </Typography>
              </ListItemText>
            </ListItem>
            {/* Phone */}
            {order?.customer?.phone && (
              <ListItem style={{ padding: '0px' }}>
                <CallOutlined color="action" />
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography variant="body2" component="h1" color="black">
                    <span>Phone:</span>
                    {/* <FormattedPhoneNumber phoneNumber={order?.customer?.phone} /> */}
                    {order.customer.phone}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {/* Address */}
            {order?.fulfillmentType === 'DELIVERY' && (
              <ListItem style={{ padding: '0px' }}>
                <LocationCityOutlined color="action" />
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography variant="body2" component="h1" color="black">
                    <span>Address:</span>{' '}
                    {order?.delivery?.address?.address.replace(
                      /,(?=[^,]*$)/,
                      ''
                    )}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {/* Note */}
            {order?.notes && order?.notes.length > 0 && (
              <ListItem style={{ padding: '0px' }}>
                <TextSnippetOutlined color="action" />
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography variant="body2" component="h1" color="black">
                    <span>Note:</span> {order?.notes}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display="flex" flexDirection="column">
          <List>
            {/* Order Source */}
            {order?.sourceName && (
              <ListItem style={{ padding: '0px' }}>
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography
                    variant="body2"
                    component="h1"
                    color="black"
                    textAlign={'right'}
                  >
                    <span>Order Source:</span> {order?.sourceName}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {/* Order Source Device type */}
            {order?.sourceDeviceType && (
              <ListItem style={{ padding: '0px' }}>
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography
                    variant="body2"
                    component="h1"
                    color="black"
                    textAlign={'right'}
                  >
                    <span>Order Source Device:</span> {order?.sourceDeviceType}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {/* Delivery Type */}
            {order?.fulfillmentType === 'DELIVERY' && (
              <ListItem style={{ padding: '0px' }}>
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography
                    variant="body2"
                    component="h1"
                    color="black"
                    textAlign={'right'}
                  >
                    <span>Delivery Type:</span>{' '}
                    {order?.delivery?.deliveryType === 'SELF_DELIVERY'
                      ? 'SELF_DELIVERY'
                      : 'PARTNER_DELIVERY'}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {/* Driver Pick-Up ETA */}
            {order?.fulfillmentType === 'DELIVERY' &&
              order?.estimatedPickupTime !== '' &&
              order?.estimatedPickupTime !== 'undefined' && (
                <ListItem style={{ padding: '0px' }}>
                  <ListItemText style={{ marginLeft: '12px' }}>
                    <Typography
                      variant="body2"
                      component="h1"
                      color="black"
                      textAlign={'right'}
                    >
                      <span>Driver Pick-Up ETA:</span>{' '}
                      <FormattedDateTime value={order?.estimatedPickupTime} />
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            {/* Estimated Delivery Time */}
            {order?.fulfillmentType === 'DELIVERY' &&
              order?.estimatedDeliveryTime !== '' &&
              order?.estimatedDeliveryTime !== 'undefined' && (
                <ListItem style={{ padding: '0px' }}>
                  <ListItemText style={{ marginLeft: '12px' }}>
                    <Typography
                      variant="body2"
                      component="h1"
                      color="black"
                      textAlign={'right'}
                    >
                      <span>Estimated Delivery Time:</span>{' '}
                      <FormattedDateTime value={order?.estimatedDeliveryTime} />
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            {/* Estimated Order Pickup Time */}
            {(order?.fulfillmentType === 'PICKUP' ||
              order?.fulfillmentType === 'COLLECTION') &&
              order?.estimatedPickupTime !== '' &&
              order?.estimatedPickupTime !== 'undefined' && (
                <ListItem style={{ padding: '0px' }}>
                  <ListItemText style={{ marginLeft: '12px' }}>
                    <Typography
                      variant="body2"
                      component="h1"
                      color="black"
                      textAlign={'right'}
                    >
                      <span>Estimated Order Pickup Time:</span>{' '}
                      <FormattedDateTime value={order?.estimatedPickupTime} />
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
          </List>
        </Box>
      </Grid>
    </Grid>
  )
}
