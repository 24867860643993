import { Box } from '@mui/material'
import {
  DeliveryDiningOutlined,
  ShoppingBagOutlined,
} from '@mui/icons-material'
import { FC } from 'react'

interface FulfillmentTypeProps {
  value: string
}

const FulfillmentType: FC<FulfillmentTypeProps> = ({ value }) => {
  const Icon =
    value?.toUpperCase() === 'DELIVERY'
      ? DeliveryDiningOutlined
      : ShoppingBagOutlined

  return (
    <Box display="flex" alignItems="center">
      <Icon sx={{ margin: 1 }} />
      {value}
    </Box>
  )
}

export default FulfillmentType
