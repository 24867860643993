import { formatNumber } from 'libphonenumber-js'

/**
 * FormattedPhoneNumberProps is a React Component properties that passed to React
 */
export interface FormattedPhoneNumberProps {
  phoneNumber: string
}

/**
 * React Functional Component with types that represents a E.164 formatted phone number
 * as a link to initiate a phone call, this component uses libphonenumber-js to parse
 */
export const FormattedPhoneNumber: React.FC<FormattedPhoneNumberProps> = ({
  phoneNumber,
  ...props
}) => {
  const displayVersion = formatNumber(phoneNumber, 'NATIONAL')

  return (
    <a href={`tel:${phoneNumber}`} {...props}>
      {displayVersion}
    </a>
  )
}
