import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { toast } from 'react-toastify'
import { GetAccountLocationByIdResponse } from '../types'

export interface UseResellerByIdOptions
  extends Omit<
    UseQueryOptions<GetAccountLocationByIdResponse, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}
/**
 * useResellerLocations
 */
export const useResellerLocations = (props: any, options?: any) => {
  const token = useAccessToken()
  const { resellerId, filters } = props
  const baseKey = ['v1', 'resellers', resellerId, 'locations', props?.filters]

  if (filters && filters.query) {
    baseKey.push(filters.query)
  }
  return useInfiniteQuery<any>(
    baseKey,
    ({ signal, pageParam }) =>
      api
        .get<any>(`/v1/resellers/${resellerId}/locations`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          params: {
            limit: 20,
            nextPageKey: pageParam,
            ...(filters?.query && { query: filters?.query }),
          },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!resellerId,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      ...options,
    }
  )
}

export const useResellerLocationById = (
  resellerId: string,
  locationId: string,
  options?: UseResellerByIdOptions
) => {
  const token = useAccessToken()

  return useQuery<any, any, GetAccountLocationByIdResponse, string[]>(
    ['v1', 'resellers', resellerId, 'locations', locationId],
    ({ signal }) =>
      api
        .get(`/v1/resellers/${resellerId}/locations/${locationId}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!resellerId,
      ...options,
    }
  )
}

export const useResellerLocationUpdate = (
  resellerId: string,
  locationId: string
) => {
  const queryClient = useQueryClient()
  const token = useAccessToken()
  return useMutation(
    (location: any) =>
      api
        .patch(
          `/v1/resellers/${resellerId}/locations/${locationId}`,
          location,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['v1', 'resellers', resellerId, 'locations'],
        })
        toast.success('Location Updated')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useResellerLocationCreate = (
  resellerId: string,
  options?: any
) => {
  const token = useAccessToken()

  return useMutation(
    (location: any) =>
      api
        .post(`/v1/resellers/${resellerId}/locations/`, location, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        toast.success('Location Created')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
      ...options,
    }
  )
}
