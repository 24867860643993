import { CircularProgress, CircularProgressProps } from '@mui/material'
import { CenteredBox } from '../CenteredBox'
import { FC } from 'react'

/**
 * Application Loader in a centered box
 */
export const AppLoader: FC<CircularProgressProps> = (props) => (
  <CenteredBox>
    <CircularProgress {...props} />
  </CenteredBox>
)
