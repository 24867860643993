import { Button, Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useMenuById } from '../../hooks'

interface MenuHeaderProps {
  locationId: string
  menuId: string
  onClickPublish: () => any
}
export const MenuHeader: FC<MenuHeaderProps> = (props: any) => {
  const { locationId, menuId, onClickPublish } = props
  const menu = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const intl = useIntl()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography variant="h4">{menu.data?.data!.name}</Typography>
        <Typography variant="subtitle1" sx={{ px: 1 }}>
          {menu.data?.data!.description}
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" color="success" onClick={onClickPublish}>
          {intl.formatMessage({ id: 'action_publish' })}
        </Button>
      </Box>
    </Box>
  )
}
