import {
  Box,
  Grid,
  Button,
  Typography,
  Autocomplete,
  Checkbox,
  TextField,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useMenuCreate,
  useMenuUpdate,
  useMenuById,
  getLocationQueryById,
  useCatalogCategory,
} from '../../../../../hooks'

import {
  AppLoader,
  ServiceAvailabilityInput,
  StyledTablePaperWrapper,
  CommonTextField,
  PageNavigationView,
} from '../../../../../components'
import { MenuCreateEntity, MenuPatchEntity } from '../../../../../types'
import { useIntl } from 'react-intl'
import {
  defaultMenuState,
  extractMenuCreatePayload,
  extractMenuPatchPayload,
  validateMenuEntity,
} from '../utils'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import { toast } from 'react-toastify'

const fulfillmentTypes = ['DELIVERY', 'PICKUP', 'DINE-IN']

export function MenuUpsert() {
  const intl = useIntl()
  const navigate = useNavigate()
  const { locationId, menuId } = useParams()
  const [checked, setChecked] = useState(true)
  const createMenuString = () => {
    let currentDate = new Date()
    let hours = currentDate.getHours()
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')
    const ampm = hours >= 12 ? 'PM' : 'AM'

    hours = hours % 12 || 12 // Convert 0 (midnight) to 12

    // Use toLocaleDateString to format the date
    let formattedDate = currentDate.toLocaleDateString('en-US', {
      month: 'short', // "Oct"
      day: 'numeric', // "18"
      year: 'numeric', // "2024"
    })

    // Remove the comma by replacing it with an empty space
    formattedDate = formattedDate.replace(',', '')

    const time = `${hours}:${minutes} ${ampm}`

    // Create the desired string
    const menuString = `menu ${formattedDate} ${time}`

    return menuString
  }

  const defaultMenuName = createMenuString()
  const [menuState, setMenuState] = useState<
    MenuCreateEntity | MenuPatchEntity
  >({ ...defaultMenuState, name: defaultMenuName })

  const locationDetails = getLocationQueryById(locationId!, {
    refetchOnMount: 'always',
    // onSuccess: (data) => {
    //   data?.data &&
    //     !menuId &&
    //     setMenuState((state) => ({
    //       ...state,
    //       serviceAvailability: data.data?.businessHours,
    //     }))
    // },
  })

  const categories = useCatalogCategory(locationId!)
  const catelogDetails = categories.data?.pages
    ?.map((value: any) => value.data)
    .flat()

  const menuCreationMutation = useMenuCreate(locationId!, checked!)
  const menuUpdateMutation = useMenuUpdate(locationId!, menuId!, checked!)

  const isValid = useMemo(() => validateMenuEntity(menuState), [menuState])

  // fetching the location by id
  const locationName = locationDetails.data?.data?.name
  let navigationPages: any = [
    { label: 'Locations', path: '/app/locations' },
    { label: locationName, path: `/app/locations/${locationId}/analytics` },
    { label: 'Menus', path: `/app/locations/${locationId}/menus` },
    { label: 'Edit Menu', path: `` },
  ]

  // Fetch next page for menu items

  const isCatelog = catelogDetails && catelogDetails?.length > 0

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
  }

  const menuDetails = useMenuById(locationId, menuId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setMenuState(data.data)
    },
  })

  const isLoading =
    menuDetails.isLoading ||
    menuCreationMutation.isLoading ||
    menuUpdateMutation.isLoading

  const handleMenuChange = async () => {
    if (menuId) {
      menuUpdateMutation.mutate(extractMenuPatchPayload(menuState), {
        onSuccess: () => {
          toast.success('Menu Updated')
          navigate(`/app/locations/${locationId}/menus`)
        },
      })
    } else {
      menuCreationMutation.mutate(extractMenuCreatePayload(menuState), {
        onSuccess: async (data) => {
          toast.success('Menu Created')
          navigate(`/app/locations/${locationId}/menus/${data.data?.id}/`)
        },
      })
    }
  }

  const handleDescriptionChange = (event: any) => {
    setMenuState((state) => ({ ...state, description: event.target.value }))
  }

  const handleNameChange = (event: any) => {
    setMenuState((state) => ({ ...state, name: event.target.value }))
  }

  const handleFullFillmentType = (event: any, value: any) => {
    setMenuState((state) => ({
      ...state,
      fulfillmentTypes: value,
    }))
  }

  const onAvailabilityChange = (availability: any) => {
    setMenuState((state) => ({ ...state, serviceAvailability: availability }))
  }

  if (isLoading) {
    return <AppLoader />
  }

  return (
    <>
      <PageNavigationView navigationPages={navigationPages} />
      <StyledTablePaperWrapper>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={9}>
              <Box marginTop={1}>
                <Typography variant="h6" component="h1">
                  {!menuId
                    ? `${intl.formatMessage({ id: 'action_create' })}`
                    : `${intl.formatMessage({ id: 'action_update' })}`}{' '}
                  {intl.formatMessage({ id: 'label_menu' })}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              justifyContent="flex-end"
              display="flex"
            >
              <Button
                color="primary"
                onClick={() => navigate(`/app/locations/${locationId}/menus`)}
              >
                {intl.formatMessage({ id: 'action_cancel' })}
              </Button>
              <Button
                variant="contained"
                onClick={handleMenuChange}
                disabled={!isValid}
                color="success"
                sx={{ color: 'white', ml: 2 }}
              >
                {menuId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={5}>
              <CommonTextField
                id="outlined-basic"
                label={`${intl.formatMessage({
                  id: 'label_menu_name',
                })}${' *'}`}
                onChange={handleNameChange}
                value={menuState.name ?? defaultMenuName ?? ''}
                autoFocus={true}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <Autocomplete
                multiple
                limitTags={3}
                id="checkboxes-tags-demos"
                options={fulfillmentTypes ? fulfillmentTypes : []}
                onChange={handleFullFillmentType}
                value={
                  menuState.fulfillmentTypes ? menuState.fulfillmentTypes : []
                }
                disableCloseOnSelect
                renderOption={(props, optionScope, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {optionScope}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={menuState.fulfillmentTypes || ''}
                    label={`${intl.formatMessage({
                      id: 'label_fulfilment_type',
                    })} *`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
              <CommonTextField
                id="outlined-multiline-static"
                onChange={handleDescriptionChange}
                value={menuState?.description || ''}
                multiline
                rows={2}
                label={`${intl.formatMessage({ id: 'label_description' })}`}
              />
            </Grid>
            {!menuId && (
              <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
                <FormControlLabel
                  onChange={handleChange}
                  control={<Checkbox checked={checked} disabled={!isCatelog} />}
                  label="Add default menu items"
                />
                <FormHelperText>
                  By enabling this checkbox, we will create a default menu from
                  you catalog.
                  <br /> (Note: This will be enabled only if you have the menu
                  available in your catalog)
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={12} sx={{ pt: 0 }}>
              <ServiceAvailabilityInput
                availabilities={menuState?.serviceAvailability}
                type={'menu'}
                setLocationState={setMenuState}
                onChange={onAvailabilityChange}
                isMandatory={true}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledTablePaperWrapper>
    </>
  )
}
