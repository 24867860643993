import {
  MenuCategoryCreateEntity,
  MenuCategoryPatchEntity,
  MenuCreateEntity,
  MenuItemCreateEntity,
  MenuItemPatchEntity,
  MenuPatchEntity,
  MenuModifierPatchEntity,
  MenuModifierGroupCreateEntity,
  MenuModifierGroupPatchEntity,
  MenuModifierCreateEntity,
  MenuModifierGroupEntity,
} from '../../../../types'

/** MENU */
export const defaultMenuState: MenuCreateEntity | MenuPatchEntity = {
  name: '',
  description: '',
  serviceAvailability: [],
  locationId: '',
  fulfillmentTypes: ['DELIVERY', 'PICKUP', 'DINE-IN'],
  image: '',
}

export const extractMenuPatchPayload = (
  menu: MenuCreateEntity | MenuPatchEntity
): MenuPatchEntity => {
  return {
    name: menu.name,
    description: menu.description,
    serviceAvailability: menu.serviceAvailability,
    fulfillmentTypes: menu.fulfillmentTypes,
  }
}
export const extractMenuCreatePayload = (
  menu: MenuCreateEntity | MenuPatchEntity
): MenuCreateEntity => {
  return {
    name: menu.name!,
    description: menu.description!,
    serviceAvailability: menu.serviceAvailability!,
    fulfillmentTypes: menu.fulfillmentTypes!,
  }
}

export const validateMenuEntity = (
  menu: MenuCreateEntity | MenuPatchEntity
) => {
  return (
    menu &&
    menu.name?.trim() !== '' &&
    menu.fulfillmentTypes?.length &&
    menu.serviceAvailability?.some(
      (res: any) => res.timePeriods && res.timePeriods.length > 0
    )
  )
}

/** Category */

export const defaultCategoryState:
  | MenuCategoryCreateEntity
  | MenuCategoryPatchEntity = {
  name: '',
  description: '',
  showOnline: true,
}

export const extractCategoryCreatePayload = (
  category: MenuCategoryCreateEntity | MenuCategoryPatchEntity
): MenuCategoryCreateEntity => {
  return {
    name: category.name!,
    showOnline: category.showOnline!,
    description: category.description!,
    position: category.position!,
    imageUrl: category.imageUrl!,
  }
}

export const extractCategoryPatchPayload = (
  category: MenuCategoryCreateEntity | MenuCategoryPatchEntity
): MenuCategoryPatchEntity => {
  return {
    name: category.name!,
    showOnline: category.showOnline!,
    description: category.description!,
    position: category.position!,
    imageUrl: category.imageUrl!,
  }
}

export const validateCategoryEntity = (
  category: MenuCategoryCreateEntity | MenuCategoryPatchEntity
) => {
  return category && category.name?.trim() !== ''
}

/** Modifier */

export const defaultMenuModifierState: MenuModifierPatchEntity = {
  name: '',
  description: '',
  price: 0,
}

export const extractMenuModifierPatchPayload = (
  modifier: MenuModifierPatchEntity
): MenuModifierPatchEntity => {
  return {
    name: modifier.name!,
    description: modifier.description!,
    price: Math.round(parseFloat(modifier?.price!.toString()) * 100),
    position: modifier.position!,
    imageUrl: modifier.imageUrl!,
    showOnline: modifier.showOnline!,
  }
}

export const validateMenuModifierEntity = (
  modifier: MenuModifierCreateEntity | MenuModifierPatchEntity
) => {
  return (
    modifier?.name?.trim() !== '' &&
    modifier?.price !== undefined &&
    !isNaN(modifier.price) &&
    modifier?.showOnline !== undefined
  )
}

/** Modifier Group */

export const defaultMenuModifierGroupState: MenuModifierGroupPatchEntity = {
  name: '',
  description: '',
  maxPermitted: 0,
  minPermitted: 0,
  modifiers: [],
}

export const defaultMenuModifierGroupGetState: MenuModifierGroupEntity = {
  maxPermitted: 0,
  catalogModifierGroupId: '',
  description: '',
  modifiers: [],
  accountId: '',
  createdAt: '',
  locationId: '',
  name: '',
  menuId: '',
  id: '',
  position: undefined,
  minPermitted: 0,
  updatedAt: '',
}

export const extractMenuModifierGroupPatchPayload = (
  modifierGroup: MenuModifierGroupPatchEntity
): MenuModifierGroupPatchEntity => {
  return {
    name: modifierGroup.name!,
    description: modifierGroup.description!,
    maxPermitted: modifierGroup.maxPermitted!,
    minPermitted: modifierGroup.minPermitted!,
    modifiers: modifierGroup.modifiers!,
    position: modifierGroup.position!,
  }
}

export const validateMenuModifierGroupEntity = (
  modifierGroup: MenuModifierGroupCreateEntity | MenuModifierGroupPatchEntity
) => {
  return (
    modifierGroup &&
    modifierGroup.name?.trim() !== '' &&
    (modifierGroup.minPermitted === undefined ||
      modifierGroup.maxPermitted === undefined ||
      modifierGroup.minPermitted <= modifierGroup.maxPermitted ||
      (modifierGroup.minPermitted === 1 && modifierGroup.maxPermitted === 0))
  )
}

/** ITEMS */

export const validateItemEntity = (
  item: MenuItemCreateEntity | MenuItemPatchEntity
) => {
  return (
    item &&
    item.price?.toLocaleString().length !== 0 &&
    item.price !== undefined &&
    item.name?.trim() !== ''
  )
}

export const defaultItemPatchState: MenuItemPatchEntity = {
  name: '',
  description: '',
  modifierGroups: [],
  categories: [],
  showOnline: false,
  imageUrl: '',
  price: 0,
  position: 0,
  containsAlcohol: false,
  containsTobacco: false,
  isBikeFriendly: true,
  nutritionalInfo: {},
}

export const validationCheckUpdateforMenuItem = (updatedState: any) => {
  const validationResults: any = {}
  if (updatedState.imageUrl || updatedState.imageUrl === '') {
    validationResults.imageUrl =
      updatedState.imageUrl?.trim() !== '' && updatedState.imageUrl !== null
  }
  if (updatedState.position || updatedState.position === null) {
    validationResults.position =
      updatedState.position !== null && updatedState.position !== undefined
  }
  if (updatedState.name || updatedState.name === '') {
    validationResults.name = updatedState.name?.trim() !== ''
  }
  if (updatedState.price || updatedState.price === null) {
    validationResults.price = updatedState.price !== null
  }
  if (updatedState.inStorePrice || updatedState.inStorePrice === null) {
    validationResults.inStorePrice = updatedState.inStorePrice !== null
  }
  if (updatedState.fulfillmentTypes || updatedState.fulfillmentTypes === null) {
    validationResults.fulfillmentTypes =
      updatedState.fulfillmentTypes !== null &&
      updatedState.fulfillmentTypes !== undefined
  }
  if (updatedState.modifierGroups || updatedState.modifierGroups === null) {
    validationResults.modifierGroups =
      updatedState.modifierGroups != null &&
      updatedState.modifierGroups !== undefined
  }
  if (updatedState.categories || updatedState.categories === null) {
    validationResults.categories =
      updatedState.categories !== null && updatedState.categories !== undefined
  }
  if (
    updatedState.serviceAvailability ||
    updatedState.serviceAvailability === null
  ) {
    validationResults.serviceAvailability =
      updatedState?.serviceAvailability !== undefined &&
      updatedState.serviceAvailability !== null
  }
  if (updatedState.showOnline || updatedState.showOnline === null) {
    validationResults.showOnline =
      updatedState.showOnline !== null && updatedState.showOnline !== undefined
  }
  if (updatedState.description) {
    validationResults.description = updatedState.description?.trim() !== ''
  }
  validteAdvancedOptions(updatedState, validationResults)

  return validationResults
}
const validteAdvancedOptions = (updatedState: any, validationResults: any) => {
  const validateRange = (rangeObject: any) => {
    return (
      Object.keys(rangeObject || {}).length === 0 || // true if the object is empty
      (rangeObject.lowerRange !== undefined &&
        rangeObject.upperRange !== undefined)
    )
  }

  const validateField = (field: any) => {
    return field !== null && field !== undefined
  }

  if (updatedState.containsAlcohol || updatedState.containsAlcohol === null) {
    validationResults.containsAlcohol = validateField(
      updatedState.containsAlcohol
    )
  }

  if (updatedState.containsTobacco || updatedState.containsTobacco === null) {
    validationResults.containsTobacco = validateField(
      updatedState.containsTobacco
    )
  }

  if (updatedState.isBikeFriendly || updatedState.isBikeFriendly === null) {
    validationResults.isBikeFriendly = validateField(
      updatedState.isBikeFriendly
    )
  }
  if (updatedState.isTaxIncluded || updatedState.isTaxIncluded === null) {
    validationResults.isTaxIncluded =
      updatedState.isTaxIncluded !== null &&
      updatedState.isTaxIncluded !== undefined
  }
  if (updatedState.taxRate || updatedState.taxRate === null) {
    validationResults.taxRate =
      updatedState.taxRate !== null && updatedState.taxRate !== undefined
  }

  if (updatedState.nutritionalInfo || updatedState.nutritionalInfo === null) {
    validationResults.nutritionalInfo = validateField(
      updatedState.nutritionalInfo
    )
  }

  const nutritionalFields = [
    'kilojoules',
    'carbohydrates',
    'salt',
    'protein',
    'calories',
    'saturatedFat',
    'sugar',
  ]

  nutritionalFields.forEach((field) => {
    if (updatedState.nutritionalInfo?.[field]) {
      validationResults[field] = validateRange(
        updatedState.nutritionalInfo[field]
      )
    }
  })

  if (updatedState.nutritionalInfo?.additive) {
    validationResults.additive = validateField(
      updatedState.nutritionalInfo?.additive
    )
  }

  if (updatedState.nutritionalInfo?.allergens) {
    validationResults.allergens = validateField(
      updatedState.nutritionalInfo?.allergens
    )
  }

  if (updatedState.nutritionalInfo?.spiciness) {
    validationResults.spiciness = validateField(
      updatedState.nutritionalInfo.spiciness
    )
  }

  if (updatedState.nutritionalInfo?.dietaryRestriction) {
    validationResults.dietaryRestriction = validateField(
      updatedState.nutritionalInfo.dietaryRestriction
    )
  }

  return validationResults
}
{
  /** create a validation function for the modifiers */
}
export const validationCheckUpdateforModifier = (updatedState: any) => {
  const validationResults: any = {}
  if (updatedState.imageUrl || updatedState.imageUrl === '') {
    validationResults.imageUrl =
      updatedState.imageUrl?.trim() !== '' && updatedState.imageUrl !== null
  }
  if (updatedState.position || updatedState.position === null) {
    validationResults.position =
      updatedState.position !== null && updatedState.position !== undefined
  }
  if (updatedState.name || updatedState.name === '') {
    validationResults.name = updatedState.name?.trim() !== ''
  }
  if (updatedState.price || updatedState.price === null) {
    validationResults.price = updatedState.price !== null
  }
  if (updatedState.inStorePrice || updatedState.inStorePrice === null) {
    validationResults.inStorePrice = updatedState.inStorePrice !== null
  }
  if (updatedState.showOnline || updatedState.showOnline === null) {
    validationResults.showOnline =
      updatedState.showOnline !== null && updatedState.showOnline !== undefined
  }
  if (updatedState.description) {
    validationResults.description = updatedState.description?.trim() !== ''
  }
  // Validate maxPermitted and minPermitted with validateMinMaxValues
  const isMinMaxValid = validateMinMaxValues(
    updatedState.minPermitted,
    updatedState.maxPermitted
  )
  if (updatedState.minPermitted || updatedState.minPermitted === null) {
    validationResults.minPermitted =
      updatedState.minPermitted !== null &&
      updatedState.minPermitted !== undefined &&
      isMinMaxValid
  }
  if (updatedState.maxPermitted || updatedState.maxPermitted === null) {
    validationResults.maxPermitted =
      updatedState.maxPermitted !== null &&
      updatedState.maxPermitted !== undefined &&
      isMinMaxValid
  }
  validteAdvancedOptions(updatedState, validationResults)
  return validationResults
}

export const validateMinMaxValues = (min: any, max: any) => {
  // Ensure min is not -1
  if (min === -1) {
    return false
  }

  // Handle the special exception case
  const isMinMaxExceptioned = min === 1 && max === 0

  // Validate that max is not less than min unless max is 0 (unlimited) or the exception applies
  if (min > max && max !== 0 && !isMinMaxExceptioned) {
    return false
  }

  return true
}

{
  /** create a validation function for the modifier groups */
}
export const validationCheckUpdateforModifierGroup = (updatedState: any) => {
  const validationResults: any = {}

  if (updatedState.position || updatedState.position === null) {
    validationResults.position =
      updatedState.position !== null && updatedState.position !== undefined
  }

  if (updatedState.name || updatedState.name === '') {
    validationResults.name = updatedState.name?.trim() !== ''
  }

  // Validate maxPermitted and minPermitted with validateMinMaxValues
  const isMinMaxValid = validateMinMaxValues(
    updatedState.minPermitted,
    updatedState.maxPermitted
  )

  if (updatedState.maxPermitted || updatedState.maxPermitted === null) {
    validationResults.maxPermitted =
      updatedState.maxPermitted !== null &&
      updatedState.maxPermitted !== undefined &&
      isMinMaxValid
  }

  if (updatedState.minPermitted || updatedState.minPermitted === null) {
    validationResults.minPermitted =
      updatedState.minPermitted !== null &&
      updatedState.minPermitted !== undefined &&
      isMinMaxValid
  }

  if (updatedState.description) {
    validationResults.description = updatedState.description?.trim() !== ''
  }

  if (updatedState.modifiers || updatedState.modifiers === null) {
    validationResults.modifiers =
      updatedState.modifiers != null && updatedState.modifiers !== undefined
  }

  return validationResults
}
