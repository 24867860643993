import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material'
import { validatePhoneNumberLength } from 'libphonenumber-js'
import { MuiTelInput } from 'mui-tel-input'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {
  AutoCompleteAddress,
  StyledTablePaperWrapper,
} from '../../../components'
import { useParams } from 'react-router-dom'
import { useResellerUsers } from '../../../hooks/useResellerUsers'

const currencies = ['GBP', 'USD', 'EUR', 'NZD', 'AUD', 'CAD']
export const AccountEditView = (props: any) => {
  const intl = useIntl()
  const { resellerId } = useParams()
  const { accountId, myInstance, onCancel, handleAccountChange, isAdmin } =
    props
  useEffect(() => {
    setUpdatedInstance({
      ...updatedInstance,
      phoneNumber: myInstance?.phoneNumber,
    })
  }, [myInstance])
  const users = useResellerUsers({ resellerId }!)
  const userLists = users?.data?.pages?.map((value) => value.data).flat()
  const usersNameList =
    userLists?.map((value) => value.firstName + ' ' + value.email) || []
  const getSelectedUser = (userId: string | undefined) => {
    const selectedUser = userLists?.find((user) => user.id == userId)
    if (selectedUser) {
      return selectedUser.firstName + ' ' + selectedUser.email
    }
    return
  }
  const selectedUser = userLists?.find((user) => user.id == myInstance?.ownerId)
  const [userIdSelected, setUserIdSelected] = useState<string | undefined>('')
  useEffect(() => {
    if (selectedUser) {
      setUserIdSelected(selectedUser.firstName + ' ' + selectedUser.email)
    }
  }, [selectedUser])
  const [updatedInstance, setUpdatedInstance] = useState<any>({
    phoneNumber: myInstance?.phoneNumber,
  })
  useEffect(() => {
    if (updatedInstance?.lastOwnerId && !updatedInstance?.ownerId) {
      setUpdatedInstance({ ...updatedInstance, lastOwnerId: undefined })
    }
  }, [updatedInstance])
  const lastOwnerId = myInstance?.ownerId
  const [isValid, setValid] = useState(false)
  useEffect(() => {
    users?.hasNextPage && users.fetchNextPage()
  }, [users.data])
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedInstance(newObject)
    accountId ? validationCheckUpdate() : validationCheckCreate()
  }
  function validationCheckUpdate() {
    const validationResults: any = {}

    if (updatedInstance.name || updatedInstance.name === '') {
      validationResults.name = updatedInstance.name?.trim() !== ''
    }
    if (updatedInstance.currency || updatedInstance.currency === null) {
      validationResults.currency = updatedInstance.currency !== null
    }
    if (updatedInstance.phoneNumber || updatedInstance.phoneNumber === null) {
      validationResults.phoneNumber =
        updatedInstance.phoneNumber !== null &&
        !validatePhoneNumberLength(updatedInstance.phoneNumber)
    }
    if (updatedInstance.address || updatedInstance.address === null) {
      validationResults.address = updatedInstance.address != null
    }
    if (updatedInstance.ownerId || updatedInstance.ownerId === null) {
      validationResults.ownerId =
        updatedInstance.lastOwnerId !== null &&
        updatedInstance.lastOwnerId !== undefined &&
        updatedInstance.ownerId !== null &&
        updatedInstance.ownerId !== undefined
    }
    if (updatedInstance.lastOwnerId) {
      validationResults.lastOwnerId =
        updatedInstance?.ownerId &&
        updatedInstance?.ownerId !== undefined &&
        updatedInstance.lastOwnerId !== null &&
        updatedInstance.lastOwnerId !== undefined
    }
    // Check if all the validations pass
    const isValid = Object.values(validationResults).every((value) => value)
    setValid(isValid)
  }
  function validationCheckCreate() {
    const defaultValidationCreate =
      updatedInstance.name?.trim() != '' &&
      updatedInstance.currency != null &&
      updatedInstance.phoneNumber != null &&
      !validatePhoneNumberLength(updatedInstance.phoneNumber) &&
      updatedInstance.address != null
    isAdmin
      ? setValid(defaultValidationCreate && updatedInstance.ownerId)
      : setValid(defaultValidationCreate)
  }
  const handleNameChange = (event: any) => {
    updatedInstance.name = event.target.value
    validateAndSet(updatedInstance)
  }

  const handlePhoneNumberChange = (number: any) => {
    updatedInstance.phoneNumber = number.replace(/\s/g, '')
    validateAndSet(updatedInstance)
  }

  const handleCurrencyChange = (event: any, value: any) => {
    if (value === null) {
      updatedInstance.currency = undefined
      validateAndSet(updatedInstance)
    } else {
      updatedInstance.currency = value
      validateAndSet(updatedInstance)
    }
  }
  const handleUserChange = (event: any, value: any) => {
    updatedInstance.lastOwnerId = lastOwnerId
    const selectedUser = userLists?.find(
      (user) => user.firstName + ' ' + user.email === value
    )
    const userId = getSelectedUser(selectedUser?.id)
    setUserIdSelected(userId)
    updatedInstance.ownerId = selectedUser?.id
    validateAndSet(updatedInstance)
  }
  const handleChangeAddress = async (address: any) => {
    const results = await geocodeByAddress(address)
    const latLng = await getLatLng(results[0])
    updatedInstance.address = {}
    const addressComponents = results[0].address_components
    for (let i = 0; i < addressComponents.length; i++) {
      const types = addressComponents[i].types
      if (types.includes('country')) {
        updatedInstance.address.country = addressComponents[i]?.long_name
          ? addressComponents[i].long_name
          : ''
      }
    }
    updatedInstance.address.lat = latLng.lat
    updatedInstance.address.lng = latLng.lng
    updatedInstance.address.place_id = results[0].place_id
    updatedInstance.address.formatted_address = results[0].formatted_address
    validateAndSet(updatedInstance)
  }

  const handleRemove = () => {
    updatedInstance.address = null
    validateAndSet(updatedInstance)
  }
  const handleUpdate = () => {
    if (!updatedInstance.ownerId) {
      handleAccountChange({ ...updatedInstance, lastOwnerId: undefined })
    } else {
      handleAccountChange(updatedInstance)
    }
  }

  return (
    <StyledTablePaperWrapper>
      <Box sx={{ paddingX: 3, paddingY: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={9}>
            <Box marginTop={1}>
              <Typography variant="h6" component="h1">
                {accountId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                {intl.formatMessage({ id: 'label_account' })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button color="inherit" onClick={onCancel}>
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>

            <Button
              variant="contained"
              onClick={() => handleUpdate()}
              disabled={!isValid}
              color="success"
              sx={{ ml: 2 }}
            >
              {accountId
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={isAdmin ? 4 : 6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_account_name' })}
              onChange={handleNameChange}
              value={updatedInstance?.name ?? myInstance?.name}
              placeholder='eg. "Foodhub"'
              variant="outlined"
              autoFocus={true}
            />
          </Grid>

          <Grid item xs={12} sm={isAdmin ? 4 : 6} mt={{ xs: 2, sm: 0 }}>
            <Autocomplete
              value={updatedInstance?.currency ?? myInstance?.currency ?? ''}
              disableClearable
              options={currencies}
              getOptionLabel={(currencies: any) => currencies}
              onChange={handleCurrencyChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({ id: 'action_select_currency' })}
                  placeholder={intl.formatMessage({
                    id: 'action_select_currency',
                  })}
                />
              )}
            />
          </Grid>
          {isAdmin && (
            <Grid item xs={12} sm={4} mt={{ xs: 2, sm: 0 }}>
              <Autocomplete
                value={
                  userIdSelected ??
                  selectedUser?.firstName + ' ' + selectedUser?.email ??
                  ''
                }
                options={usersNameList}
                disableClearable
                getOptionLabel={(usersNameList: any) => usersNameList}
                onChange={handleUserChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'action_select_user' })}
                    placeholder={intl.formatMessage({
                      id: 'action_select_user',
                    })}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MuiTelInput
              label={intl.formatMessage({ id: 'label_phone_number' })}
              placeholder="e.g. +1 123-456-7890"
              value={updatedInstance?.phoneNumber ?? myInstance?.phoneNumber}
              onChange={handlePhoneNumberChange}
              fullWidth
              disabled={isAdmin ? false : accountId ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteAddress
              disableEnable={isAdmin ? false : accountId ? true : false}
              placeholderName={intl.formatMessage({
                id: 'label_billing_address',
              })}
              formattedAddress={myInstance?.address?.formatted_address}
              handleChangeAddress={handleChangeAddress}
              removeAddress={handleRemove}
            />
          </Grid>
        </Grid>
      </Box>
    </StyledTablePaperWrapper>
  )
}
