import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useApplicationById } from '../../../hooks'
import { ImageUpload, StyledTablePaperWrapper } from '../../../components'

import { useIntl } from 'react-intl'
import { ApplicationCreateEntity } from '../../../types'

const scope = [
  'stores.read',
  'stores.write',
  'menus.read',
  'menus.write',
  'orders.read',
  'orders.write',
  'catalog.read',
  'catalog.write',
  'products.read',
  'products.write',
]
export function DeveloperUpsert() {
  const { clientId } = useParams()
  const intl = useIntl()
  const [isValid, setValid] = useState(false)
  const getAppById = clientId ? useApplicationById(clientId) : null
  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />
  const navigate = useNavigate()
  const [myInstance, setMyInstance] = useState<
    Partial<ApplicationCreateEntity>
  >({})
  const handleNameChange = (event: any) => {
    myInstance.name = event.target.value
    validateAndSet(myInstance)
  }
  const handleDesChange = (event: any) => {
    myInstance.description = event.target.value
    validateAndSet(myInstance)
  }
  const handleScopeChange = (event: any, value: any) => {
    myInstance.scopes = value
    validateAndSet(myInstance)
  }
  const handleSubmitChange = async () => {
    // if (clientId) {
    //   updateAppMutation?.mutate(myInstance)
    // } else {
    //   myInstance.id = uuidv4()
    //   myInstance.secret = uuidv4()
    //   createAppMutation?.mutate(myInstance)
    // }
  }
  const handleWebhookChange = (event: any) => {
    myInstance.redirectUris = event.target.value
    validateAndSet(myInstance)
  }

  const handleImageChange = (image: File | null) => {
    // console.log('Selected image:', image)
  }

  function validateAndSet(profileData: any) {
    var newObject = JSON.parse(JSON.stringify(profileData))
    setMyInstance(newObject)
    validationCheck()
  }

  function validationCheck() {
    setValid(
      myInstance.name != null &&
        myInstance.name?.length >= 3 &&
        myInstance.scopes != null &&
        myInstance.scopes?.length > 0
    )
  }

  useEffect(() => {
    if (getAppById?.isSuccess) {
      setMyInstance(
        getAppById?.data?.data as unknown as ApplicationCreateEntity
      )
    }
  }, [getAppById?.isSuccess])
  return (
    <Box>
      <StyledTablePaperWrapper>
        <Box sx={{ paddingX: 3, paddingY: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={9}>
              <Box marginTop={1}>
                <Typography variant="h6" component="h1">
                  {clientId
                    ? `${intl.formatMessage({ id: 'action_update' })}`
                    : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                  {intl.formatMessage({ id: 'label_application' })}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              justifyContent="flex-end"
              display="flex"
            >
              <Button
                color="inherit"
                onClick={() => navigate('/app/developer')}
              >
                {intl.formatMessage({ id: 'action_cancel' })}
              </Button>

              <Button
                variant="contained"
                onClick={handleSubmitChange}
                disabled={!isValid}
                color="success"
                sx={{ ml: 2 }}
              >
                {clientId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label={`${intl.formatMessage({
                      id: 'label_name',
                    })}${' *'}`}
                    value={myInstance.name || ''}
                    onChange={handleNameChange}
                    placeholder='eg. "abc"'
                    variant="outlined"
                    fullWidth
                    autoFocus={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="checkboxes-tags-demos"
                    options={scope}
                    onChange={handleScopeChange}
                    value={myInstance?.scopes ? myInstance?.scopes : []}
                    disableCloseOnSelect
                    renderOption={(props, optionScope, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {optionScope}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={myInstance.scopes || ''}
                        label={`${intl.formatMessage({
                          id: 'label_scope',
                        })}${' *'}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    onChange={handleDesChange}
                    value={myInstance.description || ''}
                    placeholder="eg: enter the description"
                    label={intl.formatMessage({ id: 'label_description' })}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    onChange={handleWebhookChange}
                    label={intl.formatMessage({ id: 'label_webhook_url' })}
                    value={myInstance?.redirectUris || ''}
                    variant="outlined"
                    placeholder="https://example.com/webhook"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography component={'span'} color={'grayText'}>
                <ImageUpload
                  onImageChange={handleImageChange}
                  height="150px"
                  existingImage={getAppById?.data?.data.image}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </StyledTablePaperWrapper>
    </Box>
  )
}
