import { EditOutlined } from '@mui/icons-material'
import {
  Grid,
  Box,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Button,
  Divider,
} from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useItemUpdate } from '../../hooks'
import { ItemCard, PriceInput } from './components'
import { ShowOnlineCategoryView } from './components/ShowOnlineview'
import { TruncateName } from './components/TruncateName'
import { Delete as RemoveIcon } from '@mui/icons-material'
import { debounce } from 'lodash'
import { getCurrency } from '../util'

/**
 * Menu Category Items
 */
export const MenuCategoryItems: FC<{
  category: any
  onItemEdit: (item: any) => any
  onItemAdd: (categoryId: string) => any
  onDeleteItem: (item: any) => any
  items: any
  setSelectedProduct: (val: any) => void
  highlightStyle: (prod: any, index: any, type: any) => any
  performDeletion: any
  showUndoSnackbar: any
}> = (props) => {
  const {
    items,
    category,
    onDeleteItem,
    onItemAdd,
    onItemEdit,
    setSelectedProduct,
    highlightStyle,
    performDeletion,
    showUndoSnackbar,
  } = props
  const intl = useIntl()
  const currency = getCurrency()
  const records = useMemo(() => {
    return items?.filter((item: any) => item?.categories?.includes(category.id))
  }, [category, items])
  const sortedRecords = records?.sort(
    (a: any, b: any) => a.position - b.position
  )
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          marginBottom: 2,
        }}
      >
        <Button variant="outlined" onClick={() => onItemAdd(category.id)}>
          {intl.formatMessage({ id: 'action_add_new_item_to_category' })}
        </Button>
      </Box>
      {sortedRecords?.map((item: any, index: any) => {
        return (
          <Grid item xs={12} key={item.id}>
            <ItemCardView
              item={item}
              currency={currency}
              itemName={TruncateName(item.name)}
              onItemEdit={onItemEdit}
              onDeleteItem={onDeleteItem}
              setSelectedProduct={setSelectedProduct}
              performDeletion={performDeletion}
              showUndoSnackbar={showUndoSnackbar}
              highlightStyle={highlightStyle}
              index={index}
            />
          </Grid>
        )
      })}
    </Box>
  )
}
const ItemCardView = (props: any) => {
  const {
    item,
    itemName,
    onItemEdit,
    onDeleteItem,
    setSelectedProduct,
    performDeletion,
    showUndoSnackbar,
    highlightStyle,
    index,
  } = props
  const [itemState, setItemState] = useState<any>(item)
  const [priceValue, setPriceValue] = useState<any>(undefined)
  useEffect(() => {
    setItemState(item)
  }, [item])
  const itemUpdateMutation = useItemUpdate(
    item.locationId,
    item.menuId,
    item.id
  )
  const handleShowOnline = (showOnline: boolean, itemId: string) => {
    itemUpdateMutation.mutate({ showOnline: showOnline })
  }
  const applyPriceChange = (price: any) => {
    const formattedPrice: number = Math.round(parseFloat(price) * 100)
    setItemState({ ...itemState, price: formattedPrice })
    itemUpdateMutation.mutate(
      { price: formattedPrice },
      {
        onSuccess: () => {
          setPriceValue(undefined)
        },
      }
    )
  }

  const debouncedApply = useCallback(
    debounce((value) => {
      applyPriceChange(value)
    }, 500),
    []
  )

  useEffect(() => {
    if (priceValue !== undefined) {
      debouncedApply(priceValue)
    }
    return () => {
      debouncedApply.cancel()
    }
  }, [priceValue, debouncedApply])

  const handleItemPriceChange = (e: any) => {
    const value = e.target.value
    if (!isNaN(value) || value === '') {
      setPriceValue(value)
    }
  }
  return (
    <>
      <ItemCard
        onClick={() => onItemEdit(itemState)}
        sx={{
          position: 'relative',
          ...highlightStyle(itemState, index, 'item'),
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: 80, borderRadius: 2, height: 50 }}
          image={itemState.imageUrl || 'https://placehold.co/600x400'}
          title={itemName}
        />
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography component="div" variant="body1">
              {itemName}
            </Typography>
            {itemState?.description && (
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
              >
                {itemState.description?.substring(0, 110)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 210,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <ShowOnlineCategoryView
              data={itemState}
              handleShowOnline={handleShowOnline}
            />
            <PriceInput
              value={priceValue ?? itemState.price / 100}
              size="small"
              sx={{
                width: '90px',
              }}
              onChange={(e) => {
                handleItemPriceChange(e)
              }}
              type="number"
              inputProps={{ step: '0.01' }} // Allow decimals
            />
            <Tooltip title="Edit Item">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onItemEdit(itemState)
                }}
              >
                <EditOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Item">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedProduct(itemState)
                  showUndoSnackbar && performDeletion()
                  onDeleteItem(itemState)
                }}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </ItemCard>
    </>
  )
}
