import { useQuery } from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { useCurrentReseller } from './useResellers'

export interface UseResellerAccountMetricsOptions {
  resellerId: string
  accountId: string
  range: string
}

export const useResellerAccountMetrics = (
  options: UseResellerAccountMetricsOptions
) => {
  const reseller = useCurrentReseller()
  const token = useAccessToken()

  // Returnt the query hook
  return useQuery(
    [
      'v1',
      'resellers',
      options.resellerId,
      'accounts',
      options.accountId,
      'metrics',
      options.range,
    ],
    ({ signal }) =>
      api
        .get(
          `/v1/resellers/${options.resellerId}/accounts/${options.accountId}/metrics`,
          {
            params: { range: options.range },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data.data),
    {
      staleTime: 3600 * 60,
      keepPreviousData: true,
      enabled: !!token?.access_token && reseller.isSuccess,
    }
  )
}
