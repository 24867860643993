import { Box, Typography } from '@mui/material'
import {
  FC,
  useState,
  // useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppLoader,
  // AppWarningDialog,
  Column,
  GenericTable,
  PageNavigationView,
} from '../../../components'
import { useResellerAccounts } from '../../../hooks/useResellerAccounts'
import { CopyToClipboard } from '../../../components/CopyToClipboard'
import { ResellerSearch } from '../ResellerSearch'
import { useQueryString } from '../../../hooks/useQueryString'

const columns: readonly Column[] = [
  {
    id: 'id',
    label: 'ID',
    component: (row) => (
      <CopyToClipboard value={row?.id}>
        <Typography variant="caption">{row?.id}</Typography>
      </CopyToClipboard>
    ),
  },
  {
    id: 'name',
    label: 'Name',
    component(row) {
      return <Typography variant="caption">{row?.name}</Typography>
    },
  },
  {
    id: 'currency',
    label: 'Currency',
    component(row) {
      return <Typography variant="caption">{row?.currency}</Typography>
    },
  },
  {
    id: 'address',
    label: 'Address',
    component: (row) => (
      <Typography variant="caption">
        {row?.address?.formatted_address}
      </Typography>
    ),
  },
]

export const ResellerAccounts: FC = () => {
  const { resellerId } = useParams()
  const navigate = useNavigate()
  const [queryParamURL, setQuery] = useQueryString(window.location, navigate)
  const [filters, setFilters] = useState(queryParamURL as any)
  const accounts = useResellerAccounts(
    { resellerId: resellerId!, filters: filters! },
    { refetchOnMount: 'always' }
  )
  // const accountDelete = useResellerAccountDelete(resellerId!)
  // const [showConfirmationDialog, setShowConfirmationDialog] =
  //   useState<boolean>(false)
  // const [selectedAccount, setSelectedAccount] = useState<any>({})
  // const handleDeleteConfirmed = () => {
  //   console.log('in handleDeleteConfirmed')
  //   // accountDelete.mutate(undefined, {
  //   //   onSuccess: () => {
  //   //     setShowConfirmationDialog(false)
  //   //   },
  //   // })
  // }
  // const handleDeleteClick = (account: any) => {
  //   setShowConfirmationDialog(true)
  //   setSelectedAccount(account)
  // }
  let navigationPages: any = [
    { label: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { label: 'Accounts', path: `/app/resellers/${resellerId}/accounts` },
  ]
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PageNavigationView navigationPages={navigationPages} />
        <ResellerSearch
          setFilters={setFilters}
          isLoading={accounts.isLoading}
        />
      </Box>
      {accounts.isLoading ? (
        <AppLoader />
      ) : (
        <GenericTable
          columns={columns}
          query={accounts}
          filters={filters}
          noDataMessage="No accounts found"
          enableDelete={false}
          tableType="account"
          // handleDelete={handleDeleteClick}
        />
      )}
      {/* <AppWarningDialog
        loading={accountDelete.isLoading}
        name={selectedAccount?.name}
        source="Reseller Account"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      /> */}
    </>
  )
}
