import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { FC, Fragment, useState } from 'react'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { FormattedDateTime } from '../../components/FormattedDateTime'
import { AppLoader } from '../../components'
import { ResponseCodeBadge } from '../../components/ResponseCodeBadge'
import ReactJson from 'react-json-view'
import { UseQueryResult } from 'react-query'

interface OrderAuditLogsProps {
  order: UseQueryResult<any, any>
  logs: UseQueryResult<any, any>
}

/**
 *
 * @returns
 */
export const OrderAuditLogs: FC<OrderAuditLogsProps> = ({ logs, order }) => {
  if (logs.isLoading) {
    return <AppLoader />
  }

  /**
   * Extract the logs from the response
   */
  const audits = logs?.data?.pages?.map((value: any) => value.data).flat()

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>
              <Typography sx={{ fontWeight: 'bold' }}>Logs</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>METHOD</TableCell>
            <TableCell>ENDPOINT</TableCell>
            <TableCell>RESPONSE</TableCell>
            <TableCell>TIME</TableCell>
            <TableCell>DELTA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audits?.map((row: any, index: number, audits: any[]) => (
            <Row
              orderCreatedAt={order.data?.data?.placedOn}
              nextRow={audits[index + 1]}
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

/**
 *
 * @param props
 * @returns
 */
export const Row: FC<{
  row: any
  nextRow?: any
  orderCreatedAt?: string
}> = (props: any) => {
  const { row } = props
  const [open, setOpen] = useState(false)
  const [openRequest, setOpenRequest] = useState(false)
  const [openResponse, setOpenResponse] = useState(false)

  /**
   * Calculate the time delta between the current row and the next row, note that the next row
   * is is older in time than the current row.
   */
  const delta = timePassed(
    new Date(row.createdAt).getTime(),
    new Date(
      props.nextRow ? props.nextRow.createdAt : props.orderCreatedAt
    ).getTime()
  )

  return (
    <Fragment>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          title={row.requestMethod}
          sx={{ textTransform: 'uppercase' }}
        >
          {row.requestMethod}
        </TableCell>
        {row.clientName ? (
          <TableCell title={row.clientName}>{row.clientName}</TableCell>
        ) : (
          <TableCell title={row.requestPath}>{row.resourcePath}</TableCell>
        )}
        <TableCell>
          <ResponseCodeBadge responseCode={row?.responseCode} />
        </TableCell>
        <TableCell>
          <FormattedDateTime value={row.createdAt} />
        </TableCell>
        <TableCell colSpan={6}>
          <Typography variant="body2" component="h1" color="black">
            {delta}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              <ListItem style={{ padding: '0px' }}>
                <Typography variant="body2" color="black"></Typography>
                <ListItemText style={{ marginLeft: '12px' }}>
                  <Typography variant="body2" component="h1" color="black">
                    Timestamp: <FormattedDateTime value={row.createdAt} />
                  </Typography>
                  <Typography variant="body2" component="h1" color="black">
                    Endpoint: {row?.requestMethod} {row?.requestPath}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Typography style={{ fontWeight: 'bold' }}>Headers</Typography>
              <ListItem style={{ padding: '0px', marginTop: 1 }}>
                <ListItemText style={{ marginLeft: '12px' }}>
                  {Object.keys(row.requestHeaders).map((key) => (
                    <Typography variant="body2" component="h1" color="black">
                      {key}: {row?.requestHeaders[key]}
                    </Typography>
                  ))}
                </ListItemText>
              </ListItem>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                onClick={() => setOpenRequest(!openRequest)}
              >
                <IconButton
                  aria-label="expand request body"
                  size="small"
                  onClick={() => setOpenRequest(!openRequest)}
                >
                  {openRequest ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography style={{ fontWeight: 'bold' }}>Request:</Typography>
              </Box>
              {openRequest && <ReactJsonView json={row?.requestBody} />}
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                onClick={() => setOpenResponse(!openResponse)}
              >
                <IconButton
                  size="small"
                  onClick={() => setOpenResponse(!openResponse)}
                >
                  {openResponse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Typography sx={{ fontWeight: 'bold' }}>Response:</Typography>
              </Box>
              {openResponse && <ReactJsonView json={row?.responseBody} />}
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export const ReactJsonView: FC<{ json?: string }> = ({ json }) => {
  let source = json ? JSON.parse(json) : {}
  return (
    <ReactJson
      src={
        typeof source === 'object' && source !== null
          ? source
          : { root: source }
      }
      displayDataTypes={false}
      indentWidth={4}
      collapsed={1}
      style={{ marginTop: '8px', padding: '10px' }}
    />
  )
}

export const timePassed = (t0: number, t1: number): string => {
  const delta = Math.abs(t1 - t0) / 1000 // Convert milliseconds to seconds
  if (delta < 1) {
    return `+${(delta * 1000).toFixed(0)}ms` // Display milliseconds with two decimal places
  } else if (delta < 60) {
    return `+${delta.toFixed(0)}s` // Display seconds with two decimal places
  } else if (delta < 3600) {
    return `+${(delta / 60).toFixed(0)}m` // Convert seconds to minutes and display with two decimal places
  } else {
    return `+${(delta / 3600).toFixed(0)}hr` // Convert seconds to hours and display as whole numbers
  }
}
