import { AddressModel } from './AddressModel'

export class AccountModel {
  id: string | null = null
  name: string | null = null
  currency: string | null = null
  phoneNumber: string | null = null
  address: AddressModel | null = null
  constructor(data?: any) {
    if (data) {
      this.deserilize(data)
    }
  }
  private deserilize(data: any) {
    const keys = Object.keys(data)
    for (const key of keys) {
      if (data.hasOwnProperty(key)) (this as any)[key] = data[key]
    }
  }
}
