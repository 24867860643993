import { useIntl } from 'react-intl'
import { GenericCard } from '../../../components'
import { Chart } from 'react-chartjs-2'
import { FC } from 'react'

export const OrderVolumeByLocationWidget: FC<{
  orderByLocationHistogram: any
}> = (props) => {
  const { formatMessage } = useIntl()
  const title = formatMessage({ id: 'dashboard_orderVolumeByLocation_title' })
  const subtitle = formatMessage({
    id: 'dashboard_orderVolumeByLocation_subtitle',
  })
  const helpText = formatMessage({
    id: 'dashboard_orderVolumeByLocation_helpText',
  })
  return (
    <GenericCard title={title} subtitle={subtitle} helpText={helpText}>
      <Chart
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'right',
              align: 'start',
            },
            tooltip: {
              callbacks: {
                label: (context) =>
                  `${context.dataset.label}: ${context.formattedValue}`,
              },
            },
          },
          scales: {
            x: {
              type: 'timeseries',
              stacked: true,
              ticks: {
                maxRotation: 45,
                maxTicksLimit: 10,
                autoSkip: true,
              },
            },
            y: { type: 'linear', ticks: { stepSize: 1 } },
          },
        }}
        data={{
          datasets: props.orderByLocationHistogram?.buckets.map(
            (locationBucket: any) => ({
              label:
                locationBucket.top_hits?.hits?.hits[0]?._source?.locationName ||
                locationBucket.key,
              data: locationBucket.histogram.buckets.map((bucket: any) => ({
                x: bucket.key,
                y: bucket.doc_count,
              })),
            })
          ),
        }}
        height={60}
        type="bar"
      ></Chart>
    </GenericCard>
  )
}
