import { FC } from 'react'
import { useIntl } from 'react-intl'
import { GenericCard } from '../../../components'
import { Typography } from '@mui/material'

export const TotalOrderCountWidget: FC<{ value: number }> = (props) => {
  const { formatMessage } = useIntl()
  const title = formatMessage({ id: 'dashboard_totalOrders_title' })
  const subtitle = formatMessage({ id: 'dashboard_totalOrders_subtitle' })
  const helpText = formatMessage({ id: 'dashboard_totalOrders_helpText' })

  return (
    <GenericCard title={title} subtitle={subtitle} helpText={helpText}>
      <Typography variant="h4">{props.value}</Typography>
    </GenericCard>
  )
}
