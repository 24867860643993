import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import { ListUsersResponse, GetUserByIdResponse } from '../types'
import { toast } from 'react-toastify'

export interface UseResellersOptions
  extends Omit<
    UseInfiniteQueryOptions<any, any, ListUsersResponse, string[]>,
    'queryKey' | 'queryFn'
  > {}

/**
 * useResellerById
 */
export const useResellerUsers = (props: any, options?: any) => {
  const { resellerId, filters } = props
  const token = useAccessToken()
  const baseKey = ['v1', 'resellers', resellerId, 'users', props?.filters]

  if (filters && filters.query) {
    baseKey.push(filters.query)
  }

  return useInfiniteQuery<ListUsersResponse>(
    baseKey,
    ({ signal, pageParam }) =>
      api
        .get<ListUsersResponse>(`/v1/resellers/${resellerId}/users`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          params: {
            limit: 20,
            nextPageKey: pageParam,
            ...(filters?.query && { query: filters?.query }),
          },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!resellerId,
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      keepPreviousData: true,
      ...options,
    }
  )
}

export interface UseResellerUserByIdOptions
  extends Omit<
    UseQueryOptions<GetUserByIdResponse, any, any, string[]>,
    'queryKey' | 'queryFn'
  > {}

/**
 * useResellerUsersById
 */
export const useResellerUsersById = (
  resellerId: string,
  userId: string,
  options?: UseResellerUserByIdOptions
) => {
  const token = useAccessToken()

  return useQuery<any, any, GetUserByIdResponse, string[]>(
    ['v1', 'resellers', resellerId, 'users', userId],
    ({ signal }) =>
      api
        .get(`/v1/resellers/${resellerId}/users/${userId}`, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
          cancelToken: signalToCancellationToken(signal)?.token,
        })
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!resellerId,
      ...options,
    }
  )
}

export const useResellerUserUpdate = (resellerId: string, userId: string) => {
  const queryClient = useQueryClient()
  const token = useAccessToken()
  return useMutation(
    (user: any) =>
      api
        .patch(`/v1/resellers/${resellerId}/users/${userId}`, user, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['v1', 'resellers', resellerId, 'users'],
          exact: true,
        })
        toast.success('User Updated')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useResellerUserCreate = (resellerId: string, options?: string) => {
  const queryClient = useQueryClient()
  const token = useAccessToken()

  return useMutation(
    (user: any) =>
      api
        .post(`/v1/resellers/${resellerId}/users/`, user, {
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['v1', 'resellers', resellerId, 'users'],
          exact: true,
        })
        toast.success('User Created')
      },
      onError: (error: any) => {
        console.log(error.response, 'error in create user')
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.error))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
